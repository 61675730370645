/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { LegalDisplayContainer } from '../components';
import {
  createLegal,
  clearLegal,
  updateHouseholdStoreValue,
  updateLegal,
  getLegal,
  createProposalLegal,
  readProposalLegal,
  updateProposalLegal,
  toggleShowDeleteModal,
} from '../store/actions';
import {
  legalTabs,
  LegalApplicableMemberOption,
  LegalEmpoweredMemberOption,
  UseHandleInstrumentError,
  CheckHasError,
  GenerateError,
  RemoveError,
  DateFormat,
  BeneficiaryOption,
  UseGenerateMembersList,
  FilterMembersByContext,
  TransformApplicables,
  TransformEmpowered,
  TransformLegalBeneficiaries,
  ConvertObjectToArray,
} from '../utils';

const legalReducer = (legal, action) => {
  legal[action.type] = action.payload;
  return legal;
};

const LegalContainer = ({
  isModal,
  hide,
  householdId,
  isNew,
  isList,
  proposalId,
  showProposalMode,
  selectedType = null,
}) => {
  const dispatch = useDispatch();
  const {
    legalCategories,
    currentHousehold,
    legalError,
    preferences,
    legal,
    clearedLegal,
    membersLegals,
    currentProposal,
    proposalLegal,
  } = useSelector((state) => ({
    legalCategories: state.configs.legalCategories,
    currentHousehold: state.households.currentHousehold,
    legalError: state.households.legalError,
    preferences: state.user.user.preferences,
    legal: state.households.legal,
    clearedLegal: state.households.clearedLegal,
    membersLegals: state.households.membersLegals,
    currentProposal: state.households.currentProposal,
    proposalLegal: state.households.proposalLegal,
  }));
  const initLegalState = {
    category: '',
    applicable_members: [],
    reference_name: '',
    location: '',
    empowered_members: [],
    beneficiary_members: [],
    date_created: '',
    date_collected: '',
    notes: '',
  };
  const [legalState, dispatchLegal] = useReducer(legalReducer, initLegalState);
  const [typeOptions, setTypeOptions] = useState();
  const [membersList, setMembersList] = useState();
  const [hasMembersList, setHasMembersList] = useState(false);
  const [membersOptions, setMembersOptions] = useState();
  const [empoweredMembersOptions, setEmpoweredMembersOptions] = useState();
  const [beneficiariesOptions, setBeneficiariesOptions] = useState();
  const [legalType, setLegalType] = useState();
  const [hasApplicables, setHasApplicables] = useState(false);
  const [hasEmpowered, setHasEmpowered] = useState(false);
  const [hasBeneficiaries, setHasBeneficiaries] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loadedData, setLoadedData] = useState(isNew || isList ? true : false);
  const [modalError, setModalError] = useState('');
  const [modalErrors, setModalErrors] = useState();
  const [errorTitle, setErrorTitle] = useState();
  const [hasLegal, setHasLegal] = useState(false);
  const [modalHeader, setModalHeader] = useState('Legal');
  const [hasLegals, setHasLegals] = useState(false);
  const [legalsList, setLegalsList] = useState([]);
  const [hasCategories, setHasCategories] = useState(false);
  const [modalState, setModalState] = useState(
    isList ? 'list' : isNew ? 'add' : 'edit'
  );
  const [showBackButton, setShowBackButton] = useState(false);
  const [hasTabs, setHasTabs] = useState(!isList);
  const [activeTab, setActiveTab] = useState('essentials');
  const [selectedLegal, setSelectedLegal] = useState();
  const [currentLegal, setCurrentLegal] = useState();
  const [modalTabOptions, setModalTabOptions] = useState(legalTabs);
  const [legalIsProposal, setLegalIsProposal] = useState(false);
  const [proposalContent, setProposalContent] = useState();

  useEffect(() => {
    return () => {
      dispatch(clearLegal());
    };
  }, []);

  useEffect(() => {
    if (clearedLegal) {
      setTypeOptions();
      setMembersList();
      setMembersOptions();
      setHasMembersList(false);
      setLegalType();
      setHasApplicables(false);
      setEmpoweredMembersOptions();
      setHasEmpowered(false);
      setBeneficiariesOptions();
      setHasBeneficiaries(false);
      setLoadedData(false);
      setModalError('');
      setModalErrors();
      setErrorTitle();
      setHasLegal(false);
      setHasCategories(false);
      setHasLegals(false);
      setLegalsList([]);
      setShowBackButton(false);
      setHasTabs(false);
      setModalState('add');
      setActiveTab('essentials');
      setLegalIsProposal(false);
      dispatch(updateHouseholdStoreValue('clearedLegal', false));
      setProposalContent();
    }
  }, [clearedLegal]);

  //SET STATE OF MODAL (SUMMARY LIST, ADD, EDIT)
  useEffect(() => {
    if (isList) {
      setModalState('list');
    } else {
      if (isNew) {
        setModalState('add');
        setLoadedData(true);
      } else {
        setModalState('edit');
      }
    }
  }, [isNew, isList]);

  //DEPENDING ON MODAL STATE (LIST/ADD/EDIT) SET TABS, HEADER, ETC
  useEffect(() => {
    if (modalState) {
      setModalTabOptions([
        ...legalTabs,
        ...(showProposalMode && modalState === 'edit' && legalIsProposal
          ? [{ label: 'Drafts', value: 'proposals' }]
          : []),
      ]);
      setHasTabs(modalState !== 'list');
      setActiveTab('essentials');
      if (modalState === 'list') {
        setModalHeader('Legal');
        setShowBackButton(false);
        setLegalIsProposal(false);
        setCurrentLegal();
        setProposalContent();
      }
      if (modalState === 'add') {
        setModalHeader('Add Legal');
      }
      if (modalState === 'edit') {
        setModalHeader('Edit Legal');
      }
    }
  }, [modalState, showProposalMode, legalIsProposal]);

  //SET LEGAL FROM STORE LOCALLY
  useEffect(() => {
    if (legal) {
      setHasLegal(true);
      setCurrentLegal(legal);
      setLegalIsProposal(false);
    }
  }, [legal]);

  //SET PROPOSAL LEGAL FROM STORE LOCALLY
  useEffect(() => {
    if (proposalLegal) {
      setHasLegal(true);
      setLegalIsProposal(true);
      setCurrentLegal(proposalLegal);
      setProposalContent({
        ...proposalLegal?.proposal_action_data,
        name: proposalLegal?.reference_name,
        type: 'legal',
        membersList: membersList,
      });
      dispatch(updateHouseholdStoreValue('proposalLegal', null));
    }
  }, [proposalLegal]);

  //SET WHETHER LEGAL AS SELECTED MEMBERS FOR EACH TIME
  useEffect(() => {
    if (legalState) {
      setHasApplicables(legalState?.applicable_members.length !== 0);
      setHasEmpowered(legalState?.empowered_members.length !== 0);
      setHasBeneficiaries(legalState?.beneficiary_members.length !== 0);
    }
  }, [
    legalState?.applicable_members,
    legalState?.empowered_members,
    legalState?.beneficiary_members,
  ]);

  useEffect(() => {
    if (membersLegals) {
      setHasLegals(true);
    }
  }, [membersLegals]);

  useEffect(() => {
    if (legalCategories) {
      const optionsArray = ConvertObjectToArray(legalCategories);
      const sortedOptions = optionsArray.sort(
        (a, b) => a.priority - b.priority
      );
      const transformedCategories = sortedOptions.map((option, index) => {
        option.value = option.key;
        option.label = option.name;
        option.index = index;
        return option;
      });
      setLegalType(transformedCategories[0]);
      updateLegalType(transformedCategories[0]);
      setTypeOptions(transformedCategories);
      setHasCategories(true);
    }
  }, [legalCategories]);

  useEffect(() => {
    if (hasMembersList) {
      const membersOptions = FilterMembersByContext(
        membersList,
        'instrument_owner'
      );
      setMembersOptions(membersOptions);
      const empoweredOptions = FilterMembersByContext(
        membersList,
        'legal_empowered'
      );
      setEmpoweredMembersOptions(empoweredOptions);
      const beneficiariesOptions = FilterMembersByContext(
        membersList,
        'instrument_beneficiary'
      );
      setBeneficiariesOptions(beneficiariesOptions);
    }
  }, [hasMembersList]);

  useEffect(() => {
    if (modalErrors) {
      if (!modalErrors || Object.entries(modalErrors).length === 0) {
        setModalError('');
      }
    }
  }, [modalErrors]);

  //SET VALUES FOR CURRENT LEGAL ONCE LOADED FROM STORE
  useEffect(() => {
    if (modalState === 'edit' && hasLegal && hasMembersList) {
      resetLegal(currentLegal);
      setLoadedData(true);
    }
  }, [modalState, hasLegal, hasMembersList]);

  //SET LEGALS LIST AND SET LOADED FOR LIST VIEW
  useEffect(() => {
    if (modalState === 'list' && hasLegals && hasMembersList && hasCategories) {
      setLoadedData(true);
      setLegalsList(membersLegals);
    }
  }, [modalState, hasLegals, hasMembersList, hasCategories, membersLegals]);

  useEffect(() => {
    if (isNew && !isModal && selectedType) {
      updateLegalType(selectedType);
    }
  }, [isModal, isNew, selectedType]);

  UseGenerateMembersList(
    currentHousehold,
    setMembersList,
    true,
    setHasMembersList
  );

  UseHandleInstrumentError(
    legalError,
    setButtonLoading,
    setModalError,
    setModalErrors,
    setErrorTitle,
    setLoadedData
  );

  const updateLegalType = (e) => {
    if (typeof e === 'string' && legalCategories) {
      e = legalCategories[e];
    }
    setLegalType(e);
    dispatchLegal({ type: 'category', payload: e?.value || e });
  };

  const updateApplicableMembers = (e, overwrite) => {
    let selectedApplicables = [
      ...legalState.applicable_members,
      ...(Array.isArray(e) ? e : [e]),
    ];
    if (overwrite) {
      selectedApplicables = [...(Array.isArray(e) ? e : [e])];
    }
    // select or deselect?
    const match = legalState.applicable_members.some(
      (am) => am.uuid === e.uuid
    );
    if (match) {
      selectedApplicables = legalState.applicable_members.filter(
        (am) => am.uuid !== e.uuid
      );
      // update the membersList
      setMembersList(
        membersList.map((member) => {
          if (member.uuid === e.uuid) {
            member.isApplicable = false;
          }
          return member;
        })
      );
    }
    // set options dropdown
    const membersOptions = FilterMembersByContext(
      membersList,
      'instrument_owner'
    );
    setMembersOptions(membersOptions);
    // set selected
    dispatchLegal({
      type: 'applicable_members',
      payload: TransformApplicables(membersOptions, selectedApplicables).filter(
        (member) => member.isApplicable === true
      ),
    });
  };

  const updateEmpoweredMembers = (e, overwrite) => {
    let selectedEmpowered = [
      ...legalState.empowered_members,
      ...(Array.isArray(e) ? e : [e]),
    ];
    if (overwrite) {
      selectedEmpowered = [...(Array.isArray(e) ? e : [e])];
    }
    // if passing in a single value then you are selecting or deselecting that single member
    if (!Array.isArray(e)) {
      const alreadySelected = legalState.empowered_members.some(
        (am) => am.uuid === e.uuid
      );
      if (alreadySelected) {
        // remove from selected members list
        selectedEmpowered = legalState.empowered_members.filter(
          (am) => am.uuid !== e.uuid
        );
        // update the membersList
        setMembersList(
          membersList.map((member) => {
            if (member.uuid === e.uuid) {
              member.isEmpowered = false;
            }
            return member;
          })
        );
      }
    }
    // set options dropdown
    const empoweredOptions = FilterMembersByContext(
      membersList,
      'legal_empowered'
    );
    setEmpoweredMembersOptions(empoweredOptions);
    // set selected
    dispatchLegal({
      type: 'empowered_members',
      payload: TransformEmpowered(empoweredOptions, selectedEmpowered).filter(
        (member) => member.isEmpowered === true
      ),
    });
  };

  const updateBeneficiaries = (e, overwrite) => {
    let selectedBeneficiaries = [
      ...legalState.beneficiary_members,
      ...(Array.isArray(e) ? e : [e]),
    ];
    if (overwrite) {
      selectedBeneficiaries = [...(Array.isArray(e) ? e : [e])];
    }
    // select or deselect?
    const match = legalState.beneficiary_members.some(
      (am) => am.uuid === e.uuid
    );
    if (match) {
      selectedBeneficiaries = legalState.beneficiary_members.filter(
        (am) => am.uuid !== e.uuid
      );
      // update the membersList
      setMembersList(
        membersList.map((member) => {
          if (member.uuid === e.uuid) {
            member.isBeneficiary = false;
          }
          return member;
        })
      );
    }
    // set options (dropdown)
    const beneficiariesOptions = FilterMembersByContext(
      membersList,
      'instrument_beneficiary'
    );
    setBeneficiariesOptions(beneficiariesOptions);
    // set selected
    dispatchLegal({
      type: 'beneficiary_members',
      payload: TransformLegalBeneficiaries(
        beneficiariesOptions,
        selectedBeneficiaries
      ).filter((member) => member.isBeneficiary === true),
    });
  };

  const changeValue = (e, field, overwrite = false) => {
    switch (field) {
      case 'category':
        updateLegalType(e);
        break;
      case 'applicable_members':
        updateApplicableMembers(e, overwrite);
        if (CheckHasError(modalErrors, 'applicable_members')) {
          setModalErrors(RemoveError(modalErrors, 'applicable_members'));
        }
        break;
      case 'reference_name':
      case 'location':
        dispatchLegal({
          type: field,
          payload:
            e?.target?.value !== null && e?.target?.value !== undefined
              ? e?.target?.value
              : e,
        });
        break;
      case 'empowered_members':
        updateEmpoweredMembers(e, overwrite);
        break;
      case 'beneficiary_members':
        updateBeneficiaries(e, overwrite);
        break;
      case 'date_created':
      case 'date_collected':
        dispatchLegal({
          type: field,
          payload: e !== '' ? DateFormat(e, 'YYYY-MM-DD') : '',
        });
        break;
      default: // notes
        dispatchLegal({ type: field, payload: e });
        break;
    }
  };

  const validationChecker = (name) => {
    if (CheckHasError(modalErrors, name)) {
      const updatedErrors = RemoveError(modalErrors, name);
      setModalErrors(updatedErrors);
    }
  };

  const dateFormatWithoutDD = (dateFormatString) => {
    return dateFormatString
      .split('/')
      .filter((part) => !part.toLowerCase().match(/^dd$/))
      .map((part) => (part === 'YYYY' ? 'yyyy' : part))
      .join('/');
  };

  const essentialInputs = [
    {
      type: 'select',
      label: 'Type',
      name: 'category',
      required: true,
      placeholder: 'Type',
      value: legalType,
      options: typeOptions,
      width: '100%',
      disabled: (isNew && !isModal) || (showBackButton && modalState === 'add'),
      isVisible: true,
      onChange: (e) => changeValue(e, 'category'),
      hasError: CheckHasError(modalErrors, 'category'),
      errorMessage: GenerateError(modalErrors, 'category'),
    },
    {
      type: 'select',
      label: 'Applicable Members',
      name: 'applicable_members',
      required: true,
      placeholder: 'Applicable members',
      customSelect: true,
      customComponent: LegalApplicableMemberOption,
      width: '100%',
      isVisible: true,
      value: { label: 'Select Members', value: '' },
      options: membersOptions,
      onChange: (e) => changeValue(e, 'applicable_members'),
      selectedOptions: legalState.applicable_members,
      showSelected: hasApplicables,
      hasSelected: true,
      tooltip: 'On whose behalf; e.g. Principal, Grantor, Testator',
      hasError: CheckHasError(modalErrors, 'applicable_members'),
      errorMessage: GenerateError(modalErrors, 'applicable_members'),
    },
    {
      type: 'text',
      label: 'Reference Name',
      name: 'reference_name',
      required: true,
      placeholder: 'e.g. Will, Trust, POA',
      value: legalState.reference_name,
      onChange: (e) => changeValue(e, 'reference_name'),
      isVisible: true,
      width: '100%',
      hasError: CheckHasError(modalErrors, 'reference_name'),
      errorMessage: GenerateError(modalErrors, 'reference_name'),
      onFocus: () => validationChecker('reference_name'),
    },
    {
      type: 'text',
      label: 'Location',
      name: 'location',
      required: true,
      placeholder: "e.g. Institution, Attorney's Office",
      value: legalState.location,
      onChange: (e) => changeValue(e, 'location'),
      isVisible: true,
      width: '100%',
      hasError: CheckHasError(modalErrors, 'location'),
      errorMessage: GenerateError(modalErrors, 'location'),
      onFocus: () => validationChecker('location'),
    },
    {
      type: 'select',
      label: 'Empowered Members',
      name: 'empowered_members',
      placeholder: 'Empowered members',
      customSelect: true,
      customComponent: LegalEmpoweredMemberOption,
      width: '100%',
      isVisible: true,
      value: { label: 'Select Members', value: '' },
      options: empoweredMembersOptions,
      onChange: (e) => changeValue(e, 'empowered_members'),
      selectedOptions: legalState.empowered_members,
      showSelected: hasEmpowered,
      hasSelected: true,
      tooltip:
        'The authorized or responsible party, e.g. executor, trustee, agent, attorney-in-fact, personal representative',
      hasError: CheckHasError(modalErrors, 'empowered_members'),
      errorMessage: GenerateError(modalErrors, 'empowered_members'),
    },
    {
      type: 'select',
      label: 'Primary Beneficiary',
      name: 'beneficiary_members',
      placeholder: 'Primary Beneficiary',
      customSelect: true,
      customComponent: BeneficiaryOption,
      width: '100%',
      isVisible: true,
      value: { label: 'Select Members', value: '' },
      options: beneficiariesOptions,
      onChange: (e) => changeValue(e, 'beneficiary_members'),
      selectedOptions: legalState.beneficiary_members,
      showSelected: hasBeneficiaries,
      hasSelected: true,
      hasError: CheckHasError(modalErrors, 'beneficiary_members'),
      errorMessage: GenerateError(modalErrors, 'beneficiary_members'),
    },
    {
      type: 'date',
      label: 'Date Executed',
      name: 'date_created',
      width: '48%',
      required: false,
      dateFormat: dateFormatWithoutDD(preferences?.date_format),
      placeholder: dateFormatWithoutDD(preferences?.date_format).toLowerCase(),
      value: legalState.date_created,
      isVisible: true,
      onChange: (e) => changeValue(e, 'date_created'),
      hasError: CheckHasError(modalErrors, 'date_created'),
      errorMessage: GenerateError(modalErrors, 'date_created'),
    },
    {
      type: 'date',
      label: 'Data Collected',
      name: 'date_collected',
      width: '48%',
      required: false,
      dateFormat: preferences?.date_format,
      placeholder: preferences?.date_format.toLowerCase(),
      value: legalState.date_collected,
      isVisible: true,
      onChange: (e) => changeValue(e, 'date_collected'),
      hasError: CheckHasError(modalErrors, 'date_collected'),
      errorMessage: GenerateError(modalErrors, 'date_collected'),
    },
  ];

  const notesInput = [
    {
      type: 'textarea',
      label: 'Notes',
      name: 'notes',
      showClear: true,
      id: 'asset',
      value: legalState.notes,
      onChange: (e) => changeValue(e, 'notes'),
      hasError: CheckHasError(modalErrors, 'notes'),
      errorMessage: GenerateError(modalErrors, 'notes'),
    },
  ];

  const onSaveLegal = () => {
    const legalType = legalCategories?.[legalState?.category];
    let typeDisplay = 'Legal';
    if (legalType?.name) {
      typeDisplay = legalType?.name;
    }
    setButtonLoading(true);
    setModalErrors();
    if (modalState === 'add') {
      dispatch(
        showProposalMode
          ? createProposalLegal(
              currentHousehold?.uuid,
              proposalId,
              legalState,
              typeDisplay
            )
          : createLegal(householdId, legalState, typeDisplay, !isModal)
      );
    } else {
      dispatch(
        showProposalMode
          ? updateProposalLegal(
              currentHousehold?.uuid,
              proposalId,
              legalState,
              legalState?.id,
              typeDisplay,
              legalState?.proposal_action_data?.id
            )
          : updateLegal(
              householdId,
              legal.id,
              legalState,
              typeDisplay,
              !isModal
            )
      );
    }
  };

  const handleClickLegal = (legal) => {
    setModalState('edit');
    setLoadedData(false);
    setHasLegal(false);
    if (showProposalMode && legal?.is_proposal) {
      dispatch(
        readProposalLegal(
          currentHousehold.uuid,
          currentProposal?.id,
          legal?.proposal_action_id
        )
      );
    } else {
      dispatch(getLegal(currentHousehold.id, legal.id));
    }
    setShowBackButton(true);
    setSelectedLegal(legal);
  };

  const onDeleteLegal = () => {
    dispatch(
      toggleShowDeleteModal(
        true,
        'legal',
        currentLegal.reference_name,
        currentLegal.id,
        true,
        currentLegal,
        !isModal
      )
    );
  };

  const handleClickCategory = (category) => {
    setModalState('add');
    setShowBackButton(true);
    updateLegalType(category.value);
  };

  const resetLegal = (legal) => {
    setMembersList(
      membersList.map((member) => {
        member.isSelected = false;
        member.isApplicable = false;
        member.isEmpowered = false;
        member.isBeneficiary = false;
        delete member.ratio;
        return member;
      })
    );
    for (let field in legal) {
      changeValue(legal[field], field, true);
    }
  };

  const handleClickBack = () => {
    setModalState('list');
    setShowBackButton(false);
    resetLegal(initLegalState);
  };

  return (
    <LegalDisplayContainer
      isModal={isModal}
      isNew={isNew}
      hide={hide}
      modalHeader={modalHeader}
      hasTabs={hasTabs}
      modalTabOptions={modalTabOptions}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      loadedData={loadedData}
      currentLegal={currentLegal}
      legalsList={legalsList}
      handleClickLegal={handleClickLegal}
      hasCategories={hasCategories}
      categoryOptions={typeOptions}
      handleClickCategory={handleClickCategory}
      selectedLegal={selectedLegal}
      showBackButton={showBackButton}
      essentialInputs={essentialInputs}
      notesInput={notesInput}
      handleClickBack={handleClickBack}
      onSaveLegal={onSaveLegal}
      buttonLoading={buttonLoading}
      onDeleteLegal={onDeleteLegal}
      modalState={modalState}
      modalErrors={modalErrors}
      modalError={modalError}
      errorTitle={errorTitle}
      proposalContent={proposalContent}
    />
  );
};

LegalContainer.propTypes = {
  hide: PropTypes.func,
  householdId: PropTypes.string,
  isNew: PropTypes.bool,
  isList: PropTypes.bool,
  proposalId: PropTypes.string,
  showProposalMode: PropTypes.bool,
};

export default LegalContainer;
