/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { RelationshipDisplayContainer } from '../../components';
import { calendar, share } from '../../assets';
import {
  GetStates,
  SetDefaultItem,
  GenerateAge,
  CheckHasError,
  GenerateError,
  states,
  modalTabs,
  integrationOptions,
  HtmlToString,
  DetectEnterKeyPress,
  UseSyncIntegrationContent,
  CapitalizeFirstLetter,
  allowedDateFormats,
  DateNow,
  DateFormat,
  DateSubtract,
  DateFormatFromList,
  SendTrackingEvent,
  clientModalTabs,
} from '../../utils';
import {
  createMember,
  updateMember,
  toggleShowDeleteModal,
  getMemberIntegrationInfo,
  unlinkSalesforceMember,
  getPrimaryMember,
  syncHouseholdModal,
  setMemberIntegrationInfo,
  updateMemberStoreValue,
  clearMemberInfo,
} from '../../store/actions';
import { ButtonThemes } from '../../styles/themes';

const MemberContainer = ({
  isModal = true,
  viewOnly = false,
  hide,
  isNew,
  isPersonal,
  householdId,
  defaultTab,
  invisible,
  isConsumer,
  itemDetails,
}) => {
  const dispatch = useDispatch();
  const {
    integrations,
    preferences,
    showExcludedMembers,
    showHouseholdSharing,
    member,
    loadedMemberFromAPI,
    primaryMember,
    clearedMemberInfo,
    members,
    memberError,
    memberCategories,
    memberGenderOptions,
    countries,
    currentHousehold,
    updatedMember,
    memberIntegration,
    memberIntegrationError,
    householdIntegrationsInfo,
    justSynced,
    createInviteError,
    allMemberCategories,
    loadedHouseholdFields,
    invocationId,
  } = useSelector((state) => ({
    integrations: state.user?.user?.integrations,
    preferences: state.user.user.preferences,
    showExcludedMembers: state.user.showExcludedMembers,
    showHouseholdSharing: state.user.showHouseholdSharing,
    member: state.members.member,
    loadedMemberFromAPI: state.members.loadedMemberFromAPI,
    primaryMember: state.members.primaryMember,
    clearedMemberInfo: state.members.clearedMemberInfo,
    members: state.households.currentHousehold.householdmembers,
    memberError: state.members.memberError,
    memberCategories: state.configs.memberCategories,
    memberGenderOptions: state.configs.memberGenderOptions,
    countries: state.configs.countries,
    currentHousehold: state.households.currentHousehold,
    updatedMember: state.members.updatedMember,
    memberIntegration: state.members.memberIntegration,
    memberIntegrationError: state.members.memberIntegrationError,
    householdIntegrationsInfo: state.households.currentHousehold.integrations,
    justSynced: state.households.justSynced,
    createInviteError: state.households.createInviteError,
    allMemberCategories: state.configs.allMemberCategories,
    loadedHouseholdFields: state.configs.loadedHouseholdFields,
    invocationId: state.configs.invocationId,
  }));
  const [modalTabOptions, setModalTabOptions] = useState(modalTabs);
  const [activeTab, setActiveTab] = useState('essentials');
  const defaultMember = {
    first_name: '',
    last_name: '',
    date_of_birth: '',
    date_picker_value: '',
    date_of_birth_estimated: false,
    is_dependent: false,
    is_active: true,
    description: '',
    address: '',
    address2: '',
    city: '',
    zip: '',
    phone_number: '',
    email: '',
    notes: '',
  };
  const [currentMember, setCurrentMember] = useState(defaultMember);
  const [loadedMember, setLoadedMember] = useState(false);
  const [selectedRole, setSelectedRole] = useState();
  const [selectedGender, setSelectedGender] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedCitizenship, setSelectedCitizenship] = useState();
  const [selectedState, setSelectedState] = useState();
  const [stateDisabled, setStateDisabled] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [memberRoles, setMemberRoles] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [disabledFields, setDisabledFields] = useState([]);
  const [copyingPrimary, setCopyingPrimary] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState();
  const [integrationContent, setIntegrationContent] = useState();
  const [integrationError, setIntegrationError] = useState();
  const [loadingIntegrationContent, setLoadingIntegrationContent] =
    useState(true);
  const [isUnlinkingMember, setIsUnlinkingMember] = useState(false);
  const [modalError, setModalError] = useState('');
  const [modalErrors, setModalErrors] = useState();
  const [genderOptions, setGenderOptions] = useState([]);
  const [showSyncButton, setShowSyncButton] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [lastHouseholdSync, setLastHouseholdSync] = useState();
  const [memberDisplay, setMemberDisplay] = useState('Member');
  const [showRemoveWarning, setShowRemoveWarning] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(setMemberIntegrationInfo(null));
      dispatch(clearMemberInfo());
    };
  }, []);

  useEffect(() => {
    if (clearedMemberInfo) {
      dispatch(updateMemberStoreValue('clearedMemberInfo', false));
    }
  }, [clearedMemberInfo]);

  useEffect(() => {
    if (isPersonal) {
      setCurrentMember({ ...currentMember, is_active: false });
      setMemberDisplay('Personal Relationship');
    } else {
      setMemberDisplay('Member');
    }
  }, [isPersonal]);

  useEffect(() => {
    if (updatedMember) {
      setButtonLoading(false);
    }
  }, [updatedMember]);

  useEffect(() => {
    if (memberError) {
      setButtonLoading(false);
      setLoadedMember(true);
      if (memberError?.data) {
        if (memberError.data?.message) {
          return setModalError(memberError.data?.message);
        }
        setModalErrors(memberError?.data);
        const length = Object.keys(memberError?.data).length;
        if (length === 1) {
          return setModalError(
            memberError.data[Object.keys(memberError.data)[0]]
          );
        }
      } else {
        setModalError('Please fix errors and try saving again.');
      }
    }
  }, [memberError]);

  useEffect(() => {
    if (loadedHouseholdFields) {
      if (memberCategories && memberCategories.length !== 0) {
        setMemberRoles(memberCategories);
      }
      if (memberGenderOptions && memberGenderOptions.length !== 0) {
        setGenderOptions(memberGenderOptions);
      }
    }
  }, [loadedHouseholdFields, memberCategories, memberGenderOptions]);

  useEffect(() => {
    if (countries && countries.length !== 0) {
      setCountryList(
        countries.map((country) => {
          country.label = country.name;
          country.value = country.code;
          return country;
        })
      );
    }
  }, [countries]);

  useEffect(() => {
    if (activeTab) {
      if (!modalTabs.map((tab) => tab.value).includes(activeTab)) {
        let matchedIntegration = integrationOptions.find(
          (int) => int.id === activeTab
        );
        if (matchedIntegration) {
          setSelectedIntegration(matchedIntegration);
          setLoadingIntegrationContent(true);
          dispatch(
            getMemberIntegrationInfo(currentHousehold.id, member.id, activeTab)
          );
        }
      } else {
        setIntegrationContent();
      }
    }
  }, [activeTab]);

  useEffect(() => {
    if (memberIntegration) {
      setLoadingIntegrationContent(false);
      setIntegrationContent(memberIntegration);
      setIntegrationError();
    }
  }, [memberIntegration]);

  useEffect(() => {
    if (memberIntegrationError) {
      setLoadingIntegrationContent(false);
      setIntegrationError(memberIntegrationError);
    }
  }, [memberIntegrationError]);

  useEffect(() => {
    if (currentHousehold && currentHousehold.hasOwnProperty('id')) {
      if (isNew) {
        setLoadedMember(true);
        SetDefaultItem(countries, currentHousehold.country, setSelectedCountry);
        SetDefaultItem(
          countries,
          currentHousehold.country,
          setSelectedCitizenship
        );
      }
    }
  }, [currentHousehold, isNew]);

  useEffect(() => {
    if (selectedCountry && selectedCountry.value !== 'US') {
      setSelectedState(null);
      setStateDisabled(true);
    } else {
      setStateDisabled(false);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedRole) {
      setModalError('');
      setModalErrors();
      if (selectedRole.value === 'child' && isNew) {
        setCurrentMember({
          ...currentMember,
          is_dependent: true,
        });
      }
    }
  }, [selectedRole]);

  useEffect(() => {
    if (createInviteError) {
      const errorData = createInviteError?.data;
      let errorMessage = 'Error creating invite';
      if (errorData) {
        let errors = [];
        for (let [key, value] of Object.entries(errorData)) {
          errors.push({ field: key, message: value });
        }
        errorMessage = errors.map((error) => {
          if (error.field === 'non_field_errors') {
            return `${error.message}`;
          }
          let fieldName = error.field.replaceAll('_', ' ');
          fieldName = CapitalizeFirstLetter(fieldName);
          return `${fieldName}: ${error.message}`;
        });
      }
      setModalError(errorMessage);
    } else if (createInviteError === null) {
      setModalError();
    }
  }, [createInviteError]);

  const matchRole = (role) => {
    let matched = memberRoles.find((mem) => mem.value === role);
    if (matched) {
      return setSelectedRole(matched);
    }
  };

  const matchGender = (gender) => {
    let matched = genderOptions.find((gen) => gen.value === gender);
    if (matched) {
      return setSelectedGender(matched);
    }
  };

  const matchState = (stateId) => {
    let matched = states.find((state) => state.code === stateId);
    if (matched) {
      return setSelectedState({ label: matched.name, value: matched.code });
    }
  };

  const matchCountry = (countryId, isCountry = false) => {
    let matched = countryList.find((country) => country.value === countryId);
    if (matched) {
      if (isCountry) {
        return setSelectedCountry(matched);
      } else {
        return setSelectedCitizenship(matched);
      }
    }
  };

  useEffect(() => {
    if (!isNew) {
      if (member && member.hasOwnProperty('id') && loadedHouseholdFields) {
        let integrationTabs = [];
        if (member.hasOwnProperty('integrations')) {
          if (member.integrations.hasOwnProperty('available')) {
            const householdIntegrations = member.integrations.available;
            //Need to check if user has these enabled, if yes then set it as tab
            if (householdIntegrations.length !== 0 && integrations) {
              integrationTabs = householdIntegrations.reduce((acc, curr) => {
                if (integrations[curr]) {
                  let integrationName = curr;
                  let matchedIntegration = integrationOptions.find(
                    (int) => int.id === curr
                  );
                  if (matchedIntegration) {
                    integrationName = matchedIntegration.name;
                    setSelectedIntegration(matchedIntegration);
                  }
                  return [
                    ...acc,
                    {
                      label: integrationName,
                      value: curr,
                      integration: true,
                      icon: matchedIntegration?.icon
                        ? matchedIntegration?.icon
                        : false,
                    },
                  ];
                }
                return acc;
              }, []);
            }
          }
          if (
            member.integrations.hasOwnProperty('read_only_fields') &&
            member.integrations.read_only_fields.length !== 0
          ) {
            setDisabledFields(member.integrations.read_only_fields);
          } else {
            setDisabledFields([]);
          }
        }
        setModalTabOptions([
          ...modalTabs,
          ...(showHouseholdSharing
            ? [
                {
                  label: 'Client Portal',
                  value: 'connect',
                  icon: share,
                },
              ]
            : []),
          ...integrationTabs,
        ]);
        setCurrentMember({
          id: member.id,
          uuid: member?.uuid,
          role: matchRole(member.category),
          first_name: HtmlToString(member.first_name),
          last_name: HtmlToString(member.last_name),
          age: GenerateAge(member.date_of_birth),
          date_of_birth: DateFormat(
            member.date_of_birth,
            preferences?.date_format
          ),
          date_picker_value: DateFormat(member.date_of_birth, 'MM/DD/YYYY'),
          date_of_birth_estimated: member.date_of_birth_estimated,
          is_dependent: member.is_dependent,
          is_active: member.is_active,
          gender: matchGender(member.gender),
          description: HtmlToString(member.description),
          address: HtmlToString(member.address),
          address2: HtmlToString(member.address2),
          city: HtmlToString(member.city),
          state: matchState(member.state),
          country: matchCountry(member.country, true),
          citizenship: matchCountry(member.citizenship),
          zip: member.zip,
          phone_number: member.phone_number,
          email: member.email,
          notes: member.notes,
        });
        setLoadedMember(loadedMemberFromAPI);
        if (defaultTab) {
          setActiveTab(defaultTab);
        }
      }
    }
  }, [
    member,
    integrations,
    clearedMemberInfo,
    memberRoles,
    genderOptions,
    countryList,
  ]);

  useEffect(() => {
    if (primaryMember) {
      setCurrentMember({
        ...currentMember,
        state: primaryMember?.state ? matchState(primaryMember.state) : null,
        country: primaryMember?.country
          ? matchCountry(primaryMember.country, true)
          : null,
        citizenship: primaryMember?.citizenship
          ? matchCountry(primaryMember.citizenship)
          : null,
        address: primaryMember?.address ? primaryMember.address : '',
        address2: primaryMember?.address2 ? primaryMember.address2 : '',
        city: primaryMember?.city ? primaryMember.city : '',
        zip: primaryMember?.zip ? primaryMember.zip : '',
        phone_number: primaryMember?.phone_number
          ? primaryMember.phone_number
          : '',
        email: primaryMember?.email ? primaryMember.email : '',
      });
      setCopyingPrimary(false);
    }
  }, [primaryMember]);

  const isDOBRequired = () => {
    return !currentMember.is_active &&
      (currentMember.date_of_birth === '' ||
        currentMember.date_of_birth === undefined ||
        currentMember.date_of_birth === null)
      ? false
      : true;
  };

  const saveMember = () => {
    let date_of_birth = false;
    let age = false;

    if (selectedRole === undefined) {
      setModalErrors({
        ...modalErrors,
        role: ['Please select a Relationship.'],
      });
      return setModalError('Please select a Relationship.');
    }
    if (currentMember.first_name === '') {
      setModalErrors({
        ...modalErrors,
        first_name: ['Please enter a First Name.'],
      });
      return setModalError('Please enter a First Name.');
    }
    if (currentMember.last_name === '') {
      setModalErrors({
        ...modalErrors,
        last_name: ['Please enter a Last Name.'],
      });
      return setModalError('Please enter a Last Name.');
    }

    if (isDOBRequired()) {
      if (
        currentMember.date_of_birth === '' ||
        currentMember.date_of_birth === undefined ||
        currentMember.date_of_birth === null
      ) {
        setModalErrors({
          ...modalErrors,
          date_of_birth: ['Please enter an Age or Date of Birth.'],
        });
        return setModalError('Please enter an Age or Date of Birth.');
      }
      const DOBLength = currentMember.date_of_birth.length;
      if (DOBLength <= 3) {
        const ageObj = formatAge(currentMember.date_of_birth);
        if (ageObj.hasError) {
          setModalErrors({
            ...modalErrors,
            date_of_birth: [ageObj.errorValue],
          });
          return setModalError(ageObj.errorValue);
        } else {
          setCurrentMember({
            ...currentMember,
            date_of_birth: ageObj.age,
          });
          age = ageObj.age;
        }
      } else {
        const dateObj = formatDate(currentMember.date_of_birth);
        if (dateObj.hasError) {
          setModalErrors({
            ...modalErrors,
            date_of_birth: [dateObj.errorValue],
          });
          return setModalError(dateObj.errorValue);
        } else {
          setCurrentMember({
            ...currentMember,
            date_of_birth: dateObj.date,
            date_picker_value: dateObj.date,
          });
          date_of_birth = DateFormat(
            dateObj.date,
            preferences?.date_format,
            true
          );
        }
      }
    }

    let gender = 'N';
    if (selectedGender && selectedGender.value) {
      gender = selectedGender.value;
    }
    let state = '';
    if (selectedState && selectedState.value) {
      state = selectedState.value;
    }
    let country = '';
    if (selectedCountry && selectedCountry.value) {
      country = selectedCountry.value;
    }
    let citizenship = '';
    if (selectedCitizenship && selectedCitizenship.value) {
      citizenship = selectedCitizenship.value;
    }

    const memberObj = {
      category: selectedRole.value,
      first_name: currentMember.first_name,
      last_name: currentMember.last_name,
      // age: parseInt(currentMember.age),
      // date_of_birth: DateFormat(currentMember.date_of_birth,'YYYY-MM-DD'),
      // age,
      // date_of_birth,
      gender,
      member_type: 'member',
      date_of_birth_estimated: currentMember.date_of_birth_estimated,
      is_dependent: currentMember.is_dependent,
      is_active: currentMember.is_active,
      description: currentMember.description,
      address: currentMember.address,
      address2: currentMember.address2,
      city: currentMember.city,
      state,
      country,
      citizenship,
      zip: currentMember.zip,
      phone_number: currentMember.phone_number,
      email: currentMember.email,
      notes: currentMember.notes,
    };
    if (age) {
      memberObj.age = age;
      memberObj.date_of_birth_estimated = true;
    }
    if (date_of_birth) {
      memberObj.date_of_birth = date_of_birth;
    }
    if (!isDOBRequired()) {
      memberObj.date_of_birth = null;
    }
    setButtonLoading(true);
    if (isNew) {
      dispatch(createMember(householdId, memberObj, isConsumer, !isModal));
    } else {
      dispatch(
        updateMember(householdId, member.id, memberObj, isConsumer, !isModal)
      );
    }
  };

  const saveOnEnter = () => {
    if (buttonLoading || activeTab === 'notes') {
      return;
    }
    return saveMember();
  };

  const openDeleteMember = () => {
    dispatch(
      toggleShowDeleteModal(
        true,
        isPersonal ? 'personal' : 'member',
        member.name,
        member.id,
        null,
        null,
        !isModal
      )
    );
  };

  const updateCurrentMember = (e, isSelect = false, fieldName = null) => {
    setModalError('');
    setModalErrors();
    if (isSelect) {
      setCurrentMember({
        ...currentMember,
        [fieldName]: e,
      });
    } else {
      setCurrentMember({
        ...currentMember,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    }
  };

  const isDisabled = (input) => {
    if (viewOnly) {
      return true;
    } else if (!isNew && disabledFields.length !== 0) {
      return disabledFields.includes(input);
    }
    return false;
  };

  const onDateChange = (e) => {
    const selectedDate = DateFormat(e, preferences?.date_format);
    setCurrentMember({
      ...currentMember,
      date_of_birth: selectedDate,
      date_picker_value: e,
    });
  };

  const formatAge = (age) => {
    const ageObj = {
      age: age,
      hasError: false,
      errorValue: 'Please enter a valid Age.',
    };
    const parsed = parseInt(age);
    if (isNaN(parsed)) {
      ageObj.hasError = true;
    } else {
      ageObj.parsed = parsed;
      ageObj.hasError = false;
      ageObj.errorValue = '';
    }
    return ageObj;
  };

  const formatDate = (date) => {
    const dateObj = {
      date: date,
      hasError: false,
      errorValue: `Please enter a valid Date of Birth in ${preferences?.date_format.toLowerCase()} format.`,
    };
    let dateAsMomentDate = DateFormatFromList(
      date,
      allowedDateFormats[preferences?.date_format],
      true
    );
    const isValid = dateAsMomentDate.isValid();
    if (isValid) {
      // If date is in future, assume 2-digit date parsed to current century instead of previous
      if (dateAsMomentDate > DateNow()) {
        dateAsMomentDate = DateSubtract(dateAsMomentDate, 100);
      }
      const dateCorrectedInTextFormat = DateFormat(
        dateAsMomentDate,
        preferences?.date_format
      );
      const age = GenerateAge(dateAsMomentDate, preferences?.date_format);
      dateObj.date = dateCorrectedInTextFormat;
      dateObj.age = age;
      dateObj.hasError = false;
      dateObj.errorValue = '';
    } else {
      dateObj.hasError = true;
    }
    return dateObj;
  };

  const dateOnBlur = (e) => {
    const date = e.currentTarget.value.trim();
    //Check if date length is short
    const dateLength = date.length;
    //If its 3 or less digits assume its age
    setCurrentMember({
      ...currentMember,
      date_of_birth: date,
    });
    if (isDOBRequired()) {
      if (dateLength <= 3) {
        const ageObj = formatAge(date);
        if (ageObj.hasError) {
          setModalErrors({
            ...modalErrors,
            date_of_birth: [ageObj.errorValue],
          });
          return setModalError(ageObj.errorValue);
        } else {
          setCurrentMember({
            ...currentMember,
            date_of_birth: date,
          });
        }
      } else {
        const dateObj = formatDate(date);
        if (dateObj.hasError) {
          setModalErrors({
            ...modalErrors,
            date_of_birth: [dateObj.errorValue],
          });
          return setModalError(dateObj.errorValue);
        } else {
          setCurrentMember({
            ...currentMember,
            date_of_birth: dateObj.date,
            date_picker_value: dateObj.date,
          });
        }
      }
    }
  };

  const isActiveOnChange = (currentMember) => {
    const showWarning = currentMember.is_active && !isPersonal;
    if (showWarning) {
      SendTrackingEvent(
        invocationId,
        'click',
        'exclude_member_from_household',
        'household_members',
        {
          isConsumer: isConsumer,
        }
      );
      return setShowRemoveWarning(true);
    }
    setCurrentMember({
      ...currentMember,
      is_active: !currentMember.is_active,
    });
  };

  const essentialInputs = [
    {
      type: 'select',
      label: 'Relationship',
      name: 'role',
      required: true,
      placeholder: 'Select Relationship',
      value: selectedRole,
      options: memberRoles,
      width: '100%',
      isVisible: true,
      disabled: isDisabled('category'),
      onChange: (e) => setSelectedRole(e),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'role'),
      errorMessage: GenerateError(modalErrors, 'role'),
    },
    {
      type: 'text',
      label: 'Relationship to Display',
      maxLength: '100',
      name: 'description',
      width: '100%',
      required: false,
      placeholder: 'Father, Mother, Daughter, Son, Niece, etc.',
      value: HtmlToString(currentMember.description),
      isVisible: true,
      disabled: isDisabled('description'),
      onChange: (e) => updateCurrentMember(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, modalErrors, 'description'),
      errorMessage: GenerateError(modalErrors, 'description'),
    },
    {
      type: 'text',
      label: 'First Name',
      maxLength: '45',
      name: 'first_name',
      width: isModal ? '48%' : '100%',
      required: true,
      placeholder: 'First Name',
      value: HtmlToString(currentMember.first_name),
      isVisible: true,
      disabled: isDisabled('first_name'),
      onChange: (e) => updateCurrentMember(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'first_name'),
      errorMessage: GenerateError(modalErrors, 'first_name'),
    },
    {
      type: 'text',
      label: 'Last Name',
      maxLength: '45',
      name: 'last_name',
      width: isModal ? '48%' : '100%',
      required: true,
      placeholder: 'Last Name',
      value: HtmlToString(currentMember.last_name),
      isVisible: true,
      disabled: isDisabled('last_name'),
      onChange: (e) => updateCurrentMember(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'last_name'),
      errorMessage: GenerateError(modalErrors, 'last_name'),
    },
    {
      type: 'text',
      label: 'Date Of Birth or Age',
      maxLength: '10',
      name: 'date_of_birth',
      width: isModal ? '48%' : '100%',
      required: currentMember.is_active,
      placeholder: `${preferences?.date_format.toLowerCase()} or age`,
      dateFormat: preferences?.date_format,
      value: currentMember.date_of_birth,
      dateValue: currentMember.date_picker_value,
      isVisible: true,
      disabled: isDisabled('date_of_birth'),
      onChange: (e) => updateCurrentMember(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'date_of_birth'),
      errorMessage: GenerateError(modalErrors, 'date_of_birth'),
      ageDate: true,
      iconSrc: calendar,
      onBlur: (e) => dateOnBlur(e),
      onDateChange: (e) => onDateChange(e),
      isCenterDatePicker: isModal,
    },
    // {
    //   type: 'date',
    //   label: 'Date of Birth',
    //   name: 'date_of_birth',
    //   width: '48%',
    //   // maxDate: new Date(),
    //   required: true,
    //   placeholder: 'mm/dd/yyyy',
    //   value: currentMember.date_of_birth,
    //   isVisible: true,
    //   disabled: isDisabled('date_of_birth'),
    //   onChange: (e) => updateCurrentMember(e, true, 'date_of_birth'),
    //   hasError: CheckHasError(modalErrors, 'date_of_birth'),
    //   errorMessage: GenerateError(modalErrors, 'date_of_birth'),
    // },
    {
      type: 'select',
      label: 'Gender',
      name: 'gender',
      width: isModal ? '48%' : '100%',
      required: false,
      placeholder: 'Select Gender',
      options: genderOptions,
      value: selectedGender,
      isVisible: true,
      disabled: isDisabled('gender'),
      onChange: (e) => setSelectedGender(e),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'gender'),
      errorMessage: GenerateError(modalErrors, 'gender'),
    },
    {
      type: 'checkbox',
      label: 'Date of Birth is Estimated',
      name: 'date_of_birth_estimated',
      required: false,
      width: isModal ? '48%' : '100%',
      margin: '0 3px 10px -3px',
      value: currentMember.date_of_birth_estimated,
      isVisible: true,
      disabled: isDisabled('date_of_birth_estimated'),
      onChange: () =>
        setCurrentMember({
          ...currentMember,
          date_of_birth_estimated: !currentMember.date_of_birth_estimated,
        }),
      // hasError: layoutNameFieldError,
    },
    {
      type: 'checkbox',
      label: 'Dependent',
      name: 'is_dependent',
      width: isModal ? '49%' : '100%',
      margin: '0 0 10px -3px',
      required: false,
      value: currentMember.is_dependent,
      isVisible: true,
      disabled: isDisabled('is_dependent'),
      onChange: () =>
        setCurrentMember({
          ...currentMember,
          is_dependent: !currentMember.is_dependent,
        }),
      // hasError: layoutNameFieldError,
    },
    {
      type: 'checkbox',
      label: 'Include in My Household',
      name: 'is_active',
      required: false,
      value: currentMember.is_active,
      isVisible: showExcludedMembers,
      disabled: isDisabled('is_active'),
      onChange: () => {
        isActiveOnChange(currentMember);
      },
      // hasError: layoutNameFieldError,
    },
  ];

  const detailInputs = [
    {
      type: 'select',
      label: 'Citizenship',
      name: 'citizenship',
      required: false,
      placeholder: 'Citizenship',
      value: selectedCitizenship,
      options: countryList,
      width: '100%',
      isVisible: true,
      disabled: isDisabled('citizenship'),
      onChange: (e) => setSelectedCitizenship(e),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'citizenship'),
      errorMessage: GenerateError(modalErrors, 'citizenship'),
    },
    {
      type: 'text',
      label: 'Address',
      name: 'address',
      width: '100%',
      required: false,
      placeholder: 'Address',
      value: HtmlToString(currentMember.address),
      isVisible: true,
      disabled: isDisabled('address'),
      onChange: (e) => updateCurrentMember(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'address'),
      errorMessage: GenerateError(modalErrors, 'address'),
    },
    {
      type: 'text',
      name: 'address2',
      width: '100%',
      required: false,
      placeholder: 'Address Additional',
      value: HtmlToString(currentMember.address2),
      isVisible: true,
      disabled: isDisabled('address2'),
      onChange: (e) => updateCurrentMember(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'address2'),
      errorMessage: GenerateError(modalErrors, 'address2'),
    },
    {
      type: 'text',
      label: 'City',
      maxLength: '45',
      name: 'city',
      width: isModal ? '40%' : '100%',
      required: false,
      placeholder: 'City',
      value: HtmlToString(currentMember.city),
      isVisible: true,
      disabled: isDisabled('city'),
      onChange: (e) => updateCurrentMember(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'city'),
      errorMessage: GenerateError(modalErrors, 'city'),
    },
    {
      type: 'select',
      label: 'State',
      name: 'state',
      width: isModal ? '30%' : '58%',
      required: false,
      placeholder: 'State',
      options: GetStates(states),
      value: selectedState,
      isVisible: true,
      disabled: isDisabled('state') || stateDisabled,
      onChange: (e) => setSelectedState(e),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'state'),
      errorMessage: GenerateError(modalErrors, 'state'),
    },
    {
      type: 'text',
      label: 'Zip Code',
      name: 'zip',
      width: isModal ? '25%' : '38%',
      required: false,
      placeholder: 'Zip Code',
      value: currentMember.zip,
      isVisible: true,
      disabled: isDisabled('zip'),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      onChange: (e) => updateCurrentMember(e),
      hasError: CheckHasError(modalErrors, 'zip'),
      errorMessage: GenerateError(modalErrors, 'zip'),
    },
    {
      type: 'select',
      label: 'Country',
      name: 'country',
      required: false,
      placeholder: 'Country',
      value: selectedCountry,
      options: countryList,
      width: '100%',
      isVisible: true,
      disabled: isDisabled('country'),
      onChange: (e) => setSelectedCountry(e),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'country'),
      errorMessage: GenerateError(modalErrors, 'country'),
    },
    {
      type: 'text',
      label: 'Phone',
      maxLength: '45',
      name: 'phone_number',
      width: isModal ? '48%' : '100%',
      required: false,
      placeholder: 'Phone',
      value: currentMember.phone_number,
      isVisible: true,
      disabled: isDisabled('phone'),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      onChange: (e) => updateCurrentMember(e),
      hasError: CheckHasError(modalErrors, 'phone'),
      errorMessage: GenerateError(modalErrors, 'phone'),
    },
    {
      type: 'text',
      label: 'Email',
      maxLength: '45',
      name: 'email',
      width: isModal ? '48%' : '100%',
      required: false,
      placeholder: 'Email',
      value: currentMember.email,
      isVisible: true,
      disabled: isDisabled('email'),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      onChange: (e) => updateCurrentMember(e),
      hasError: CheckHasError(modalErrors, 'email'),
      errorMessage: GenerateError(modalErrors, 'email'),
    },
  ];

  const notesInput = {
    type: 'textarea',
    label: 'Notes',
    name: 'notes',
    showClear: true,
    id: 'member',
    value: currentMember.notes,
    onChange: (e) => updateCurrentMember(e, true, 'notes'),
  };

  const unlinkMember = () => {
    let isPrimary = false;
    if (selectedRole && selectedRole?.value) {
      if (selectedRole.value === 'primary') {
        isPrimary = true;
      }
    }
    setIsUnlinkingMember(true);
    dispatch(
      unlinkSalesforceMember(householdId, currentMember.id, false, isPrimary)
    );
  };

  const copyFromPrimary = () => {
    if (members && members.length !== 0) {
      const matching = members.find((member) => {
        return member.category === 'primary';
      });
      //Need to show loading
      if (matching && !copyingPrimary) {
        setCopyingPrimary(true);
        dispatch(getPrimaryMember(householdId, matching.id));
      }
    }
  };

  UseSyncIntegrationContent(
    activeTab,
    setShowSyncButton,
    householdIntegrationsInfo,
    setLastHouseholdSync
  );

  useEffect(() => {
    if (justSynced) {
      setIsSyncing(false);
    }
  }, [justSynced]);

  const syncInstrument = () => {
    setIsSyncing(true);
    dispatch(
      syncHouseholdModal(integrationContent.sync_url, currentHousehold.id)
    );
  };

  const generateWarningContent = () => {
    return {
      heading: 'Exclude from Household?',
      text: `Excluding a member from the household will remove them from all financials, entities, target-maps, reports, etc. As well as delete ones for which they are the sole owner. <br/><br/><strong>This cannot be undone</strong>.`,
      buttons: [
        {
          text: 'No',
          function: () => setShowRemoveWarning(false),
          theme: ButtonThemes.cancel,
        },
        {
          text: 'Yes',
          theme: ButtonThemes.primary,
          function: () => {
            setShowRemoveWarning(false);
            setCurrentMember({
              ...currentMember,
              is_active: !currentMember.is_active,
            });
          },
        },
      ],
    };
  };

  return (
    <RelationshipDisplayContainer
      isModal={isModal}
      name={memberDisplay}
      member={member}
      viewOnly={viewOnly}
      isLoading={!loadedMember}
      invisible={invisible}
      hide={hide}
      isNew={isNew}
      modalTabOptions={viewOnly ? clientModalTabs : modalTabOptions}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      household={currentHousehold}
      itemDetails={itemDetails}
      essentialInputs={essentialInputs}
      detailInputs={detailInputs}
      notesInput={notesInput}
      onEnter={saveOnEnter}
      preferences={preferences}
      categories={memberCategories}
      removeContent={{
        showing: showRemoveWarning,
        hide: () => setShowRemoveWarning(!showRemoveWarning),
        content: generateWarningContent(),
      }}
      copyPrimary={{
        showing: isNew || (member?.category !== 'primary' && !viewOnly),
        onClick: copyFromPrimary,
        copying: copyingPrimary,
      }}
      buttonContent={{
        deleteFunction: openDeleteMember,
        saveFunction: saveMember,
        showLoading: buttonLoading,
        syncFunction: syncInstrument,
        showSync: showSyncButton,
        isSyncing: isSyncing,
      }}
      modalError={modalError}
      integrationContent={{
        content: integrationContent,
        loading: loadingIntegrationContent,
        integration: selectedIntegration,
        member: currentMember,
        error: integrationError,
        lastHouseholdSync: lastHouseholdSync,
        role: selectedRole,
        onClick: unlinkMember,
        onClickLoading: isUnlinkingMember,
        type: 'Member',
      }}
      allMemberCategories={allMemberCategories}
    />
  );
};

MemberContainer.propTypes = {
  isModal: PropTypes.bool,
  viewOnly: PropTypes.bool,
  hide: PropTypes.func,
  isNew: PropTypes.bool,
  isPersonal: PropTypes.bool,
  householdId: PropTypes.string,
  defaultTab: PropTypes.string,
  invisible: PropTypes.bool,
  isConsumer: PropTypes.bool,
  itemDetails: PropTypes.object,
};

export default MemberContainer;
