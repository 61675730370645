import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Tooltip } from 'react-tippy';
import {
  // ModalButtons, Error,
  LoadingAnimation,
} from '..';
import { LegalContainer } from '../../containers';
import { chevronDownDark, greyCirclePlus } from '../../assets';
import {
  ExpandableItem,
  ExpandedDetails,
  ExpandIcon,
  ModalSidebarContainer,
  // ToolbarContainer,
  // SidebarMessageContainer,
  ExpandHeadingContainer,
  LoadingContainer,
} from '../../styles/library/sidebarStyles';
import { FlexCenterStart } from '../../styles/library/layoutStyles';
// import { ErrorThemes } from '../../styles/themes';
import {
  ConvertObjectToArray,
  GenerateAvatarMemberIconOld,
  GenerateProposalIcon,
  SendTrackingEvent,
} from '../../utils';
import { assetMapColors, colors, fonts } from '../../styles/variables';
import {
  clearLegal,
  getLegal,
  readProposalLegal,
  updateHouseholdStoreValue,
} from '../../store/actions';

const LegalsListSidebar = ({
  householdId,
  currentHousehold,
  currency,
  showProposalMode,
  currentProposal,
  invocationId,
}) => {
  const dispatch = useDispatch();
  const { membersLegals, createdLegal, legalCategories } = useSelector(
    (state) => ({
      membersLegals: state.households.membersLegals,
      createdLegal: state.households.createdLegal,
      legalCategories: state.configs.legalCategories,
    })
  );
  const [isLoading, setIsLoading] = useState(true);
  const [legalsList, setLegalsList] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [legalTypes, setLegalTypes] = useState([]);

  // setLegalsList([
  //   ...membersLegals.map((legal) => {
  //     const matchingExisting = legalsList.find((l) => l.id === legal.id);
  //     if (matchingExisting) {
  //       legal.isExpanded = matchingExisting.isExpanded;
  //     }
  //     return legal;
  //   }),
  // ]);

  useEffect(() => {
    if (legalCategories) {
      const optionsArray = ConvertObjectToArray(legalCategories);
      const sortedOptions = optionsArray.sort(
        (a, b) => a.priority - b.priority
      );
      const transformedCategories = sortedOptions.map((option, index) => {
        option.value = option.key;
        option.label = option.name;
        option.index = index;
        return option;
      });
      setLegalTypes(transformedCategories);
    }
  }, [legalCategories]);

  useEffect(() => {
    if (membersLegals) {
      setLegalsList([...membersLegals]);
      setTimeout(() => {
        setIsLoading(false);
      }, 400);
    }
  }, [membersLegals]);

  useEffect(() => {
    if (createdLegal) {
      closeAllTypes();
      dispatch(updateHouseholdStoreValue('createdLegal', false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdLegal]);

  useEffect(() => {
    if (legalsList && legalTypes?.length) {
      const hasExpandedLegal = legalsList.some((legal) => legal.isExpanded);
      const hasExpandedType = legalTypes.some((type) => type.isExpanded);
      setIsExpanded(hasExpandedLegal || hasExpandedType);
    }
  }, [legalsList, legalTypes]);

  const closeAllLegals = () => {
    dispatch(clearLegal());
    setLegalsList(
      legalsList.map((option) => {
        const copied = { ...option };
        copied.isExpanded = false;
        return copied;
      })
    );
  };

  const closeAllTypes = () => {
    if (legalTypes?.length !== 0) {
      setLegalTypes(
        legalTypes.map((option) => {
          const copied = { ...option };
          copied.isExpanded = false;
          return copied;
        })
      );
    }
  };

  const onExpandOption = (legal) => {
    if (!legal.isExpanded) {
      closeAllTypes();
      dispatch(clearLegal());
      if (showProposalMode && legal?.is_proposal) {
        dispatch(
          readProposalLegal(
            currentHousehold?.uuid,
            currentProposal?.id,
            legal?.proposal_action_id
          )
        );
      } else {
        dispatch(getLegal(householdId, legal.id));
      }
    }
    setLegalsList(
      legalsList.map((option) => {
        const copied = { ...option };
        if (option.id === legal.id) {
          copied.isExpanded = !copied.isExpanded;
          if (!option.isExpanded) {
            SendTrackingEvent(
              invocationId,
              'click',
              'legal_sidebar_expand',
              'sidebar_amr',
              {
                legal: legal.id,
              }
            );
          }
        } else {
          copied.isExpanded = false;
        }
        return copied;
      })
    );
  };

  const onExpandCreateType = (type) => {
    console.log('inside onExpandCreateType');
    const isClosing = type.isExpanded;
    if (!isClosing) {
      closeAllLegals();
    }
    setLegalTypes(
      legalTypes.map((option) => {
        const copied = { ...option };
        if (option.value === type.value) {
          copied.isExpanded = !copied.isExpanded;
          if (!option.isExpanded) {
            SendTrackingEvent(
              invocationId,
              'click',
              'legal_sidebar_create_expand',
              'sidebar_amr',
              {
                legal_type: type.value,
              }
            );
          }
        } else {
          copied.isExpanded = false;
        }
        return copied;
      })
    );
  };

  const generateBottomPadding = () => {
    // const hasError = error && error !== '';
    const hasError = false;
    let height = 0;
    const errorHeight = 38;
    if (isExpanded) {
      height = 62;
    }
    if (hasError) {
      height += errorHeight;
    }
    return `${height}px`;
  };

  return (
    <ModalSidebarContainer $paddingBottom={generateBottomPadding()}>
      {isLoading ? (
        <LoadingContainer>
          <LoadingAnimation />
        </LoadingContainer>
      ) : (
        <>
          {legalsList.map((legal) => {
            return (
              <ExpandableItem key={legal.id}>
                <ExpandHeadingContainer
                  onClick={() => onExpandOption(legal)}
                  $isExpanded={legal.isExpanded}
                  $isSticky={legal.isExpanded}
                  $padding={'13px 18px'}
                  $isProposal={legal?.is_proposal}
                  $background={legal?.is_proposal ? assetMapColors.draft : null}
                  $borderColor={legal?.is_proposal ? `${colors.pink}30` : null}
                  style={
                    legal?.proposal_action === 'delete'
                      ? {
                          backgroundImage: `url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23E24585' fill-opacity='0.15' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E")`,
                        }
                      : null
                  }
                >
                  <LegalRow>
                    <LegalLeft>
                      <LegalNameContainer>
                        <LegalName>
                          {legal.name}{' '}
                          {legal.year && (
                            <LegalYear>{`(${legal.year})`}</LegalYear>
                          )}
                        </LegalName>
                        {legal?.is_proposal &&
                          GenerateProposalIcon(legal, null, {
                            position: 'relative',
                            top: 'auto',
                            right: 'auto',
                            display: 'inline-block',
                            width: '18px',
                            margin: legal.year ? '0' : '0 0 0 5px',
                          })}
                      </LegalNameContainer>
                    </LegalLeft>
                    <LegalRight>
                      {legal?.members.length &&
                        legal?.members?.map((member, index) => {
                          return (
                            member?.name && (
                              <Tooltip
                                title={member?.name}
                                position="top"
                                trigger="mouseenter click"
                                delay={0}
                                arrow={true}
                                arrowSize={'small'}
                                animation={'fade'}
                                animateFill={false}
                                transitionFlip={false}
                                size={'small'}
                                style={{ display: 'flex' }}
                                key={index}
                              >
                                <LegalMember
                                  title={member?.name}
                                  key={index}
                                  src={GenerateAvatarMemberIconOld(member)}
                                  alt="user"
                                />
                              </Tooltip>
                            )
                          );
                        })}
                    </LegalRight>
                  </LegalRow>
                  <ExpandIcon $isExpanded={legal.isExpanded}>
                    <img src={chevronDownDark} alt="down" />
                  </ExpandIcon>
                </ExpandHeadingContainer>
                {legal.isExpanded && (
                  <ExpandedDetails $padding="0">
                    <LegalContainer
                      key={legal?.id}
                      isModal={false}
                      isNew={false}
                      householdId={householdId}
                      currency={currency}
                      isList={false}
                      proposalId={currentProposal?.id}
                      showProposalMode={showProposalMode}
                    />
                  </ExpandedDetails>
                )}
              </ExpandableItem>
            );
          })}
          {legalTypes?.length !== 0 &&
            legalTypes.map((type) => {
              return (
                <ExpandableItem key={type.value}>
                  <ExpandHeadingContainer
                    onClick={() => onExpandCreateType(type)}
                    $isExpanded={type.isExpanded}
                    $isSticky={type.isExpanded}
                    $padding={'10px 18px'}
                  >
                    <TypeName $isExpanded={type.isExpanded}>
                      {type.label}
                    </TypeName>
                    <ExpandIcon $isExpanded={type.isExpanded}>
                      <img
                        src={greyCirclePlus}
                        alt="add"
                        style={{ width: '18px', height: '18px' }}
                      />
                    </ExpandIcon>
                  </ExpandHeadingContainer>
                  {type.isExpanded && (
                    <ExpandedDetails $padding="0">
                      <LegalContainer
                        key={0}
                        isModal={false}
                        isNew={true}
                        householdId={householdId}
                        currency={currency}
                        isList={false}
                        selectedType={type}
                        proposalId={currentProposal?.id}
                        showProposalMode={showProposalMode}
                      />
                    </ExpandedDetails>
                  )}
                </ExpandableItem>
              );
            })}
        </>
      )}
    </ModalSidebarContainer>
  );
};

const LegalRow = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
`;

const LegalLeft = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  margin: 0 10px 0 0;
`;

const LegalRight = styled.div`
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  ${FlexCenterStart};
  flex-wrap: wrap;
  margin: 2px 0 0 -1.5px;
  ${'' /* max-width: 105px; */}
`;

// const LegalTop = styled.div`
//   ${FlexCenterStart};
//   flex: 1 1 auto;
// `;

const LegalNameContainer = styled.div`
  ${FlexCenterStart};
  flex-wrap: wrap;
`;

const LegalName = styled.p`
  font-size: 13px;
  font-weight: ${fonts.semiBold};
`;

const TypeName = styled.p`
  font-size: 13px;
  flex: 1 1 auto;
  font-weight: ${(props) =>
    props.$isExpanded ? fonts.semiBold : fonts.regular};
  color: ${(props) => (props.$isExpanded ? colors.darkGrey : colors.paleGrey)};
`;

const LegalYear = styled.span`
  color: ${colors.paleGrey};
  font-weight: ${fonts.regular};
  font-size: 11px;
  margin-right: 5px;
`;

// const LegalBottom = styled.div`
//   margin: 5px 0 0 -1.5px;
//   ${FlexCenterStart};
//   flex-wrap: wrap;
// `;

const LegalMember = styled.img`
  max-width: 12px;
  width: 100%;
  max-height: 12px;
  height: 100%;
  margin: 1.5px 2px;
`;

export default LegalsListSidebar;
