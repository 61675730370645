import styled from 'styled-components';
import {
  colors,
  inputColors,
  messageColors,
  fonts,
  maxDevice,
} from '../variables';

export const InputLabel = styled.label`
  text-align: left;
  margin: ${(props) => (props.margin ? props.margin : '0 0 3px 3px')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '11px')};
  font-weight: ${fonts.semiBold};
  color: ${inputColors.label};
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
`;

export const TextInputContainer = styled.div`
  @media ${maxDevice.mobileL} {
    width: ${(props) => (props.mobileWidth ? props.mobileWidth : 'auto')};
    margin: ${(props) =>
      props.mobileMargin
        ? props.mobileMargin
        : props.margin
          ? props.margin
          : '0 0 10px 0'};
  }
  display: flex;
  flex-direction: column;
  margin: ${(props) => (props.margin ? props.margin : '0 0 10px 0')};
  width: ${(props) => (props.width ? props.width : 'auto')};
  input {
    flex: 1 1 auto;
    padding: ${(props) => (props.padding ? props.padding : '9.1px 12px')};
    font-size: 13px;
    width: ${(props) => (props.autoWidth ? 'auto' : '100%')};
  }
  /* img {
    right: 8px;
    top: 50%;
    width: 17px;
    height: 17px;
    transform: translateY(-50%);
    &:hover {
      transform: translateY(-50%) scale(1.01);
    }
  } */
`;

export const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
  .react-datepicker-popper {
    z-index: 2 !important;
  }
`;

export const TextInputRow = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  .number-formatter {
    background: white;
    border: 1px solid ${inputColors.border};
    color: ${colors.darkGrey};
    /* padding: 9.5px 15px; */
    border-radius: 6px;
    flex: 1 1 auto;
    &::placeholder {
      color: ${colors.paleGrey};
      opacity: 1;
    }
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  width: ${(props) => (props.width ? props.width : 'auto')};
  margin: ${(props) => (props.margin ? props.margin : '0 0 10px -3px')};
  label {
    font-size: 12px;
  }
`;

export const SelectContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  text-align: left;
  flex-direction: column;
  margin: ${(props) => (props.margin ? props.margin : null)};
  width: ${(props) => (props.width ? props.width : 'auto')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : null)};
  ${InputLabel} {
    margin: 0 0 5px 0;
  }
  div {
    div {
      div {
        font-size: 13px;
      }
    }
  }
`;

export const EditorContainer = styled.div`
  color: ${colors.darkGrey};
  background: ${colors.white};
  resize: none;
  font-size: 13px;
  border-radius: 6px;
  flex: 1 1 auto;
  width: 100%;
  .quill {
    flex: 1 1 auto;
    .ql-toolbar.ql-snow {
      border-radius: 6px 6px 0 0;
      border: ${(props) =>
        props.error ? `1px solid ${messageColors.error}` : null};
    }
    .ql-container.ql-snow {
      border-radius: 0 0 6px 6px;
      border: ${(props) =>
        props.error ? `1px solid ${messageColors.error}` : null};
    }
  }
  .ql-editor {
    height: 100%;
    min-height: ${(props) => (props.height ? props.height : '203.5px')};
    max-height: ${(props) => (props.height ? props.height : '203.5px')};
    p {
      margin-bottom: 10px;
    }
    a {
      color: ${colors.lightBlue};
    }
  }
  .ql-bubble {
    z-index: 10;
    border: 1px solid ${inputColors.border};
  }
  .ql-bubble .ql-tooltip-editor input[type='text'] {
    left: 0;
  }
`;

export const TooltipIcon = styled.img`
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-bottom: -1px;
  opacity: 0.75;
`;

export const TooltipIconError = styled(TooltipIcon)`
  opacity: 1;
`;

export const ClearButton = styled.button`
  &:hover {
    svg {
      path {
        fill: ${messageColors.error};
      }
    }
  }
`;

export const UseDefaultTextButton = styled.button`
  border: 1px solid ${colors.lightGrey} !important;
  width: 100% !important;
  color: ${colors.darkGrey} !important;
  font-size: 11px !important;
  background: ${colors.lighterGrey} !important;
  border-radius: 3px !important;
  &:hover {
    background: ${colors.lightGrey} !important;
    border: 1px solid ${colors.paleGrey} !important;
  }
`;
