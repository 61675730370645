import {
  apiAction,
  getHouseholdInfo,
  getConsumerHouseholdInfo,
  getProposalHouseholdInfo,
} from '.';

export const InstrumentTypes = {
  UPDATE_INSTRUMENT_STORE_VALUE: 'UPDATE_INSTRUMENT_STORE_VALUE',
  CLOSE_ALL_INSTRUMENT_MODALS: 'CLOSE_ALL_INSTRUMENT_MODALS',
  GET_INSTRUMENT_INFO: 'GET_INSTRUMENT_INFO',
  CLEAR_INSTRUMENT_INFO: 'CLEAR_INSTRUMENT_INFO',
  GET_PROPOSAL_INSTRUMENT_INFO: 'GET_PROPOSAL_INSTRUMENT_INFO',
  GET_INSTRUMENT_INTEGRATION_INFO: 'GET_INSTRUMENT_INTEGRATION_INFO',
  CREATE_INSTRUMENT: 'CREATE_INSTRUMENT',
  CREATE_PROPOSAL_INSTRUMENT: 'CREATE_PROPOSAL_INSTRUMENT',
  UPDATE_INSTRUMENT_INFO: 'UPDATE_INSTRUMENT_INFO',
  UPDATE_PROPOSAL_INSTRUMENT_INFO: 'UPDATE_PROPOSAL_INSTRUMENT_INFO',
  CLONE_INSTRUMENT: 'CLONE_INSTRUMENT',
  DELETE_INSTRUMENT: 'DELETE_INSTRUMENT',
  DELETE_PROPOSAL_INSTRUMENT: 'DELETE_PROPOSAL_INSTRUMENT',
  TAKE_PROPOSAL_ACTION: 'TAKE_PROPOSAL_ACTION',
  TAKE_BULK_INSTRUMENT_ACTION: 'TAKE_BULK_INSTRUMENT_ACTION',
  LOAD_INSTRUMENT_FOLDER: 'LOAD_INSTRUMENT_FOLDER',
  LINK_INSTRUMENT_FOLDER: 'LINK_INSTRUMENT_FOLDER',
  UNLINK_INSTRUMENT_FOLDER: 'UNLINK_INSTRUMENT_FOLDER',
  TOGGLE_SHOW_CASH_FLOW_MODAL: 'TOGGLE_SHOW_CASH_FLOW_MODAL',
  TOGGLE_SHOW_ASSET_MODAL: 'TOGGLE_SHOW_ASSET_MODAL',
  TOGGLE_SHOW_LIABILITY_MODAL: 'TOGGLE_SHOW_LIABILITY_MODAL',
  TOGGLE_SHOW_INSURANCE_MODAL: 'TOGGLE_SHOW_INSURANCE_MODAL',
  TOGGLE_SHOW_LEGAL_MODAL: 'TOGGLE_SHOW_LEGAL_MODAL',
};

/*
  GENERIC FUNCTION USED TO CHANGE OR MODIFY SINGLE VALUE IN INSTRUMENT STORE
  - fieldName <string>: field name in redux store to modify
  - value <any>: new value desired for field
*/
export const updateInstrumentStoreValue = (fieldName, value) => ({
  type: InstrumentTypes.UPDATE_INSTRUMENT_STORE_VALUE,
  payload: { fieldName, value },
});

/*
 ACTION TO CLOSE ALL INSTRUMENT MODALS IN APP
  - keepOpen <string>: not used but for ability to keep passed modal open
*/
export const closeAllInstrumentModals = (keepOpen) => ({
  type: InstrumentTypes.CLOSE_ALL_INSTRUMENT_MODALS,
  payload: keepOpen,
});

//ACTION TO SET INSTRUMENT INFO IN STORE <object>
export const setInstrumentInfo = (instrument) => ({
  type: InstrumentTypes.GET_INSTRUMENT_INFO,
  payload: instrument,
});

/*
  API CALL TO GET INSTRUMENT INFO
  - householdId <string>: id of household for instrument
  - instrumentId <string>: id of instrument to be fetched
  - isConsumer <bool>: whether user is consumer, uses different endpoint
*/
export const getInstrumentInfo = (
  householdId,
  instrumentId,
  isConsumer = false
) => {
  return apiAction({
    path: `/household/${householdId}/instrument/${instrumentId}`,
    isConsumer: isConsumer,
    method: 'GET',
    onSuccess: setInstrumentInfo,
    label: InstrumentTypes.GET_INSTRUMENT_INFO,
  });
};

//ACTION TO CLEAR CURRENT INSTRUMENT FROM STORE
export const clearInstrumentInfo = () => ({
  type: InstrumentTypes.CLEAR_INSTRUMENT_INFO,
});

//ACTION TO SET PROPOSAL INSTRUMENT INFO IN STORE <object>
export const setProposalInstrumentInfo = (instrument) => ({
  type: InstrumentTypes.GET_PROPOSAL_INSTRUMENT_INFO,
  payload: instrument,
});

/*
  API CALL TO GET PROPOSAL INSTRUMENT INFO
  - householdId <string>: uuid of household for instrument
  - proposalId <string>: uuid of proposal for instrument
  - proposalActionId <string>: uuid of proposal action be fetched
*/
export const getProposalInstrumentInfo = (
  householdId,
  proposalId,
  proposalActionId
) => {
  return apiAction({
    path: `/household/${householdId}/proposal/${proposalId}/action/${proposalActionId}`,
    method: 'GET',
    onSuccess: setProposalInstrumentInfo,
    label: InstrumentTypes.GET_PROPOSAL_INSTRUMENT_INFO,
  });
};

//ACTION TO SET INSTRUMENT INTEGRATION INFO IN STORE <object>
export const setInstrumentIntegrationInfo = (integrationInfo) => ({
  type: InstrumentTypes.GET_INSTRUMENT_INTEGRATION_INFO,
  payload: integrationInfo,
});

/*
  API CALL TO UPDATE INSTRUMENT
  - householdId <string>: id of household for instrument
  - instrumentId <string>: id of instrument to be fetched
  - integration <string>: id value of integration to be fetched
*/
export const getInstrumentIntegrationInfo = (
  householdId,
  instrumentId,
  integration
) => {
  return apiAction({
    path: `/household/${householdId}/instrument/${instrumentId}/integration/${integration}`,
    method: 'GET',
    onSuccess: setInstrumentIntegrationInfo,
    label: InstrumentTypes.GET_INSTRUMENT_INTEGRATION_INFO,
  });
};

/*
  API CALL TO CREATE INSTRUMENT
  - householdId <string>: id of household for instrument
  - instrument <object>: data needed to create instrument
  - type <string>: instrument type (cash flow, asset, liability, insurance)
  - isConsumer <bool>: whether user is consumer, uses different endpoint
  - isSidebar <bool>: whether instrument was created from sidebar or modal
*/
export const createInstrument = (
  householdId,
  instrument,
  type,
  isConsumer = false,
  isSidebar = false
) => {
  return apiAction({
    path: `/household/${householdId}/instrument`,
    isConsumer: isConsumer,
    method: 'POST',
    data: instrument,
    passId: householdId,
    passSubId: isSidebar,
    successMessage: `Created ${type}`,
    onSuccess: isConsumer ? getConsumerHouseholdInfo : getHouseholdInfo,
    label: InstrumentTypes.CREATE_INSTRUMENT,
  });
};

/*
  API CALL TO CREATE PROPOSAL INSTRUMENT
  - householdId <string>: uuid of household for instrument
  - proposalId <string>: uuid of proposal
  - type <string>: instrument type (cash flow, asset, liability, insurance)
*/
export const createProposalInstrument = (
  householdId,
  proposalId,
  instrument,
  objectType = 'instrument',
  type = 'Financial'
) => {
  return apiAction({
    path: `/household/${householdId}/proposal/${proposalId}/action`,
    method: 'POST',
    data: {
      action: 'add',
      object_type: objectType,
      object_id: null,
      diff_data: instrument,
    },
    passId: householdId,
    passSubId: proposalId,
    successMessage: `Created ${type}`,
    onSuccess: getProposalHouseholdInfo,
    label: InstrumentTypes.CREATE_PROPOSAL_INSTRUMENT,
  });
};

/*
  API CALL TO UPDATE INSTRUMENT
  - householdId <string>: id of household for instrument
  - instrumentId <string>: id of instrument to be updated
  - instrument <object>: data needed to update instrument
  - type <string>: instrument type (cash flow, asset, liability, insurance)
  - isConsumer <bool>: whether user is consumer, uses different endpoint
  - isSidebar <bool>: whether update comes from sidebar
*/
export const updateInstrument = (
  householdId,
  instrumentId,
  instrument,
  type,
  isConsumer = false,
  isSidebar = false
) => {
  return apiAction({
    path: `/household/${householdId}/instrument/${instrumentId}`,
    isConsumer: isConsumer,
    method: 'PUT',
    data: instrument,
    passId: householdId,
    passSubId: isSidebar,
    successMessage: `${type} Updated`,
    onSuccess: isConsumer ? getConsumerHouseholdInfo : getHouseholdInfo,
    label: InstrumentTypes.UPDATE_INSTRUMENT_INFO,
  });
};

/*
  API CALL TO UPDATE PROPOSAL INSTRUMENT
  - householdId <string>: uuid of household for instrument
  - proposalId <string>: uuid of proposal
  - instrument <object>: data needed to update instrument
  - instrumentId <string>: uuid of instrument proposal is for (only for modifying existing instrument)
  - type <string>: instrument type (cash flow, asset, liability, insurance)
  - proposalActionId <string>: uuid of proposal action (only for modifying an existing proposal action)
*/
export const updateProposalInstrument = (
  householdId,
  proposalId,
  instrument,
  instrumentId,
  object_type = 'instrument',
  type = 'Financial',
  proposalActionId = false
) => {
  const idValue = proposalActionId
    ? { proposal_action_id: proposalActionId }
    : { object_id: instrumentId };
  return apiAction({
    path: `/household/${householdId}/proposal/${proposalId}/action`,
    method: 'POST',
    data: {
      action: 'modify',
      object_type: object_type,
      diff_data: instrument,
      ...idValue,
    },
    passId: householdId,
    passSubId: proposalId,
    successMessage: `${type} Updated`,
    onSuccess: getProposalHouseholdInfo,
    label: InstrumentTypes.UPDATE_PROPOSAL_INSTRUMENT_INFO,
  });
};

//ACTION TO SET CLONED INSTRUMENT INFO IN STORE <object>
export const setClonedInstrument = (instrument, isSidebar) => ({
  type: InstrumentTypes.CLONE_INSTRUMENT,
  payload: { instrument, isSidebar },
});

/*
  API CALL TO CLONE INSTRUMENT
  - householdId <string>: id of household for instrument
  - instrumentId <string>: id of instrument to be cloned
  - type <string>: instrument type (cash flow, asset, liability, insurance)
  - isSidebar <bool>: if clone was done in sidebar
*/
export const cloneInstrument = (
  householdId,
  instrumentId,
  type,
  isSidebar = false
) => {
  return apiAction({
    path: `/household/${householdId}/instrument/${instrumentId}/clone`,
    method: 'POST',
    passValue: isSidebar,
    successMessage: `${type} Cloned`,
    onSuccess: setClonedInstrument,
    label: InstrumentTypes.CLONE_INSTRUMENT,
  });
};

/*
  API CALL TO DELETE INSTRUMENT
  - householdId <string>: id of household for instrument
  - instrumentId <string>: id of instrument to be deleted
  - type <string>: instrument type (cash flow, asset, liability, insurance)
  - isConsumer <bool>: whether user is consumer, uses different endpoint
  - isSidebar <bool>: if delete is from sidebar
*/
export const deleteInstrument = (
  householdId,
  instrumentId,
  type = 'Financial',
  isConsumer = false,
  isSidebar = false
) => {
  return apiAction({
    path: `/household/${householdId}/instrument/${instrumentId}`,
    isConsumer: isConsumer,
    method: 'DELETE',
    successMessage: `${type} Deleted`,
    passId: householdId,
    passSubId: instrumentId,
    passThirdParam: isSidebar,
    onSuccess: isConsumer ? getConsumerHouseholdInfo : getHouseholdInfo,
    label: InstrumentTypes.DELETE_INSTRUMENT,
  });
};

/*
  API CALL TO DELETE INSTRUMENT IN PROPOSAL MODE
  - householdId <string>: uuid of household for instrument
  - proposalId <string>: uuid of proposal
  - instrumentId <string>: uuid of instrument to be deleted
  - type <string>: instrument type (cash flow, asset, liability, insurance)
*/
export const deleteProposalInstrument = (
  householdId,
  proposalId,
  instrumentId,
  type = 'Financial'
) => {
  return apiAction({
    path: `/household/${householdId}/proposal/${proposalId}/action`,
    method: 'POST',
    data: {
      action: 'delete',
      object_type: 'instrument',
      object_id: instrumentId,
    },
    successMessage: `${type} Deleted`,
    passId: householdId,
    passSubId: proposalId,
    onSuccess: getProposalHouseholdInfo,
    label: InstrumentTypes.DELETE_PROPOSAL_INSTRUMENT,
  });
};

/*
  API CALL TO DELETE PROPOSAL ACTION IN PROPOSAL MODE
  - householdId <string>: uuid of household for instrument
  - proposalId <string>: uuid of proposal
  - proposalActionId <string>: uuid of proposalAction to be deleted
  - type <string>: instrument type (cash flow, asset, liability, insurance)
*/
export const deleteProposalAction = (
  householdId,
  proposalId,
  proposalActionId,
  type = 'Financial'
) => {
  return apiAction({
    path: `/household/${householdId}/proposal/${proposalId}/action/${proposalActionId}`,
    method: 'DELETE',
    successMessage: `${type} Deleted`,
    passId: householdId,
    passSubId: proposalId,
    onSuccess: getProposalHouseholdInfo,
    label: InstrumentTypes.DELETE_PROPOSAL_INSTRUMENT,
  });
};

/*
  API CALL TO ACCEPT/REJECT PROPOSAL ACTION
  - householdId <string>: uuid of household for instrument
  - proposalId <string>: uuid of proposal
  - proposalActionId <string>: uuid of proposal action to accept/reject
  - isAccept <boolean>: whether request is for accepting proposal
*/
export const takeProposalAction = (
  householdId,
  proposalId,
  proposalActionId,
  isAccept
) => {
  return apiAction({
    path: `/proposal/action/${proposalActionId}/accept_reject`,
    method: 'PUT',
    data: { accept: isAccept },
    successMessage: isAccept ? 'Draft Accepted' : 'Draft Rejected',
    passId: householdId,
    passSubId: proposalId,
    onSuccess: getProposalHouseholdInfo,
    showStart: true,
    label: InstrumentTypes.TAKE_PROPOSAL_ACTION,
  });
};

/*
  API CALL TO TAKE A BULK ACTION ON INSTRUMENTS (DELETE/CLONE/HIDE/UNHIDE)
  - householdId <string>: id of household for instrument
  - instrumentIdList <array>: list of instrument ids to be deleted
  - action <string>: What type of action is being done (delete/hide/show/clone)
*/
export const takeBulkInstrumentAction = (
  householdId,
  instrumentIdList,
  action = 'hide'
) => {
  const isPlural = instrumentIdList?.length > 1;
  let financialsText = isPlural ? 'Financials' : 'Financial';
  let displayMessage = '';
  switch (action) {
    case 'delete':
      displayMessage = `${financialsText} Deleted`;
      break;
    case 'clone':
      displayMessage = `${financialsText} Cloned`;
      break;
    case 'hide':
      displayMessage = `${financialsText} Hidden`;
      break;
    case 'show':
      displayMessage = `${financialsText} Unhidden`;
      break;
    default:
      displayMessage = 'Unknown Action';
      break;
  }
  return apiAction({
    path: `/household/${householdId}/instrument/bulk/${action}`,
    method: 'POST',
    data: instrumentIdList,
    successMessage: displayMessage,
    passId: householdId,
    onSuccess: getHouseholdInfo,
    label: InstrumentTypes.TAKE_BULK_INSTRUMENT_ACTION,
  });
};

//ACTION TO SET INSTRUMENT FILE STORAGE FOLDER IN STORE <object>
export const setInstrumentFolder = (folder) => ({
  type: InstrumentTypes.LOAD_INSTRUMENT_FOLDER,
  payload: folder,
});

/*
  API CALL TO GET EXISTING FILE STORAGE FOLDER FOR INSTRUMENT
  - householdId <string>: id of household for instrument
  - instrumentId <string>: id of instrument to be fetched
  - integration <string>: id value of integration to be fetched
  - folderId <string>: id of existing folder desired
*/
export const getInstrumentStorageFolder = (
  householdId,
  instrumentId,
  integration,
  folderId
) => {
  return apiAction({
    path: `/household/${householdId}/instrument/${instrumentId}/integration/${integration}/load_folder${
      folderId ? `?folder=${folderId}` : ''
    }`,
    method: 'GET',
    onSuccess: setInstrumentFolder,
    label: InstrumentTypes.LOAD_INSTRUMENT_FOLDER,
  });
};

/*
  API CALL TO LINK INSTRUMENT TO FILE STORAGE FOLDER
  - household <object>: household object for instrument
  - instrument <object>: instrument object to be linked
  - integration <string>: id value of integration to be linked
  - folderId <string>: id of existing folder to link (if pass 'new' then it will create)
  - type <string>: instrument type (cash flow, asset, liability, insurance)
*/
export const linkInstrumentFolder = (
  household,
  instrument,
  integration,
  folderId,
  type
) => {
  let instrumentId = instrument.uuid ? instrument.uuid : instrument.id;
  return apiAction({
    path: `/household/${household?.uuid}/instrument/${instrumentId}/integration/${integration}/link`,
    method: 'PUT',
    data: folderId === 'new' ? { create: true } : { folder: folderId },
    passId: household?.id,
    passSubId: instrument?.id,
    passThirdParam: integration,
    onSuccess: getInstrumentIntegrationInfo,
    successMessage: `${type} Linked`,
    errorMessage: 'Error Linking',
    label: InstrumentTypes.LINK_INSTRUMENT_FOLDER,
  });
};

/*
  API CALL TO UNLINK INSTRUMENT FROM FILE STORAGE FOLDER
  - household <object>: household object for instrument
  - instrument <object>: instrument object to be unlinked
  - integration <string>: id value of integration to be unlinked
  - type <string>: instrument type (cash flow, asset, liability, insurance)
*/
export const unlinkInstrumentFolder = (
  household,
  instrument,
  integration,
  type
) => {
  let instrumentId = instrument.uuid ? instrument.uuid : instrument.id;
  return apiAction({
    path: `/household/${household?.uuid}/instrument/${instrumentId}/integration/${integration}/unlink`,
    method: 'PUT',
    passId: household?.id,
    passSubId: instrument?.id,
    passThirdParam: integration,
    onSuccess: getInstrumentIntegrationInfo,
    successMessage: `${type} Unlinked`,
    errorMessage: 'Error Unlinking',
    label: InstrumentTypes.UNLINK_INSTRUMENT_FOLDER,
  });
};

/*
  ACTION TO SET TOGGLE DISPLAY OF CASH FLOW MODAL
  - showModal <boolean>: whether to show modal or not
  - newInstrument <boolean>: whether modal or for new or existing cash flow
  - defaultTab <string>: what tab should modal open to, default is essentials
*/
export const toggleShowCashFlowModal = (
  showModal,
  newInstrument = false,
  defaultTab = 'essentials'
) => {
  return {
    type: InstrumentTypes.TOGGLE_SHOW_CASH_FLOW_MODAL,
    payload: {
      showModal: showModal,
      newInstrument: newInstrument,
      defaultTab,
    },
  };
};

/*
  ACTION TO SET TOGGLE DISPLAY OF ASSET MODAL
  - showModal <boolean>: whether to show modal or not
  - newInstrument <boolean>: whether modal or for new or existing asset
  - defaultTab <string>: what tab should modal open to, default is essentials
*/
export const toggleShowAssetModal = (
  showModal,
  newInstrument = false,
  defaultTab = 'essentials'
) => {
  return {
    type: InstrumentTypes.TOGGLE_SHOW_ASSET_MODAL,
    payload: {
      showModal: showModal,
      newInstrument: newInstrument,
      defaultTab,
    },
  };
};

/*
  ACTION TO SET TOGGLE DISPLAY OF LIABILITY MODAL
  - showModal <boolean>: whether to show modal or not
  - newInstrument <boolean>: whether modal or for new or existing liability
  - defaultTab <string>: what tab should modal open to, default is essentials
*/
export const toggleShowLiabilityModal = (
  showModal,
  newInstrument = false,
  defaultTab = 'essentials'
) => {
  return {
    type: InstrumentTypes.TOGGLE_SHOW_LIABILITY_MODAL,
    payload: {
      showModal: showModal,
      newInstrument: newInstrument,
      defaultTab,
    },
  };
};

/*
  ACTION TO SET TOGGLE DISPLAY OF INSURANCE MODAL
  - showModal <boolean>: whether to show modal or not
  - newInstrument <boolean>: whether modal or for new or existing insurance
  - defaultTab <string>: what tab should modal open to, default is essentials
*/
export const toggleShowInsuranceModal = (
  showModal,
  newInstrument = false,
  defaultTab = 'essentials'
) => {
  return {
    type: InstrumentTypes.TOGGLE_SHOW_INSURANCE_MODAL,
    payload: {
      showModal: showModal,
      newInstrument: newInstrument,
      defaultTab,
    },
  };
};
