/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {
  GenerateInput,
  ModalTabs,
  ModalButtons,
  Error,
  IntegrationTab,
  LoadingAnimation,
  DetectModalKeys,
  HouseholdSharingModal,
  SimpleModal,
} from '..';
import { timesWhite } from '../../assets';
import {
  ModalBackground,
  ModalOverlay,
  ModalContainer,
  ModalContentContainer,
  ModalHeadingContainer,
  ModalLoadingOverlayContainer,
  LoadingOverlayContainer,
  LoadingOverlay,
  ModalHeading,
  CloseButton,
  TabContent,
} from '../../styles/library/modalStyles';
import { colors } from '../../styles/variables';
import { RowButton } from '../../styles/library/fontStyles';

const RelationshipModal = ({
  name,
  member,
  isLoading,
  invisible,
  hide,
  isNew,
  viewOnly = false,
  modalTabOptions,
  activeTab,
  setActiveTab,
  household,
  essentialInputs,
  detailInputs,
  notesInput,
  onEnter,
  buttonContent,
  modalError,
  integrationContent,
  removeContent,
  copyPrimary,
}) => {
  const ref = useRef();

  return ReactDOM.createPortal(
    <>
      {removeContent?.showing && (
        <SimpleModal
          hide={removeContent?.hide}
          content={removeContent?.content}
        />
      )}
      <DetectModalKeys onEnter={onEnter} onEsc={hide} />
      <ModalBackground invisible={invisible} />
      <ModalOverlay invisible={invisible}>
        <ModalContainer role="dialog" ref={ref}>
          <ModalHeadingContainer>
            <ModalHeading>
              {viewOnly
                ? `${name} Details`
                : isNew
                  ? `Add ${name}`
                  : `Edit ${name}`}
            </ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hide()}
              alt="close"
              data-image="close"
            />
          </ModalHeadingContainer>
          <ModalLoadingOverlayContainer>
            {isLoading && !isNew && (
              <LoadingOverlayContainer>
                <LoadingOverlay>
                  <LoadingAnimation />
                </LoadingOverlay>
              </LoadingOverlayContainer>
            )}
            {modalTabOptions?.length && (
              <ModalTabs
                tabs={modalTabOptions}
                activeTab={activeTab}
                onClick={setActiveTab}
              />
            )}
            <ModalContentContainer>
              {activeTab === 'essentials' ? (
                <TabContent>
                  {essentialInputs.map((input, index) => {
                    return GenerateInput(input, index, null, viewOnly);
                  })}
                </TabContent>
              ) : activeTab === 'details' ? (
                <>
                  {copyPrimary?.showing && (
                    <RowButton
                      onClick={() => {
                        copyPrimary?.onClick();
                      }}
                    >
                      {copyPrimary?.copying ? (
                        <>
                          <LoadingAnimation
                            dots={true}
                            smaller={true}
                            button={true}
                            color={colors.lightBlue}
                          />
                          Copying Data from Primary
                        </>
                      ) : (
                        'Copy Data from Primary'
                      )}
                    </RowButton>
                  )}
                  <TabContent>
                    {detailInputs.map((input, index) => {
                      return GenerateInput(input, index);
                    })}
                  </TabContent>
                </>
              ) : activeTab === 'notes' ? (
                <TabContent>{GenerateInput(notesInput, 0)}</TabContent>
              ) : activeTab === 'connect' ? (
                <HouseholdSharingModal
                  showModal={false}
                  householdId={household?.id}
                  member={member}
                  hide={hide}
                />
              ) : (
                <IntegrationTab
                  content={integrationContent?.content}
                  loading={integrationContent?.loading}
                  integration={integrationContent?.integration}
                  member={integrationContent?.member}
                  household={household}
                  role={integrationContent?.role}
                  onClick={integrationContent?.onClick}
                  onClickLoading={integrationContent?.onClickLoading}
                  error={integrationContent?.error}
                  type={integrationContent?.type}
                  lastHouseholdSync={integrationContent?.lastHouseholdSync}
                />
              )}
              {!viewOnly && activeTab !== 'connect' && (
                <ModalButtons
                  isNew={isNew}
                  showCopy={false}
                  hide={hide}
                  deleteFunction={buttonContent?.deleteFunction}
                  saveFunction={buttonContent?.saveFunction}
                  showLoading={buttonContent?.showLoading}
                  loadingText={buttonContent?.loadingText}
                  showSync={buttonContent?.showSync}
                  syncFunction={buttonContent?.syncFunction}
                  isSyncing={buttonContent?.isSyncing}
                />
              )}
            </ModalContentContainer>
          </ModalLoadingOverlayContainer>
          {modalError && modalError !== '' && (
            <Error errorMessage={modalError} />
          )}
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

RelationshipModal.propTypes = {
  name: PropTypes.string,
  member: PropTypes.object,
  isLoading: PropTypes.bool,
  viewOnly: PropTypes.bool,
  invisible: PropTypes.bool,
  hide: PropTypes.func,
  isNew: PropTypes.bool,
  modalTabOptions: PropTypes.array,
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
  household: PropTypes.object,
  essentialInputs: PropTypes.array,
  detailInputs: PropTypes.array,
  notesInput: PropTypes.object,
  onEnter: PropTypes.func,
  buttonContent: PropTypes.object,
  modalError: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  integrationContent: PropTypes.object,
  removeContent: PropTypes.object,
  copyPrimary: PropTypes.object,
};

export default RelationshipModal;
