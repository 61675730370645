/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { Tooltip } from 'react-tippy';
import { Button, LoadingAnimation } from '../';
import {
  trashDark,
  pencil,
  checkbox,
  copy,
  square,
  link,
  minus,
  search,
  download,
  share,
  times,
  launchLink,
  checkboxGreen,
  home,
  chevronDown,
  ellipsisH,
  undo,
  keyIcon,
  // plusLogoOutline,
  imagePreview,
  // targetMaps,
  // trafficLight,
} from '../../assets';
import {
  GenerateAge,
  IsNegative,
  FormatCurrency,
  TransformGender,
  GenerateEntityLabel,
  GenerateInstrumentType,
  GenerateLegalType,
  TransformInterval,
  GenerateStatusDisplay,
  CheckHasValue,
  DateFromNowUTC,
  DateFormatUTC,
  DateFormat,
  HtmlToString,
} from '../../utils';
import {
  colors,
  maxDevice,
  messageColors,
  inputColors,
  fonts,
} from '../../styles/variables';
import {
  LoadingPlaceholder,
  StatusLabel,
} from '../../styles/library/fontStyles';
import PropTypes from 'prop-types';
import 'react-tippy/dist/tippy.css';
import DropdownMenu from '../DropdownMenu';

const SmallTable = ({
  tableHeading,
  headingButton,
  columnHeadings,
  items,
  loaded,
  memberList,
  showTotals,
  totalsObj,
  negativeTotals = false,
  deleteFunction,
  editFunction,
  toggleFunction,
  cloneFunction,
  moreActionFunction,
  type,
  activeColor,
  marginBottom,
  isHousehold = true,
  customEmptyResults = false,
  showEdit = false,
  dragAndDrop = false,
  onDragEnd,
  actionsList = [],
}) => {
  const {
    preferences,
    currency,
    instrumentCategories,
    allInstrumentCategories,
    allMemberCategories,
    showCashValueColumn,
    hasEntityRollup,
    legalCategories,
  } = useSelector((state) => ({
    preferences: state.user.user.preferences,
    currency: state.households.householdCurrency,
    instrumentCategories: state.configs.instrumentCategories,
    allInstrumentCategories: state.configs.allInstrumentCategories,
    allMemberCategories: state.configs.allMemberCategories,
    showCashValueColumn: state.user.showCashValueColumn,
    hasEntityRollup: state.user.hasEntityRollup,
    legalCategories: state.configs.legalCategories,
  }));
  const [showMoreMenu, setShowMoreMenu] = useState(false);

  const activeCheckStyle = {
    width: '3%',
    borderLeft: `2px solid ${activeColor}`,
    minWidth: '35px',
    textAlign: 'center',
  };

  const inactiveCheckStyle = {
    width: '3%',
    borderLeft: `2px solid transparent`,
    minWidth: '35px',
    textAlign: 'center',
  };

  const canDelete = CheckHasValue(deleteFunction, false);

  const generateTableBody = (type, items) => {
    if (
      type === 'members' ||
      type === 'entities' ||
      type === 'personal' ||
      type === 'excludedEntities'
    ) {
      return generateMemberTables(type, items);
    }
    if (type === 'professionals') {
      return generateProfessionalTable(items);
    }
    if (
      type === 'assets' ||
      type === 'income' ||
      type === 'liability' ||
      type === 'insurance'
    ) {
      return generateFinancialTables(type, items);
    }
    if (type === 'legal') {
      return generateLegalTable(items);
    }
    if (
      type === 'customer_members' ||
      type === 'customer_delegation' ||
      type === 'customer_pending' ||
      type === 'customer_transfers'
    ) {
      return generateCustomerMemberTable(type, items);
    }
    if (
      type === 'wants' ||
      type === 'haveCashFlow' ||
      type === 'haveAssets' ||
      type === 'haveInsurance'
    ) {
      return generateTargetMapTables(type, items);
    }
    if (
      type === 'incomes_ben' ||
      type === 'assets_ben' ||
      type === 'liabilities_ben' ||
      type === 'life_insurance_ben' ||
      type === 'other_insurance_ben'
    ) {
      return generateBeneficiaryTables(type, items);
    }
    if (
      type === 'life_insurance' ||
      type === 'disability' ||
      type === 'ltc' ||
      type === 'p&c'
    ) {
      return generatePolicyTables(type, items);
    }
    if (type === 'medical' || type === 'other_insurance' || type === 'legal') {
      return generatePolicyMedicalOtherTables(type, items);
    }
    if (type === 'legal_policy') {
      return generatePolicyLegalTable(type, items);
    }
    if (
      type === 'assets_balance' ||
      type === 'liabilities_balance' ||
      type === 'insurance_balance'
    ) {
      return generateBalanceSheetTables(type, items);
    }
    if (type === 'billing') {
      return generateBillingTable(type, items);
    }
    if (type === 'align') {
      return generateAlignTable(type, items);
    }
    if (
      type === 'align_household' ||
      type === 'align_member' ||
      type === 'align_entity'
    ) {
      return generateAlignSearchTable(type, items);
    }
    if (type === 'household' || type === 'deleted_households') {
      return generateHouseholdTable(type, items);
    }
    if (type === 'deleted_household_items') {
      return generateHouseholdItemsTable(type, items);
    }
    if (type === 'household_sharing') {
      return generateHouseholdSharingTable(items);
    }
    if (type === 'customer_stencil') {
      return generateCustomerStencilTable(items);
    }
  };

  const generateHouseholdItemsTable = (type, items) => {
    if (type === 'deleted_household_items') {
      if (loaded) {
        return items.map((item, index) => {
          return (
            <TableRow
              key={index}
              style={{ height: '50px' }}
              data-row={index + 1}
            >
              {generateCheckboxAlt(item, 'is_selected')}
              <td style={{ width: '30%', textTransform: 'none' }}>
                {item.name}
              </td>
              <td style={{ width: '25%' }}>{item.type}</td>
              <td style={{ width: '25%' }}>
                {item.type === 'member' || item.type === 'entity'
                  ? GenerateEntityLabel(item.category, allMemberCategories)
                  : GenerateInstrumentType(
                      item.category,
                      allInstrumentCategories
                    )}
              </td>
              <td style={{ width: '17%' }}>
                {DateFromNowUTC(item.last_modified)}
              </td>
            </TableRow>
          );
        });
      } else {
        return [1, 2, 3].map((index) => {
          return (
            <TableRow key={index} style={{ height: '50px' }}>
              <td style={{ width: '3%' }}>
                <LoadingPlaceholder
                  round="true"
                  width="20px"
                  height="20px"
                  margin="0 8px"
                />
              </td>
              <td style={{ width: '30%' }}>
                <LoadingPlaceholder width="150px" />
              </td>
              <td style={{ width: '25%' }}>
                <LoadingPlaceholder width="80px" />
              </td>
              <td style={{ width: '25%' }}>
                <LoadingPlaceholder width="120px" />
              </td>
              <td style={{ width: '17%' }}>
                <LoadingPlaceholder width="100px" />
              </td>
            </TableRow>
          );
        });
      }
    }
  };

  //    if (type === 'customer_transfers') {
  //     if (loaded) {
  //       return items.map((item, index) => {
  //         return (
  //           <TableRow key={index}>
  //             <td style={{ textTransform: 'capitalize', width: '8%' }}>
  //               {item.category}
  //             </td>
  //             <td style={{ width: '14%' }}>{item.source_member_name}</td>
  //             <td style={{ width: '14%' }}>
  //               {item.target_member_name ? item.target_member_name : '-'}
  //             </td>
  //             <td style={{ width: '14%' }}>
  //               {item.target_customer_name ? item.target_customer_name : '-'}
  //             </td>
  //             <td style={{ width: '4%', textAlign: 'center' }}>
  //               {item.household_count}
  //             </td>
  //             <td style={{ width: '14%', textAlign: 'center' }}>
  //               {item.created_utc
  // ? DateFormatUTC(item.created_utc,`${preferences.date_format} h:mma`, true)
  //                 : '-'}
  //             </td>
  //             <td style={{ width: '14%', textAlign: 'center' }}>
  //               {item.modified_utc
  // ? DateFormatUTC(item.modified_utc, `${preferences.date_format} h:mma`, true)
  //                 : '-'}
  //             </td>
  //             <td style={{ width: '10%', textAlign: 'center' }}>
  //               {generateTransferStatusDisplay(item.status, item.is_expired)}
  //             </td>
  //           </TableRow>
  //         );
  //       });
  //     }
  //   }
  // };

  // const transformLabel = (value) => {
  //   switch (value) {
  //     case 'assetmap':
  //       return 'Asset-Map';
  //     case 'targetmaps':
  //       return 'Target-Maps';
  //     case 'signals':
  //       return 'Signals';
  //     default:
  //       return value;
  //   }
  // };

  // const generateIcon = (value) => {
  //   switch (value) {
  //     case 'assetmap':
  //       return plusLogoOutline;
  //     case 'targetmaps':
  //       return targetMaps;
  //     case 'signals':
  //       return trafficLight;
  //     default:
  //       return plusLogoOutline;
  //   }
  // };

  // const generatePermissionsDisplay = (item) => {
  //   if (item.status === 'active') {
  //     return (
  //       <PermissionBoxContainer>
  //         <PermissionBox>
  //           <PermissionIcon src={plusLogoOutline} alt="asset-map" />
  //           <PermissionType>Asset-Map</PermissionType>
  //           <PermissionValue>View</PermissionValue>
  //         </PermissionBox>
  //       </PermissionBoxContainer>
  //     );
  // if (item.permissions) {
  //   const permissionList = [];
  //   for (const [key, value] of Object.entries(permissions)) {
  //     permissionList.push({
  //       label: transformLabel(key),
  //       key: key,
  //       icon: generateIcon(key),
  //       access: value,
  //     });
  //   }
  //   return permissionList.map((permission, index) => {
  //     return (
  //       <PermissionBoxContainer key={index}>
  //         <PermissionBox>
  //           <PermissionIcon src={permission.icon} />
  //           <PermissionType>{permission.label}</PermissionType>
  //           <PermissionValue>{permission.access}</PermissionValue>
  //         </PermissionBox>
  //       </PermissionBoxContainer>
  //     );
  //   });
  // }
  //   } else {
  //     return (
  //       <span style={{ color: colors.paleGrey, fontSize: '12px' }}>None</span>
  //     );
  //   }
  // };

  // const generateActions = (item) => {
  //   return actionsList.reduce((acc, curr) => {
  //     if (curr.value === 'share') {
  //       if (item.status === 'inactive') {
  //         return [...acc, curr];
  //       }
  //     } else if (curr.value === 'edit') {
  //       if (item.status !== 'inactive') {
  //         return [...acc, curr];
  //       }
  //     } else if (curr.value === 'pause') {
  //       if (item.status !== 'paused') {
  //         return [...acc, curr];
  //       }
  //     } else if (curr.value === 'resume') {
  //       if (item.status === 'paused') {
  //         return [...acc, curr];
  //       }
  //     } else if (curr.value === 'stop') {
  //       return [...acc, curr];
  //     }
  //     return acc;
  //   }, []);
  // };

  const checkIsAllowed = (action, item) => {
    if (action === 'share') {
      return item.status !== 'active' && item.status !== 'rejected';
    }
    if (action === 'edit') {
      return item.status === 'active';
    }
    if (action === 'preview') {
      return item.status === 'active' || item.status === 'pending';
    }
    // if (action === 'pause') {
    //   return item.status === 'active';
    // }
    // if (action === 'resume') {
    //   return item.status === 'paused';
    // }
    if (action === 'stop') {
      return item.status !== 'inactive';
    }
    return false;
  };

  const generateHouseholdSharingTable = (items) => {
    if (loaded) {
      return items.map((item, index) => {
        // const allowedActions = generateActions(item);
        return (
          <TableRow key={index} style={{ height: '50px' }}>
            <td style={{ width: '18%' }}>
              <ClickableValue onClick={() => editFunction(item)}>
                {HtmlToString(item?.name)}
              </ClickableValue>
            </td>
            <td style={{ width: '13%' }}>
              {GenerateEntityLabel(item.category, allMemberCategories)}
            </td>
            <td style={{ width: '18%', textTransform: 'none' }}>
              {item.email ? item.email : ''}
            </td>
            <td style={{ width: '20%' }}>{item?.role}</td>
            {/* <td style={{ width: '20%' }}>{generatePermissionsDisplay(item)}</td> */}
            <td style={{ width: '15%' }}>
              {item.last_login === null
                ? null
                : preferences !== undefined &&
                  DateFormatUTC(
                    item.last_login,
                    `${preferences?.date_format} h:mma`,
                    true
                  )}
            </td>
            <td style={{ width: '10%', textAlign: 'center' }}>
              {GenerateStatusDisplay(item.status)}
            </td>
            <ActionRow width="6%">
              <div>
                {actionsList.map((action, index) => {
                  if (item.status === 'active') {
                    if (action.value !== 'share') {
                      return (
                        <span
                          key={index}
                          title={action.label}
                          onClick={
                            checkIsAllowed(action.value, item)
                              ? () => action.onClick(item, action.value)
                              : null
                          }
                          style={{
                            cursor: checkIsAllowed(action.value, item)
                              ? 'pointer'
                              : 'not-allowed',
                          }}
                        >
                          <img
                            src={action.icon}
                            alt={action.label}
                            data-image={action.value}
                            style={{
                              opacity: checkIsAllowed(action.value, item)
                                ? '1'
                                : '.5',
                            }}
                          />
                        </span>
                      );
                    }
                  } else {
                    if (action.value !== 'edit') {
                      return (
                        <span
                          key={index}
                          title={action.label}
                          onClick={
                            checkIsAllowed(action.value, item)
                              ? () => action.onClick(item, action.value)
                              : null
                          }
                          style={{
                            cursor: checkIsAllowed(action.value, item)
                              ? 'pointer'
                              : 'not-allowed',
                          }}
                        >
                          <img
                            src={action.icon}
                            alt={action.label}
                            data-image={action.value}
                            style={{
                              opacity: checkIsAllowed(action.value, item)
                                ? '1'
                                : '.5',
                            }}
                          />
                        </span>
                      );
                    }
                  }
                })}
              </div>
            </ActionRow>
          </TableRow>
        );
      });
    } else {
      return [1, 2, 3].map((index) => {
        return (
          <TableRow key={index} style={{ height: '50px' }}>
            <td style={{ width: '18%' }}>
              <LoadingPlaceholder width="120px" />
            </td>
            <td style={{ width: '13%' }}>
              <LoadingPlaceholder width="70px" />
            </td>
            <td style={{ width: '18%' }}>
              <LoadingPlaceholder width="150px" />
            </td>
            <td style={{ width: '20%' }}>
              <LoadingPlaceholder
                width="80px"
                inline="true"
                margin="6px 10px 6px 0"
              />
              {/* <LoadingPlaceholder width="80px" inline="true" /> */}
            </td>
            <td style={{ width: '15%' }}>
              <LoadingPlaceholder width="130px" />
            </td>
            <td style={{ width: '10%', textAlign: 'center' }}>
              <LoadingPlaceholder width="65px" margin="6px auto" />
            </td>
            <ActionRow style={{ width: '6%' }}>
              <div>
                <LoadingPlaceholder width="50px" round={true} />
                <LoadingPlaceholder width="50px" round={true} />
                {/* <LoadingPlaceholder width="50px" round={true} /> */}
              </div>
            </ActionRow>
          </TableRow>
        );
      });
    }
  };

  const generateCustomerStencilTable = (items) => {
    if (loaded) {
      return items.map((item, index) => {
        return (
          <TableRow key={index} style={{ height: '50px' }}>
            <td style={{ width: '20%', fontWeight: fonts.semiBold }}>
              <ImagePreviewIcon
                src={imagePreview}
                alt="asset map preview"
                title="View image preview of stencil"
                onClick={() => item.imageOnClick(item)}
              />
              {item.link ? (
                <a href={item.link} target="_blank" rel="noreferrer">
                  {item.name}
                </a>
              ) : (
                <span>{item.name}</span>
              )}
            </td>
            <td
              style={{ width: '45%', textTransform: 'none' }}
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
            <td style={{ width: '8%', textAlign: 'center' }}>
              {item.members_count ? item.members_count : '0'}
            </td>
            <td style={{ width: '8%', textAlign: 'center' }}>
              {' '}
              {item.stencil_financials_count
                ? item.stencil_financials_count
                : '0'}
            </td>
            <td style={{ width: '15%', textAlign: 'center' }}>
              {item.last_modified_by_utc
                ? DateFormatUTC(
                    item.last_modified_by_utc,
                    `${preferences.date_format} h:mma`,
                    true
                  )
                : '-'}
            </td>
            <ActionRow>
              <Tooltip
                title={item.action?.tooltip}
                position="left"
                trigger="mouseenter click"
                followCursor={false}
                delay={0}
                arrow={true}
                arrowSize={'small'}
                animation={'fade'}
                animateFill={false}
                transitionFlip={false}
                size={'small'}
                distance={-5}
                style={{ paddingRight: '10px' }}
              >
                <span onClick={() => item.action?.onClick(item)}>
                  <img
                    style={{ width: '14px' }}
                    src={item.action?.icon}
                    alt={item.action?.value}
                    data-image={`stencil-${item.action?.value}-${index}`}
                  />
                </span>
              </Tooltip>
            </ActionRow>
          </TableRow>
        );
      });
    } else {
      return [1, 2, 3, 4, 5].map((index) => {
        return (
          <TableRow key={index} style={{ height: '50px' }}>
            <td style={{ width: '20%' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <LoadingPlaceholder
                  width="25px"
                  height="25px"
                  margin="0px 15px 0px 0"
                  round={true}
                  inline={true}
                />
                <LoadingPlaceholder width="120px" inline={true} />
              </div>
            </td>
            <td style={{ width: '45%' }}>
              <LoadingPlaceholder width="90%" />
            </td>
            <td style={{ width: '8%', textAlign: 'center' }}>
              <LoadingPlaceholder width="40px" margin="0 auto" />
            </td>
            <td style={{ width: '8%', textAlign: 'center' }}>
              <LoadingPlaceholder width="40px" margin="0 auto" />
            </td>
            <td style={{ width: '15%', textAlign: 'center' }}>
              <LoadingPlaceholder width="80px" margin="0 auto" />
            </td>
            <ActionRow style={{ width: '6%' }}>
              <LoadingPlaceholder
                width="25px"
                height="25px"
                round={true}
                margin="0 auto"
              />
            </ActionRow>
          </TableRow>
        );
      });
    }
  };

  const generateHouseholdTable = (type, items) => {
    if (type === 'deleted_households') {
      if (loaded) {
        return items.map((item, index) => {
          return (
            <TableRow key={index} style={{ height: '50px' }}>
              {generateCheckboxAlt(item, 'is_selected')}
              <td style={{ width: '30%', textTransform: 'none' }}>
                {item.name}
              </td>
              <td style={{ width: '25%' }}>{item.member.name}</td>
              <td style={{ width: '25%' }}>{item.customer.name}</td>
              <td style={{ width: '17%' }}>
                {DateFromNowUTC(item.last_modified_by_utc)}
              </td>
            </TableRow>
          );
        });
      } else {
        return [1, 2, 3].map((index) => {
          return (
            <TableRow key={index} style={{ height: '50px' }}>
              <td style={{ width: '3%' }}>
                <LoadingPlaceholder
                  round="true"
                  width="20px"
                  height="20px"
                  margin="0 8px"
                />
              </td>
              <td style={{ width: '30%' }}>
                <LoadingPlaceholder width="150px" />
              </td>
              <td style={{ width: '25%' }}>
                <LoadingPlaceholder width="80px" />
              </td>
              <td style={{ width: '25%' }}>
                <LoadingPlaceholder width="120px" />
              </td>
              <td style={{ width: '17%' }}>
                <LoadingPlaceholder width="100px" />
              </td>
            </TableRow>
          );
        });
      }
    }
  };

  const generateMemberTables = (type, items) => {
    if (type === 'members' || type === 'personal') {
      if (loaded) {
        return items.map((item, index) => {
          return (
            <TableRow key={index}>
              <td style={{ maxWidth: '260px' }}>
                {item.is_linked && (
                  <LinkIcon
                    src={link}
                    alt="linked"
                    data-image={`linked-${index}`}
                  />
                )}
                <ClickableValue onClick={() => editFunction(item)}>
                  {item?.categoryDisplay}
                </ClickableValue>
              </td>
              <td>{item.first_name}</td>
              <td>{item.last_name}</td>
              <td>{TransformGender(item.gender)}</td>
              <td
                style={{
                  fontStyle: item.date_of_birth_estimated ? 'italic' : null,
                  color: item.date_of_birth_estimated ? colors.paleGrey : null,
                  textTransform: 'lowercase',
                }}
              >
                <span
                  title={
                    item.date_of_birth_estimated
                      ? 'Date of Birth Estimated'
                      : null
                  }
                >
                  {preferences !== undefined && item.date_of_birth
                    ? DateFormat(item.date_of_birth, preferences.date_format)
                    : '-'}
                  {item.date_of_birth_estimated && item.date_of_birth && ' (e)'}
                </span>
              </td>
              <td>
                {preferences !== undefined && item.date_of_birth
                  ? GenerateAge(item.date_of_birth, preferences.date_format)
                  : '-'}
              </td>
              {createActionItems(item, false, false, !canDelete)}
            </TableRow>
          );
        });
      } else {
        return [1, 2, 3].map((index) => {
          return (
            <TableRow key={index}>
              <td>
                <LoadingPlaceholder />
              </td>
              <td>
                <LoadingPlaceholder width="110px" />
              </td>
              <td>
                <LoadingPlaceholder width="150px" />
              </td>
              <td>
                <LoadingPlaceholder width="60px" />
              </td>
              <td>
                <LoadingPlaceholder width="70px" />
              </td>
              <td>
                <LoadingPlaceholder width="50px" />
              </td>
              <ActionRow>
                <div style={{ paddingRight: '10px' }}>
                  <LoadingPlaceholder width="50px" round={true} />
                  {canDelete && (
                    <LoadingPlaceholder width="50px" round={true} />
                  )}
                </div>
              </ActionRow>
            </TableRow>
          );
        });
      }
    }
    if (type === 'entities' || type === 'excludedEntities') {
      if (loaded) {
        return items.map((item, index) => {
          return (
            <TableRow key={index}>
              <td>
                {item.is_linked && (
                  <LinkIcon
                    src={link}
                    alt="linked"
                    data-image={`linked-${index}`}
                  />
                )}
                <ClickableValue onClick={() => editFunction(item)}>
                  {item?.categoryDisplay}
                </ClickableValue>
              </td>
              <td>{item.last_name}</td>
              <td>
                {item.owners &&
                  item.owners.length !== 0 &&
                  item.owners.map((owner, index) => {
                    return (
                      !owner.member.hide && (
                        <LinkedLabel key={index}>
                          <span>{owner.member.name}</span>
                          {owner?.member?.member_type !== 'entity' && (
                            <MemberAge
                              style={
                                owner.member.date_of_birth_estimated
                                  ? { fontStyle: 'italic' }
                                  : null
                              }
                            >
                              {owner.member.age}
                              {owner.member.date_of_birth_estimated && 'e'}
                            </MemberAge>
                          )}
                        </LinkedLabel>
                      )
                    );
                  })}
              </td>
              {hasEntityRollup && type !== 'excludedEntities' && (
                <td style={{ textAlign: 'center' }}>
                  {item.entity_rollup ? (
                    <CheckBoxImage
                      src={checkbox}
                      alt="checked"
                      data-image={`is_entity_rollup-${index}`}
                    />
                  ) : (
                    <CheckBoxNullImage
                      src={minus}
                      alt="none"
                      data-image={`is-not_entity_rollup-${index}`}
                    />
                  )}
                </td>
              )}
              {createActionItems(item, false, false, !canDelete)}
            </TableRow>
          );
        });
      } else {
        return [1].map((index) => {
          return (
            <TableRow key={index}>
              <td>
                <LoadingPlaceholder width="140px" />
              </td>
              <td>
                <LoadingPlaceholder width="110px" />
              </td>
              <td>
                <LoadingPlaceholder width="150px" />
              </td>
              {hasEntityRollup && type !== 'excludedEntities' && (
                <td style={{ textAlign: 'center' }}>
                  <LoadingPlaceholder width="18px" margin="6px auto" />
                </td>
              )}
              <ActionRow>
                <div style={{ paddingRight: '10px' }}>
                  <LoadingPlaceholder width="50px" round={true} />
                  {canDelete && (
                    <LoadingPlaceholder width="50px" round={true} />
                  )}
                </div>
              </ActionRow>
            </TableRow>
          );
        });
      }
    }
  };

  const generateProfessionalTable = (items) => {
    if (loaded) {
      return items.map((item, index) => {
        return (
          <TableRow key={index}>
            <td>
              <ClickableValue onClick={() => editFunction(item)}>
                {item?.categoryDisplay}
              </ClickableValue>
            </td>
            <td>{item.business_name}</td>
            <td>{item.first_name ? item.first_name : '-'}</td>
            <td>{item.last_name ? item.last_name : '-'}</td>
            {createActionItems(item, false, false, !canDelete)}
          </TableRow>
        );
      });
    } else {
      return [1, 2].map((index) => {
        return (
          <TableRow key={index}>
            <td>
              <LoadingPlaceholder width="110px" />
            </td>
            <td>
              <LoadingPlaceholder width="150px" />
            </td>
            <td>
              <LoadingPlaceholder width="80px" />
            </td>
            <td>
              <LoadingPlaceholder width="80px" />
            </td>
            <ActionRow>
              <div style={{ paddingRight: '10px' }}>
                <LoadingPlaceholder width="50px" round={true} />
                {canDelete && <LoadingPlaceholder width="50px" round={true} />}
              </div>
            </ActionRow>
          </TableRow>
        );
      });
    }
  };

  const determineNegative = (item) => {
    if (instrumentCategories) {
      let matchedObj = instrumentCategories[item.category];
      if (matchedObj) {
        return matchedObj.negative;
      }
    }
  };

  const determineCashValueDisplay = (item) => {
    const allowedCategories = ['lifeul', 'lifewhole', 'lifevul'];
    if (allowedCategories.includes(item?.category)) {
      return FormatCurrency(item.cash_value ? item.cash_value : 0, currency);
    } else {
      return '-';
    }
  };

  const generateFinancialRow = (item, index) => {
    let negativeCategory = determineNegative(item);
    let signVal = Math.sign(item.amount);
    let negative = signVal === -1 ? true : false;
    return (
      <TableRow
        key={index}
        style={{
          backgroundImage: item.is_hidden
            ? `url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%238a888e' fill-opacity='0.075' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E")`
            : null,
          cursor: showEdit ? 'pointer' : null,
          backgroundColor: item.is_selected ? colors.paleBlue : null,
        }}
        onClick={showEdit ? () => toggleFunction(item) : null}
        data-is_hidden={item.is_hidden}
        data-bulk_selected={item.is_selected}
      >
        {showEdit && generateCheckboxFinancials(item)}
        <td style={{ width: '10%' }}>
          {item.is_linked && (
            <LinkIcon src={link} alt="linked" data-image={`linked-${index}`} />
          )}
          <ClickableValue onClick={() => editFunction(item)}>
            {GenerateInstrumentType(item.category, allInstrumentCategories)}
          </ClickableValue>
        </td>
        <td style={{ width: '7%', opacity: item.is_hidden ? 0.5 : null }}>
          {item.status}
        </td>
        <td style={{ width: '28%', opacity: item.is_hidden ? 0.5 : null }}>
          {item.members &&
            item.members.length !== 0 &&
            item.members.map((member, index) => {
              return (
                <LinkedLabel key={index}>
                  <span>{member?.member?.name}</span>
                  {member.member.member_type === 'member' && (
                    <MemberAge
                      style={
                        member.member.date_of_birth_estimated
                          ? { fontStyle: 'italic' }
                          : null
                      }
                    >
                      {member.member.age}
                      {member.member.date_of_birth_estimated && 'e'}
                    </MemberAge>
                  )}
                </LinkedLabel>
              );
            })}
        </td>
        <td style={{ width: '13%', opacity: item.is_hidden ? 0.5 : null }}>
          {item.name}
        </td>
        <td style={{ width: '11%', opacity: item.is_hidden ? 0.5 : null }}>
          {item.source}
        </td>
        {item.instrument_type === 'insurance' && showCashValueColumn && (
          <td
            style={{
              width: '7%',
              textTransform: 'none',
              textAlign: 'right',
              opacity: item.is_hidden ? 0.5 : null,
            }}
          >
            {determineCashValueDisplay(item)}
          </td>
        )}
        <td
          style={{
            width: '13%',
            textTransform: 'none',
            textAlign: 'right',
            paddingRight: '8px',
            color: negative || negativeCategory ? messageColors.error : null,
            opacity: item.is_hidden ? 0.5 : null,
          }}
        >
          {item.is_future_value && item.instrument_type !== 'insurance' && (
            <span>FV </span>
          )}
          {negativeCategory && !negative
            ? `(${FormatCurrency(item.amount, currency)})`
            : FormatCurrency(item.amount, currency)}
          {item.amount_period_display && item.amount_period_display}
        </td>
        <td
          style={{
            width: '9%',
            textAlign: 'center',
            opacity: item.is_hidden ? 0.5 : null,
          }}
        >
          {preferences !== undefined &&
            DateFormat(item.as_of_date, preferences.date_format)}
        </td>
        <td
          style={{
            width: '3%',
            textAlign: 'center',
            opacity: item.is_hidden ? 0.5 : null,
          }}
        >
          {item.is_managed ? (
            <CheckBoxImage
              src={checkbox}
              alt="checked"
              data-image={`is_managed-${index}`}
            />
          ) : (
            <CheckBoxNullImage
              src={minus}
              alt="none"
              data-image={`not_managed-${index}`}
            />
          )}
        </td>
        {createActionItems(item, false, false, !canDelete)}
      </TableRow>
    );
  };

  const generateLegalRow = (item, index) => {
    return (
      <TableRow
        key={index}
        style={{
          backgroundImage: item.is_hidden
            ? `url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%238a888e' fill-opacity='0.075' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E")`
            : null,
          cursor: showEdit ? 'pointer' : null,
          backgroundColor: item.is_selected ? colors.paleBlue : null,
        }}
        onClick={showEdit ? () => toggleFunction(item) : null}
        data-is_hidden={item.is_hidden}
        data-bulk_selected={item.is_selected}
      >
        <td style={{ width: '15%' }}>
          {item.is_linked && (
            <LinkIcon src={link} alt="linked" data-image={`linked-${index}`} />
          )}
          <ClickableValue onClick={() => editFunction(item)}>
            {GenerateLegalType(item.category, legalCategories)}
          </ClickableValue>
        </td>
        <td style={{ width: '20%', opacity: item.is_hidden ? 0.5 : null }}>
          {item.applicable_members &&
            item.applicable_members.length !== 0 &&
            item.applicable_members.map((member, index) => {
              return (
                <LinkedLabel key={index}>
                  <span>{member?.name}</span>
                  {member?.member_type === 'member' && (
                    <MemberAge
                      style={
                        member.date_of_birth_estimated
                          ? { fontStyle: 'italic' }
                          : null
                      }
                    >
                      {member.age}
                      {member.date_of_birth_estimated && 'e'}
                    </MemberAge>
                  )}
                </LinkedLabel>
              );
            })}
        </td>
        <td style={{ width: '15%', opacity: item.is_hidden ? 0.5 : null }}>
          {item.reference_name}
        </td>
        <td style={{ width: '15%', opacity: item.is_hidden ? 0.5 : null }}>
          {item.location}
        </td>
        <td
          style={{
            width: '9%',
            textAlign: 'center',
          }}
        >
          {preferences !== undefined && item.date_created !== ''
            ? DateFormat(item.date_created, preferences.date_format)
            : '-'}
        </td>
        <td
          style={{
            width: '9%',
            textAlign: 'center',
            opacity: item.is_hidden ? 0.5 : null,
          }}
        >
          {preferences !== undefined && item.date_collected
            ? DateFormat(item.date_collected, preferences.date_format)
            : '-'}
        </td>
        {createActionItems(item, false, false, !canDelete)}
      </TableRow>
    );
  };

  const moreActionsList = [
    {
      label: 'Reset MFA',
      value: 'reset_mfa',
      icon: undo,
      onClick: moreActionFunction,
    },
    {
      label: 'Reset Password',
      value: 'reset_password',
      icon: keyIcon,
      onClick: moreActionFunction,
    },
  ];

  const generateTransferStatusDisplay = (status, isExpired) => {
    if (isExpired && status !== 'canceled') {
      status = 'expired';
    }
    let labelText = status;
    let textColor = colors.darkGrey;
    let backgroundColor = colors.lightGrey;
    let borderColor = null;
    switch (status) {
      case 'completed':
      case 'complete':
        labelText = 'completed';
        textColor = colors.green;
        backgroundColor = messageColors.successLabelBg;
        break;
      case 'expired':
        labelText = 'expired';
        textColor = colors.darkGrey;
        backgroundColor = colors.lightGrey;
        break;
      case 'undone':
        labelText = 'undone';
        textColor = colors.midGrey;
        backgroundColor = 'transparent';
        borderColor = colors.midGrey;
        break;
      case 'initiated':
      case 'pending':
        labelText = 'pending';
        textColor = messageColors.warning;
        backgroundColor = messageColors.warningLabelBg;
        break;
      case 'canceled':
        labelText = 'canceled';
        textColor = messageColors.error;
        backgroundColor = messageColors.errorLabelBg;
        break;
      default:
        labelText = status;
    }
    return (
      <StatusLabel
        color={textColor}
        background={backgroundColor}
        borderColor={borderColor}
      >
        {labelText}
      </StatusLabel>
    );
  };

  const checkAllowed = (action, item) => {
    //SHARE IS ONLY ALLOWED WHEN EXTERNAL AND NOT COMPLETED
    if (action === 'share') {
      if (item.category === 'external') {
        if (item.status === 'canceled') {
          return false;
        } else {
          return item.status === 'pending' || item.is_expired;
        }
      }
    }
    //UNDO IS ONLY ALLOWED WHEN TRANSFER IS INTERNAL AND COMPLETED
    if (action === 'undo') {
      if (item.category === 'internal') {
        return item.status === 'completed' || item.status === 'complete';
      }
    }
    //CANCEL IS ONLY ALLOWED WHEN EXTERNAL AND NOT COMPLETED
    if (action === 'cancel') {
      if (item.category === 'external') {
        if (item.status === 'canceled') {
          return false;
        } else {
          return item.status === 'pending' || item.is_expired;
        }
      }
    }
    return false;
  };

  const generateCustomerMemberTable = (type) => {
    if (type === 'customer_members') {
      if (loaded) {
        return items.map((item, index) => {
          item.menu_key = `${item.email}_${index}`;
          return (
            <React.Fragment key={index}>
              <TableRow>
                <td style={{ textTransform: 'lowercase', width: '25%' }}>
                  {item.email}
                </td>
                <td style={{ width: '10%' }}>{item.first_name}</td>
                <td style={{ width: '10%' }}>{item.last_name}</td>
                {generateCheckboxAlt(item, 'is_advisor')}
                {generateCheckboxAlt(item, 'is_delegate')}
                {generateCheckboxAlt(item, 'is_manager')}
                {columnHeadings.some((heading) => heading.text === 'Billing') &&
                  generateCheckboxAlt(item, 'is_billing_manager')}
                {columnHeadings.some(
                  (heading) => heading.text === 'Customize'
                ) && generateCheckboxAlt(item, 'is_ldd_enabled')}
                <td style={{ width: '10%', textAlign: 'center' }}>
                  <StatusLabel
                    color={
                      item.is_mfa_enabled ? colors.green : messageColors.error
                    }
                    background={
                      item.is_mfa_enabled
                        ? messageColors.successLabelBg
                        : messageColors.errorLabelBg
                    }
                  >
                    {item.is_mfa_enabled ? 'Active' : 'Inactive'}
                  </StatusLabel>
                </td>
                {createActionItems(item, false, true, false, true)}
              </TableRow>
              {showMoreMenu === item.menu_key && (
                <DropdownMenu
                  showMoreMenu={showMoreMenu}
                  setShowMoreMenu={setShowMoreMenu}
                  heading="More Actions"
                  items={moreActionsList.reduce((acc, curr) => {
                    if (curr.value === 'reset_mfa' && !item.is_mfa_enabled) {
                      return acc;
                    }
                    return [...acc, curr];
                  }, [])}
                  passedData={item}
                  customStyle={{
                    right: '41px',
                    top: null,
                    width: '145px',
                    margin: '-5px 0 0 0',
                  }}
                />
              )}
            </React.Fragment>
          );
        });
      } else {
        return [1, 2, 3, 4, 5].map((index) => {
          return (
            <TableRow key={index}>
              <td style={{ width: '25%' }}>
                <LoadingPlaceholder width="220px" />
              </td>
              <td style={{ width: '10%' }}>
                <LoadingPlaceholder width="120px" />
              </td>
              <td style={{ width: '10%' }}>
                <LoadingPlaceholder width="120px" />
              </td>
              <td style={{ width: '3%' }}>
                <LoadingPlaceholder width="18px" margin="6px auto" />
              </td>
              <td style={{ width: '3%' }}>
                <LoadingPlaceholder width="18px" margin="6px auto" />
              </td>
              <td style={{ width: '3%' }}>
                <LoadingPlaceholder width="18px" margin="6px auto" />
              </td>
              {columnHeadings.some((heading) => heading.text === 'Billing') && (
                <td style={{ width: '3%' }}>
                  <LoadingPlaceholder width="18px" margin="6px auto" />
                </td>
              )}
              {columnHeadings.some(
                (heading) => heading.text === 'Customize'
              ) && (
                <td style={{ width: '3%' }}>
                  <LoadingPlaceholder width="18px" margin="6px auto" />
                </td>
              )}
              <td style={{ width: '10%', textAlign: 'center' }}>
                <LoadingPlaceholder width="65px" margin="6px auto" />
              </td>
              <ActionRow style={{ width: '3%' }}>
                <div>
                  <LoadingPlaceholder width="50px" round={true} />
                  <LoadingPlaceholder width="50px" round={true} />
                </div>
              </ActionRow>
            </TableRow>
          );
        });
      }
    }
    if (type === 'customer_delegation') {
      if (loaded) {
        return items.map((item, index) => {
          return (
            <TableRow key={index}>
              <td style={{ textTransform: 'lowercase', width: '25%' }}>
                {item.email}
              </td>
              <td style={{ width: '10%' }}>{item.first_name}</td>
              <td style={{ width: '10%' }}>{item.last_name}</td>
              <td style={{ width: '40%' }}>
                {item?.delegators && item.delegators.length !== 0
                  ? item.delegators.map((delegate, index) => {
                      return (
                        <LinkedLabel key={index}>
                          <span>{delegate.name}</span>
                        </LinkedLabel>
                      );
                    })
                  : 'None'}
              </td>
              {createActionItems(item, false, false, true)}
            </TableRow>
          );
        });
      } else {
        return [1, 2, 3].map((index) => {
          return (
            <TableRow key={index}>
              <td style={{ width: '25%' }}>
                <LoadingPlaceholder width="220px" />
              </td>
              <td style={{ width: '10%' }}>
                <LoadingPlaceholder width="120px" />
              </td>
              <td style={{ width: '10%' }}>
                <LoadingPlaceholder width="120px" />
              </td>
              <td style={{ width: '40%' }}>
                <LoadingPlaceholder width="220px" />
              </td>
              <ActionRow>
                <div style={{ paddingRight: '10px' }}>
                  <LoadingPlaceholder width="50px" round={true} />
                </div>
              </ActionRow>
            </TableRow>
          );
        });
      }
    }
    if (type === 'customer_pending') {
      if (loaded) {
        return items.map((item, index) => {
          return (
            <TableRow key={index}>
              <td style={{ width: '10%' }}>{item.type}</td>
              <td style={{ textTransform: 'lowercase', width: '25%' }}>
                {item.email}
              </td>
              <td style={{ width: '10%' }}>{item.status}</td>
              <td style={{ width: '10%', textAlign: 'center' }}>
                {DateFormatUTC(
                  item.modified_utc,
                  `${preferences.date_format} h:mma`
                )}
              </td>
              {/* {createActionItems(item, false, true, false)} */}
              <ActionRow>
                <div style={{ paddingRight: '10px' }}>
                  {item.status === 'sent' && (
                    <span
                      onClick={!item.loading ? () => editFunction(item) : null}
                    >
                      {item.loading ? (
                        <SpinnerContainer style={{ marginLeft: '-2px' }}>
                          <LoadingAnimation
                            dots={true}
                            smaller={true}
                            table={true}
                            color={colors.paleGrey}
                          />
                        </SpinnerContainer>
                      ) : (
                        <img
                          src={share}
                          alt="send"
                          data-image={`send-${index}`}
                        />
                      )}
                    </span>
                  )}
                  <span onClick={() => deleteFunction(item)}>
                    <img
                      src={trashDark}
                      alt="delete"
                      data-image={`delete-${index}`}
                    />
                  </span>
                </div>
              </ActionRow>
            </TableRow>
          );
        });
      } else {
        return [1, 2, 3].map((index) => {
          return (
            <TableRow key={index}>
              <td style={{ width: '10%' }}>
                <LoadingPlaceholder width="70px" />
              </td>
              <td style={{ width: '25%' }}>
                <LoadingPlaceholder width="220px" />
              </td>
              <td style={{ width: '10%' }}>
                <LoadingPlaceholder width="90px" />
              </td>
              <td style={{ width: '10%' }}>
                <LoadingPlaceholder width="120px" margin="6px auto" />
              </td>
              <ActionRow>
                <div style={{ paddingRight: '10px' }}>
                  <LoadingPlaceholder width="50px" round={true} />
                </div>
              </ActionRow>
            </TableRow>
          );
        });
      }
    }
    if (type === 'customer_transfers') {
      if (loaded) {
        return items.map((item, index) => {
          return (
            <TableRow key={index}>
              <td style={{ textTransform: 'capitalize', width: '8%' }}>
                {item.category}
              </td>
              <td style={{ width: '14%' }}>{item.source_member_name}</td>
              <td style={{ width: '14%' }}>
                {item.target_member_name ? item.target_member_name : '-'}
              </td>
              <td style={{ width: '14%' }}>
                {item.target_customer_name ? item.target_customer_name : '-'}
              </td>
              <td style={{ width: '4%', textAlign: 'center' }}>
                {item.household_count}
              </td>
              <td style={{ width: '14%', textAlign: 'center' }}>
                {item.created_utc
                  ? DateFormatUTC(
                      item.created_utc,
                      `${preferences.date_format} h:mma`,
                      true
                    )
                  : '-'}
              </td>
              <td style={{ width: '14%', textAlign: 'center' }}>
                {item.modified_utc
                  ? DateFormatUTC(
                      item.modified_utc,
                      `${preferences.date_format} h:mma`,
                      true
                    )
                  : '-'}
              </td>
              <td style={{ width: '10%', textAlign: 'center' }}>
                {generateTransferStatusDisplay(item.status, item.is_expired)}
              </td>
              <ActionRow width="8%">
                <div>
                  {actionsList.map((action, index) => {
                    return (
                      <span
                        key={index}
                        onClick={
                          checkAllowed(action.value, item)
                            ? () => action.onClick(item)
                            : null
                        }
                        style={{
                          cursor: checkAllowed(action.value, item)
                            ? 'pointer'
                            : 'not-allowed',
                        }}
                      >
                        <img
                          src={action.icon}
                          alt={action.label}
                          data-image={action.value}
                          style={{
                            opacity: checkAllowed(action.value, item)
                              ? '1'
                              : '.5',
                          }}
                        />
                      </span>
                    );
                  })}
                </div>
              </ActionRow>
            </TableRow>
          );
        });
      } else {
        return [1, 2, 3].map((index) => {
          return (
            <TableRow key={index}>
              <td style={{ width: '8%' }}>
                <LoadingPlaceholder width="50px" />
              </td>
              <td style={{ width: '15%' }}>
                <LoadingPlaceholder width="120px" />
              </td>
              <td style={{ width: '15%' }}>
                <LoadingPlaceholder width="120px" />
              </td>
              <td style={{ width: '15%' }}>
                <LoadingPlaceholder width="100px" />
              </td>
              <td style={{ width: '5%' }}>
                <LoadingPlaceholder width="30px" margin="6px auto" />
              </td>
              <td style={{ width: '15%' }}>
                <LoadingPlaceholder width="120px" margin="6px auto" />
              </td>
              <td style={{ width: '15%' }}>
                <LoadingPlaceholder width="120px" margin="6px auto" />
              </td>
              <td style={{ width: '8%', textAlign: 'center' }}>
                <LoadingPlaceholder width="65px" margin="6px auto" />
              </td>
              <ActionRow style={{ width: '5%' }}>
                <div>
                  <LoadingPlaceholder width="50px" round={true} />
                  <LoadingPlaceholder width="50px" round={true} />
                  <LoadingPlaceholder width="50px" round={true} />
                </div>
              </ActionRow>
            </TableRow>
          );
        });
      }
    }
  };

  const generateCheckboxAlt = (item, type) => {
    if (item[`${type}_loading`]) {
      return (
        <td style={{ width: '3%', textAlign: 'center', minWidth: '35px' }}>
          <SpinnerContainer style={{ marginTop: '-4px' }}>
            <LoadingAnimation
              dots={true}
              smaller={true}
              table={true}
              color={colors.paleGrey}
            />
          </SpinnerContainer>
        </td>
      );
    }
    return (
      <td style={item[type] ? activeCheckStyle : inactiveCheckStyle}>
        <CheckBoxClick onClick={() => toggleFunction(item, type)}>
          {item[type] ? (
            <CheckBoxImage
              src={checkboxGreen}
              alt="checked"
              data-image={`checkbox-checked`}
            />
          ) : (
            <CheckBoxImage
              src={square}
              alt="empty"
              data-image={`checkbox-unchecked`}
            />
          )}
        </CheckBoxClick>
      </td>
    );
  };

  const generateCheckboxFinancials = (item) => {
    return (
      <td style={{ width: '4%', minWidth: '35px' }}>
        <CheckBoxContainer>
          <CheckBoxImage
            src={item.is_selected ? checkboxGreen : square}
            alt={item.is_selected ? 'checked' : 'empty'}
            data-image={
              item.is_selected ? 'checkbox-checked' : 'checkbox-unchecked'
            }
          />
        </CheckBoxContainer>
      </td>
    );
  };

  const generateCheckbox = (item, type) => {
    return (
      <td style={item.is_active ? activeCheckStyle : inactiveCheckStyle}>
        <CheckBoxClick onClick={() => toggleFunction(item, type)}>
          {item.is_active ? (
            <CheckBoxImage
              src={checkbox}
              alt="checked"
              data-image="checkbox-checked"
            />
          ) : (
            <CheckBoxImage
              src={square}
              alt="empty"
              data-image="checkbox-unchecked"
            />
          )}
        </CheckBoxClick>
      </td>
    );
  };

  const generateMemberDisplay = (item, key = 'memberObj') => {
    return (
      item[key] && (
        <LinkedLabel key={item?.[key]?.id}>
          <span>{item?.[key]?.name}</span>
          {item?.[key]?.member_type === 'member' && (
            <MemberAge
              style={
                item?.[key]?.date_of_birth_estimated
                  ? { fontStyle: 'italic' }
                  : null
              }
            >
              {item?.[key]?.age}
              {item?.[key]?.date_of_birth_estimated && 'e'}
            </MemberAge>
          )}
        </LinkedLabel>
      )
    );
  };

  const generateMembersDisplay = (item, memberType = 'insured') => {
    let filteredMembers = [];
    if (item?.members) {
      filteredMembers = item?.members.filter(
        (mem) => mem.relation === memberType
      );
    }
    return (
      filteredMembers &&
      filteredMembers.length !== 0 &&
      filteredMembers.map((member, index) => {
        return (
          <LinkedLabel key={index}>
            <span>{member?.memberObj?.name}</span>
            {member?.memberObj?.member_type === 'member' && (
              <MemberAge
                style={
                  member?.memberObj?.date_of_birth_estimated
                    ? { fontStyle: 'italic' }
                    : null
                }
              >
                {member?.memberObj?.age}
                {member?.memberObj?.date_of_birth_estimated && 'e'}
              </MemberAge>
            )}
          </LinkedLabel>
        );
      })
    );
  };

  const generateCashFlowRow = (item, index) => {
    return (
      <Draggable draggableId={item.id} index={index} key={item.id}>
        {(provided, snapshot) => (
          <DraggableRow
            key={index}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
            matchesSearch={item.matches_search}
            active={item.is_active}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {generateCheckbox(item, 'want')}
            <td style={{ width: '20%', textAlign: 'left' }}>
              <ClickableValue onClick={() => editFunction(item)}>
                {item.category_description}
              </ClickableValue>
            </td>
            <td style={{ width: '10%', textAlign: 'left' }}>
              {generateMemberDisplay(item)}
            </td>
            <td
              style={{
                width: '10%',
                textAlign: 'right',
                color: IsNegative(item.amount)
                  ? item.is_active
                    ? messageColors.error
                    : messageColors.errorHalfOpacity
                  : null,
              }}
            >
              {FormatCurrency(item.amount, currency)}
            </td>
            <td style={{ width: '10%' }}>
              {preferences !== undefined &&
                item.start_date !== null &&
                DateFormat(item.start_date, preferences.date_format)}
            </td>
            <td style={{ width: '10%' }}>
              {preferences !== undefined &&
                item.end_date !== null &&
                DateFormat(item.end_date, preferences.date_format)}
            </td>
            <td style={{ width: '5%' }}>{item.start_age}</td>
            <td style={{ width: '5%' }}>{item.end_age}</td>
            <td style={{ width: '5%' }}>{item.inflation_rate}%</td>
            <td
              style={{
                width: '10%',
                textAlign: 'right',
                color: IsNegative(item.amount)
                  ? item.is_active
                    ? messageColors.error
                    : messageColors.errorHalfOpacity
                  : null,
              }}
            >
              {FormatCurrency(Math.round(item.capital_value), currency)}
            </td>
            {createActionItems(item, true, false, false, false, true)}
          </DraggableRow>
        )}
      </Draggable>
    );
  };

  const generateCashFlowRowAvailable = (item, index) => {
    return (
      <Draggable draggableId={item.id} index={index} key={item.id}>
        {(provided, snapshot) => (
          <DraggableRow
            key={index}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
            matchesSearch={item.matches_search}
            active={item.is_active}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {generateCheckbox(item, 'income')}
            <td style={{ width: '10%', textAlign: 'left' }}>
              <ClickableValue onClick={() => editFunction(item)}>
                {item.name}
                {item?.is_contribution && ' Contributions'}
              </ClickableValue>
            </td>
            <td style={{ width: '13%', textAlign: 'left' }}>
              {generateMemberDisplay(item)}
            </td>
            <td
              style={{
                width: '10%',
                textAlign: 'right',
                color: IsNegative(item.amount)
                  ? item.is_active
                    ? messageColors.error
                    : messageColors.errorHalfOpacity
                  : null,
              }}
            >
              {item.is_future_value && <span>FV </span>}
              {FormatCurrency(item.amount, currency)}
            </td>
            <td
              style={{
                width: '10%',
                fontStyle: item.is_starting_now ? 'italic' : null,
              }}
            >
              {preferences !== undefined &&
                item.start_date !== null &&
                DateFormat(item.start_date, preferences.date_format)}
            </td>
            <td style={{ width: '10%' }}>
              {preferences !== undefined &&
                item.end_date !== null &&
                DateFormat(item.end_date, preferences.date_format)}
            </td>
            <td
              style={{
                width: '6%',
                fontStyle: item.is_starting_now ? 'italic' : null,
              }}
            >
              {item.start_age}
            </td>
            <td style={{ width: '6%' }}>{item.end_age}</td>
            <td style={{ width: '6%' }}>{item.inflation_rate}%</td>
            <td style={{ width: '6%' }}>
              {item.tax_rate ? item.tax_rate : 0}%
            </td>
            <td
              style={{
                width: '10%',
                textAlign: 'right',
                color: IsNegative(item.capital_value)
                  ? item.is_active
                    ? messageColors.error
                    : messageColors.errorHalfOpacity
                  : null,
              }}
            >
              {FormatCurrency(Math.round(item.capital_value), currency)}
            </td>
            {createActionItems(item, true)}
          </DraggableRow>
        )}
      </Draggable>
    );
  };

  const generateAssetsAvailable = (item, index) => {
    return (
      <Draggable draggableId={item.id} index={index} key={item.id}>
        {(provided, snapshot) => (
          <DraggableRow
            key={index}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
            matchesSearch={item.matches_search}
            active={item.is_active}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {generateCheckbox(item, 'asset')}
            <td style={{ width: '13%', textAlign: 'left' }}>
              <ClickableValue onClick={() => editFunction(item)}>
                {item.name}
              </ClickableValue>
            </td>
            <td style={{ width: '13%', textAlign: 'left' }}>
              {generateMemberDisplay(item)}
            </td>
            <td
              style={{
                width: '12%',
                textAlign: 'right',
                color: IsNegative(item.amount)
                  ? item.is_active
                    ? messageColors.error
                    : messageColors.errorHalfOpacity
                  : null,
              }}
            >
              {item.is_future_value && <span>FV </span>}
              {FormatCurrency(item.amount, currency)}
            </td>
            <td
              style={{
                width: '10%',
                fontStyle: item.is_starting_now ? 'italic' : null,
              }}
            >
              {preferences !== undefined &&
                item.start_date !== null &&
                DateFormat(item.start_date, preferences.date_format)}
            </td>
            <td
              style={{
                width: '8%',
                fontStyle: item.is_starting_now ? 'italic' : null,
              }}
            >
              {item.start_age}
            </td>
            <td style={{ width: '8%' }}>
              {item.tax_rate ? item.tax_rate : 0}%
            </td>
            <td
              style={{
                width: '12%',
                textAlign: 'right',
                color: IsNegative(item.capital_value)
                  ? item.is_active
                    ? messageColors.error
                    : messageColors.errorHalfOpacity
                  : null,
              }}
            >
              {FormatCurrency(Math.round(item.capital_value), currency)}
            </td>
            {createActionItems(item, true)}
          </DraggableRow>
        )}
      </Draggable>
    );
  };

  const generateInsuranceAvailable = (item, index) => {
    return (
      <Draggable draggableId={item.id} index={index} key={item.id}>
        {(provided, snapshot) => (
          <DraggableRow
            key={index}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
            matchesSearch={item.matches_search}
            active={item.is_active}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {generateCheckbox(item, 'insurance')}
            <td style={{ width: '13%', textAlign: 'left' }}>
              <ClickableValue onClick={() => editFunction(item)}>
                {item.name}
              </ClickableValue>
            </td>
            <td style={{ width: '13%', textAlign: 'left' }}>
              {item.description}
            </td>
            <td style={{ width: '13%', textAlign: 'left' }}>
              {generateMembersDisplay(item)}
            </td>
            <td
              style={{
                width: '12%',
                textAlign: 'right',
                color: IsNegative(item.amount)
                  ? item.is_active
                    ? messageColors.error
                    : messageColors.errorHalfOpacity
                  : null,
              }}
            >
              {item.is_future_value && <span>FV </span>}
              {FormatCurrency(item.amount, currency)}
              <span style={{ textTransform: 'none' }}>
                {item.amount_period_display}
              </span>
            </td>
            <td
              style={{
                width: '10%',
                fontStyle: item.is_starting_now ? 'italic' : null,
              }}
            >
              {preferences !== undefined &&
                item.start_date !== null &&
                DateFormat(item.start_date, preferences.date_format)}
            </td>
            <td style={{ width: '10%' }}>
              {preferences !== undefined &&
                item.end_date !== null &&
                DateFormat(item.end_date, preferences.date_format)}
            </td>
            <td style={{ width: '6%' }}>{item.inflation_rate}%</td>
            <td style={{ width: '6%' }}>
              {item.tax_rate ? item.tax_rate : 0}%
            </td>
            <td
              style={{
                width: '12%',
                textAlign: 'right',
                color: IsNegative(item.capital_value)
                  ? item.is_active
                    ? messageColors.error
                    : messageColors.errorHalfOpacity
                  : null,
              }}
            >
              {FormatCurrency(Math.round(item.capital_value), currency)}
            </td>
            {createActionItems(item, true)}
          </DraggableRow>
        )}
      </Draggable>
    );
  };

  const generateBillingTable = () => {
    return items.map((item, index) => {
      return (
        <TableRow key={index}>
          <td style={{ width: '15%' }}>
            <span
              onClick={() => editFunction(item)}
              style={{
                color: colors.lightBlue,
                cursor: 'pointer',
              }}
            >
              {item.invoice_number}
            </span>
          </td>
          <td style={{ width: '15%' }}>
            {DateFormat(item.invoice_created_at, preferences.date_format)}
          </td>
          <td style={{ width: '15%' }}>
            {DateFormat(item.due_on, preferences.date_format)}
          </td>
          <td style={{ width: '10%', textAlign: 'center' }}>{item.state}</td>
          <td style={{ width: '20%', textAlign: 'center' }}>
            {FormatCurrency(item.total_in_cents / 100, currency, 2)}
          </td>
          <ActionRow>
            <div style={{ paddingRight: '10px' }}>
              <span onClick={() => editFunction(item)}>
                <img
                  style={{ width: '12px' }}
                  src={download}
                  alt="download"
                  data-image={`download-${index}`}
                />
              </span>
            </div>
          </ActionRow>
        </TableRow>
      );
    });
  };

  const generateAlignTable = () => {
    return items.map((item, index) => {
      return (
        <TableRow key={index}>
          <td style={{ width: '15%' }}>{item.category}</td>
          <td style={{ width: '15%' }}>{item.name}</td>
          <td style={{ width: '15%' }}>
            {item.linked ? (
              <span>
                Link to existing{' '}
                <a
                  href={item.linked_member.external_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    cursor: 'pointer',
                    color: colors.lightBlue,
                    fontWeight: fonts.semiBold,
                  }}
                >
                  {item.linked_member.name}
                </a>{' '}
                {item.action}
              </span>
            ) : (
              <span>
                Create <strong>NEW</strong> {item.action}
              </span>
            )}
          </td>
          <ActionRow>
            <div style={{ paddingRight: '5px' }}>
              {item.linked ? (
                <span onClick={() => deleteFunction(item)}>
                  <img
                    style={{ width: '12px' }}
                    src={times}
                    alt="reset"
                    data-image={`clear-${index}`}
                  />
                </span>
              ) : (
                <span onClick={() => editFunction(item)}>
                  <img
                    style={{ width: '14px' }}
                    src={search}
                    alt="search"
                    data-image={`search-${index}`}
                  />
                </span>
              )}
            </div>
          </ActionRow>
        </TableRow>
      );
    });
  };

  const generateNameDisplay = (item) => {
    return (
      <div
        style={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <span>{item.name}</span>
        <a
          href={item.external_url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: 'flex' }}
        >
          <img
            src={launchLink}
            data-image="launch"
            alt="link"
            style={{ width: '10px', marginLeft: '5px' }}
          />
          {item.display_address && (
            <Tooltip
              title={item.display_address}
              position="bottom"
              trigger="mouseenter click"
              followCursor="false"
              delay={0}
              arrow={true}
              arrowSize={'small'}
              animation={'fade'}
              animateFill={false}
              transitionFlip={false}
              size={'small'}
            >
              <img
                src={home}
                alt="address"
                data-image={`home`}
                style={{ width: '10px', marginLeft: '5px' }}
              />
            </Tooltip>
          )}
        </a>
      </div>
    );
  };

  const generateSelectDisplay = (item) => {
    return (
      <td
        style={{
          paddingRight: '10px',
          width: '8%',
        }}
      >
        <div
          style={{
            cursor: 'pointer',
            color: colors.lightBlue,
          }}
          onClick={() => editFunction(item)}
        >
          Select
        </div>
      </td>
    );
  };

  const generateAlignSearchTable = (type) => {
    if (type === 'align_household') {
      return items.map((item, index) => {
        return (
          <TableRow key={index}>
            <td style={{ width: '40%' }}>{generateNameDisplay(item)}</td>
            <td style={{ width: '20%', textAlign: 'center' }}>
              {item.member_count}
            </td>
            <td style={{ width: '20%', textAlign: 'center' }}>
              {DateFormatUTC(item.modified_utc, `${preferences.date_format}`)}
            </td>
            {generateSelectDisplay(item)}
          </TableRow>
        );
      });
    }
    if (type === 'align_member') {
      return items.map((item, index) => {
        return (
          <TableRow key={index}>
            <td style={{ width: '40%' }}>{generateNameDisplay(item)}</td>
            <td style={{ width: '20%', textAlign: 'left' }}>{item.type}</td>
            <td style={{ width: '15%', textAlign: 'center' }}>{item.status}</td>
            <td style={{ width: '20%', textAlign: 'left' }}>
              {item.date_of_birth}
            </td>
            {generateSelectDisplay(item)}
          </TableRow>
        );
      });
    }
    if (type === 'align_entity') {
      return items.map((item, index) => {
        return (
          <TableRow key={index}>
            <td style={{ width: '55%' }}>{generateNameDisplay(item)}</td>
            <td style={{ width: '20%', textAlign: 'left' }}>{item.type}</td>
            <td style={{ width: '15%', textAlign: 'center' }}>{item.status}</td>
            {generateSelectDisplay(item)}
          </TableRow>
        );
      });
    }
  };

  // const createEmptyValue = (type, empty, add) => {
  //   if (empty) {
  //     switch (type) {
  //       case 'income':
  //         return 'no cash flows';
  //       case 'assets':
  //         return 'no assets';
  //       case 'liability':
  //         return 'no liabilities';
  //       case 'insurance':
  //         return 'no insurance';
  //       default:
  //         return 'no ' + type;
  //     }
  //   }
  //   if (add) {
  //     switch (type) {
  //       case 'income':
  //         return 'add cash flow';
  //       case 'assets':
  //         return 'add asset';
  //       case 'liability':
  //         return 'add liability';
  //       case 'insurance':
  //         return 'add insurance';
  //       default:
  //         return 'add ' + type;
  //     }
  //   }
  // };

  const genericTableRow = (index, type = 'financial') => {
    return (
      <TableRow key={index}>
        <td
          style={{
            width: type === 'financial' ? '10%' : '15%',
            fontStyle: 'italic',
            color: colors.paleGrey,
          }}
        >
          <LoadingPlaceholder width={type === 'financial' ? '90px' : '190px'} />
        </td>
        {type === 'financial' && (
          <td style={{ width: '7%' }}>
            <LoadingPlaceholder width="60px" />
          </td>
        )}
        <td style={{ width: type === 'financial' ? '28%' : '20%' }}>
          <LoadingPlaceholder
            width={type === 'financial' ? '120px' : '200px'}
          />
        </td>
        <td style={{ width: type === 'financial' ? '13%' : '15%' }}>
          <LoadingPlaceholder
            width={type === 'financial' ? '100px' : '130px'}
          />
        </td>
        <td style={{ width: type === 'financial' ? '11%' : '15%' }}>
          <LoadingPlaceholder
            width={type === 'financial' ? '110px' : '140px'}
          />
        </td>
        <td style={{ width: type === 'financial' ? '13%' : '9%' }}>
          <LoadingPlaceholder
            width={type === 'financial' ? '70px' : '70px'}
            float={type === 'financial' ? 'right' : null}
            margin={'auto'}
          />
        </td>
        <td style={{ width: '9%' }}>
          <LoadingPlaceholder width="70px" margin="6px auto" />
        </td>
        {type === 'financial' && (
          <td style={{ width: '3%' }}>
            <LoadingPlaceholder width="18px" margin="6px auto" />
          </td>
        )}
        <ActionRow>
          <div style={{ paddingRight: '10px' }}>
            <LoadingPlaceholder width="50px" round={true} />
            {canDelete && <LoadingPlaceholder width="50px" round={true} />}
          </div>
        </ActionRow>
      </TableRow>
    );
  };

  const generateFinancialTables = (type, items) => {
    if (loaded) {
      return items.map((item, index) => {
        return generateFinancialRow(item, index);
      });
    } else {
      return [1, 2, 3, 4].map((index) => {
        return genericTableRow(index);
      });
    }
  };

  const generateLegalTable = (items) => {
    if (loaded) {
      return items.map((item, index) => {
        return generateLegalRow(item, index);
      });
    } else {
      return [1, 2, 3, 4].map((index) => {
        return genericTableRow(index, 'legal');
      });
    }
  };

  const generateTargetMapTables = (type, items) => {
    if (loaded) {
      if (type === 'wants') {
        return (
          <DragDropContext onDragEnd={(e) => onDragEnd(e, type)}>
            <Droppable droppableId="tm-wants-list" type="WANTS">
              {(provided) => (
                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                  {items.map((item, index) => {
                    return generateCashFlowRow(item, index);
                  })}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        );
      }
      if (type === 'haveCashFlow') {
        return (
          <DragDropContext onDragEnd={(e) => onDragEnd(e, type)}>
            <Droppable droppableId="tm-have-income-list" type="HAVE-INCOME">
              {(provided) => (
                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                  {items.map((item, index) => {
                    return generateCashFlowRowAvailable(item, index);
                  })}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        );
      }
      if (type === 'haveAssets') {
        return (
          <DragDropContext onDragEnd={(e) => onDragEnd(e, type)}>
            <Droppable droppableId="tm-have-asset-list" type="HAVE-ASSET">
              {(provided) => (
                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                  {items.map((item, index) => {
                    return generateAssetsAvailable(item, index);
                  })}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        );
      }
      if (type === 'haveInsurance') {
        return (
          <DragDropContext onDragEnd={(e) => onDragEnd(e, type)}>
            <Droppable
              droppableId="tm-have-insurance-list"
              type="HAVE-INSURANCE"
            >
              {(provided) => (
                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                  {items.map((item, index) => {
                    return generateInsuranceAvailable(item, index);
                  })}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        );
      }
    } else {
      return [1, 2, 3].map((item) => {
        return (
          <TableRow key={item}>
            <td style={{ width: '3%' }}>
              <LoadingPlaceholder width="18px" margin="6px auto" />
            </td>
            <td style={{ width: '13%' }}>
              <LoadingPlaceholder width="150px" float="left" />
            </td>
            <td style={{ width: '15%' }}>
              <LoadingPlaceholder width="120px" float="left" />
            </td>
            <td style={{ width: '10%' }}>
              <LoadingPlaceholder width="80px" float="right" />
            </td>
            <td style={{ width: '10%' }}>
              <LoadingPlaceholder width="70px" margin="6px auto" />
            </td>
            <td style={{ width: '10%' }}>
              <LoadingPlaceholder width="70px" margin="6px auto" />
            </td>
            <td style={{ width: '6%' }}>
              <LoadingPlaceholder width="50px" margin="6px auto" />
            </td>
            <td style={{ width: '6%' }}>
              <LoadingPlaceholder
                width="50px"
                center={type !== 'haveAssets'}
                float={type === 'haveAssets' ? 'right' : null}
              />
            </td>
            {type === 'haveCashFlow' && (
              <td style={{ width: '6%' }}>
                <LoadingPlaceholder width="40px" margin="6px auto" />
              </td>
            )}
            {type !== 'haveAssets' && (
              <>
                <td style={{ width: '6%' }}>
                  <LoadingPlaceholder width="50px" margin="6px auto" />
                </td>
                <td style={{ width: '10%' }}>
                  <LoadingPlaceholder width="80px" float="right" />
                </td>
              </>
            )}
            <ActionRow>
              <div style={{ paddingRight: '10px' }}>
                {type === 'wants' && (
                  <LoadingPlaceholder width="50px" round={true} />
                )}
                <LoadingPlaceholder width="50px" round={true} />
                <LoadingPlaceholder width="50px" round={true} />
              </div>
            </ActionRow>
          </TableRow>
        );
      });
    }
  };

  const generateRatio = (amount, memberRatio, isJoint) => {
    let ratio = 1;
    isJoint ? (ratio = 1) : (ratio = memberRatio / 100);
    return Math.round(amount * ratio);
  };

  const getBeneficiaryAmount = (item, member) => {
    const memberMatch = item.beneficiary[member.id];
    if (memberMatch) {
      return memberMatch.amount;
    }
    return 0;
  };

  const isJoint = (item, member) => {
    const memberMatch = item.beneficiary[member.id];
    if (memberMatch) {
      if (memberMatch.type === 'joint') {
        return true;
      }
    }
    return false;
  };

  const generateBeneficiaryRow = (item, index) => {
    return (
      <TableRow key={index}>
        <td style={{ width: '10%', textAlign: 'left' }}>
          <ClickableValue onClick={() => editFunction(item)}>
            {GenerateInstrumentType(item.category, allInstrumentCategories)}
          </ClickableValue>
        </td>
        <td style={{ width: '10%', textAlign: 'left' }}>{item.name}</td>
        <td style={{ width: '10%', textAlign: 'left' }}>
          {item.source}
          {item.is_managed || '*'}
        </td>
        <td style={{ width: '10%' }}>
          {item.members && item.members.length !== 0
            ? generateMembers(item.members, 'owner')
            : '-'}
        </td>
        <td
          style={{
            width: '10%',
            textAlign: 'right',
            color: item.type === 'liability' ? messageColors.error : null,
          }}
        >
          {item.unassigned !== 0 && item.type === 'liability'
            ? `(${FormatCurrency(item.unassigned, currency)})`
            : FormatCurrency(item.unassigned, currency)}
        </td>
        {memberList &&
          memberList.map((member, index) => {
            return (
              <td
                key={index}
                style={{
                  width: `${50 / memberList.length}%`,
                  textAlign: 'right',
                  color:
                    item.type === 'liability'
                      ? messageColors.error
                      : isJoint(item, member)
                        ? colors.paleGrey
                        : null,
                }}
              >
                {getBeneficiaryAmount(item, member) !== 0 &&
                item.type === 'liability'
                  ? `(${FormatCurrency(
                      getBeneficiaryAmount(item, member),
                      currency
                    )})`
                  : FormatCurrency(
                      getBeneficiaryAmount(item, member),
                      currency
                    )}
              </td>
            );
          })}
        <td
          style={{
            textAlign: 'right',
            fontWeight: fonts.semiBold,
            width: '100px',
            minWidth: '100px',
            color: item.type === 'liability' ? messageColors.error : null,
          }}
        >
          {item.amount !== 0 && item.type === 'liability'
            ? `(${FormatCurrency(item.amount, currency)})`
            : FormatCurrency(item.amount, currency)}
        </td>
      </TableRow>
    );
  };

  const generateBeneficiaryTables = (type, items) => {
    if (loaded) {
      return items.map((item, index) => {
        return generateBeneficiaryRow(item, index);
      });
    } else {
      return [1, 2, 3].map((item) => {
        return (
          <TableRow key={item}>
            <td style={{ width: '10%' }}>
              <LoadingPlaceholder width="100px" float="left" />
            </td>
            <td style={{ width: '10%' }}>
              <LoadingPlaceholder width="90px" float="left" />
            </td>
            <td style={{ width: '10%' }}>
              <LoadingPlaceholder width="90px" float="left" />
            </td>
            <td style={{ width: '10%' }}>
              <LoadingPlaceholder width="90px" float="left" />
            </td>
            <td style={{ width: '10%' }}>
              <LoadingPlaceholder width="120px" float="right" />
            </td>
            <td style={{ width: '50%' }}>
              {/* <LoadingPlaceholder width="120px" float="right" /> */}
            </td>
            <td style={{ width: '100px' }}>
              <LoadingPlaceholder width="80px" float="right" />
            </td>
            {/* <td style={{ width: '15%' }}>
              <LoadingPlaceholder width="90px" float="right" />
            </td> */}
          </TableRow>
        );
      });
    }
  };

  const generateMembers = (members, relation) => {
    const filteredMembers = members.filter(
      (member) => member.relation === relation
    );
    return filteredMembers.length !== 0
      ? filteredMembers.map((member, index) => {
          return (
            <LinkedLabel key={index}>
              {member?.member?.name || member?.name}
            </LinkedLabel>
          );
        })
      : '-';
  };

  const generateAssets = (assets) => {
    return assets && assets.length !== 0
      ? assets.map((asset, index) => {
          return (
            asset && (
              <LinkedLabel key={index}>
                {asset.name}
                {/* <span style={{ color: colors.paleGrey }}> {asset.source}</span> */}
                <span> {FormatCurrency(asset.amount, currency)}</span>
              </LinkedLabel>
            )
          );
        })
      : '-';
  };

  const generatePolicyRow = (item, index, type) => {
    return (
      <TableRow key={index}>
        <td style={{ width: '6%', textAlign: 'left' }}>
          {item.name}
          {item.is_managed || '*'}
        </td>
        <td style={{ width: '6%', textAlign: 'left' }}>{item.source}</td>
        <td style={{ width: '4%', textAlign: 'center' }}>
          {preferences !== undefined && item.policy_date !== null
            ? DateFormat(item.policy_date, preferences.date_format)
            : 'N/A'}
        </td>
        <td style={{ width: '5%', textAlign: 'left' }}>
          {item.nickname ? item.nickname : '-'}
        </td>
        <td style={{ width: '12%', textAlign: 'left' }}>
          {item.members && item.members.length !== 0
            ? generateMembers(item.members, 'insured')
            : '-'}
        </td>
        <td style={{ width: '12%', textAlign: 'left' }}>
          {item.members && item.members.length !== 0
            ? generateMembers(item.members, 'owner')
            : '-'}
        </td>
        {type !== 'p&c' ? (
          <td style={{ width: '12%', textAlign: 'left' }}>
            {item.members && item.members.length !== 0
              ? generateMembers(item.members, 'beneficiary')
              : '-'}
          </td>
        ) : (
          <td style={{ width: '12%', textAlign: 'left' }}>
            {item.assets && item.assets.length !== 0
              ? generateAssets(item.assetList)
              : '-'}
          </td>
        )}
        {type !== 'p&c' && (
          <td style={{ width: '12%', textAlign: 'left' }}>
            {item.members && item.members.length !== 0
              ? generateMembers(item.members, 'contingent_beneficiary')
              : '-'}
          </td>
        )}
        {type !== 'p&c' ? (
          <td style={{ width: '6%', textAlign: 'left' }}>
            {GenerateInstrumentType(item.category, allInstrumentCategories)}
          </td>
        ) : (
          <td style={{ width: '6%', textAlign: 'left' }}>
            {item.members && item.members.length !== 0
              ? generateMembers(item.members, 'insured')
              : '-'}
          </td>
        )}
        <td style={{ width: '6%', textAlign: 'right', paddingRight: '10px' }}>
          {FormatCurrency(
            item.planned_premium ? item.planned_premium : 0,
            currency
          )}
        </td>
        <td style={{ width: '6%', textAlign: 'left' }}>
          {TransformInterval(item.planned_premium_interval)}
        </td>
        <td style={{ width: '6%', textAlign: 'center' }}>
          {preferences !== undefined && item.as_of_date !== null
            ? DateFormat(item.as_of_date, preferences.date_format)
            : '-'}
        </td>
        {type === 'life_insurance' ? (
          <td style={{ width: '6%', textAlign: 'right' }}>
            {FormatCurrency(item.cash_value ? item.cash_value : 0, currency)}
          </td>
        ) : type !== 'p&c' ? (
          <td style={{ width: '6%', textAlign: 'right' }}>
            {item.elimination_days ? item.elimination_days : '-'}
          </td>
        ) : null}
        {type === 'life_insurance' ? (
          <td style={{ width: '6%', textAlign: 'right' }}>
            {FormatCurrency(
              item.surrender_value ? item.surrender_value : 0,
              currency
            )}
          </td>
        ) : type !== 'p&c' ? (
          <td style={{ width: '6%', textAlign: 'right' }}>
            {item.benefit_period
              ? `${item.benefit_period} ${item.benefit_period_type}`
              : '-'}
          </td>
        ) : null}

        <td
          style={{
            width: '9%',
            textAlign: 'right',
          }}
        >
          {FormatCurrency(item.amount, currency)}
          <span style={{ textTransform: 'lowercase' }}>
            {item.amount_period_display && item.amount_period_display}
          </span>
        </td>
      </TableRow>
    );
  };

  const generatePolicyTables = (type, items) => {
    if (loaded) {
      return items.map((item, index) => {
        return generatePolicyRow(item, index, type);
      });
    } else {
      return [1, 2, 3].map((item) => {
        return (
          <TableRow key={item}>
            <td style={{ width: '6%' }}>
              <LoadingPlaceholder width="70px" float="left" />
            </td>
            <td style={{ width: '6%' }}>
              <LoadingPlaceholder width="60px" float="left" />
            </td>
            <td style={{ width: '6%' }}>
              <LoadingPlaceholder width="60px" margin="6px auto" />
            </td>
            <td style={{ width: '6%' }}>
              <LoadingPlaceholder width="60px" float="left" />
            </td>
            <td style={{ width: '10%' }}>
              <LoadingPlaceholder width="90px" float="left" />
            </td>
            <td style={{ width: '10%' }}>
              <LoadingPlaceholder width="100px" float="left" />
            </td>
            <td style={{ width: '10%' }}>
              <LoadingPlaceholder width="100px" float="left" />
            </td>
            <td style={{ width: '6%' }}>
              <LoadingPlaceholder width="80px" float="left" />
            </td>
            <td style={{ width: '6%' }}>
              <LoadingPlaceholder width="70px" float="right" />
            </td>
            <td style={{ width: '6%' }}>
              <LoadingPlaceholder width="40px" margin="6px auto" />
            </td>
            <td style={{ width: '6%' }}>
              <LoadingPlaceholder width="50px" margin="6px auto" />
            </td>
            <td style={{ width: '6%' }}>
              <LoadingPlaceholder width="50px" float="right" />
            </td>
            <td style={{ width: '6%' }}>
              <LoadingPlaceholder width="50px" float="right" />
            </td>
            <td style={{ width: '9%' }}>
              <LoadingPlaceholder width="100px" float="right" />
            </td>
          </TableRow>
        );
      });
    }
  };

  const generateMedicalOtherRow = (item, index, type) => {
    return (
      <TableRow key={index}>
        <td style={{ width: '15%', textAlign: 'left' }}>
          {item.name}
          {item.is_managed || '*'}
        </td>
        <td style={{ width: '10%', textAlign: 'left' }}>{item.source}</td>
        <td style={{ width: '10%', textAlign: 'center' }}>
          {preferences !== undefined && item.policy_date !== null
            ? DateFormat(item.policy_date, preferences.date_format)
            : 'N/A'}
        </td>
        <td style={{ width: '18%', textAlign: 'left' }}>
          {item.members && item.members.length !== 0
            ? generateMembers(item.members, 'insured')
            : '-'}
        </td>
        <td style={{ width: '10%', textAlign: 'right', paddingRight: '10px' }}>
          {FormatCurrency(
            item.planned_premium ? item.planned_premium : 0,
            currency
          )}
        </td>
        <td style={{ width: '10%', textAlign: 'left' }}>
          {TransformInterval(item.planned_premium_interval)}
        </td>
        <td style={{ width: '5%', textAlign: 'center' }}>
          {preferences !== undefined && item.as_of_date !== null
            ? DateFormat(item.as_of_date, preferences.date_format)
            : '-'}
        </td>
        <td style={{ width: '10%', textAlign: 'right' }}>
          {FormatCurrency(item.deductible ? item.deductible : 0, currency)}
        </td>
        <td
          style={{
            width: '10%',
            textAlign: 'right',
          }}
        >
          {item.amount ? FormatCurrency(item.amount, currency) : '-'}
          <span style={{ textTransform: 'lowercase' }}>
            {item.amount_period_display && item.amount_period_display}
          </span>
        </td>
      </TableRow>
    );
  };

  const generatePolicyMedicalOtherTables = (type, items) => {
    if (loaded) {
      return items.map((item, index) => {
        return generateMedicalOtherRow(item, index, type);
      });
    } else {
      return [1, 2, 3].map((item) => {
        return (
          <TableRow key={item}>
            <td style={{ width: '15%' }}>
              <LoadingPlaceholder width="110px" float="left" />
            </td>
            <td style={{ width: '10%' }}>
              <LoadingPlaceholder width="80px" float="left" />
            </td>
            <td style={{ width: '10%' }}>
              <LoadingPlaceholder width="60px" margin="6px auto" />
            </td>
            <td style={{ width: '18%' }}>
              <LoadingPlaceholder width="150px" float="left" />
            </td>
            <td style={{ width: '10%' }}>
              <LoadingPlaceholder width="90px" float="right" />
            </td>
            <td style={{ width: '10%' }}>
              <LoadingPlaceholder width="80px" float="left" />
            </td>
            <td style={{ width: '5%' }}>
              <LoadingPlaceholder width="80px" margin="6px auto" />
            </td>
            <td style={{ width: '10%' }}>
              <LoadingPlaceholder width="70px" float="right" />
            </td>
            <td style={{ width: '10%' }}>
              <LoadingPlaceholder width="80px" float="right" />
            </td>
          </TableRow>
        );
      });
    }
  };

  const generatePolicyLegalRow = (item, index) => {
    return (
      <TableRow key={index}>
        <td style={{ width: '12%', textAlign: 'left' }}>
          {item.reference_name}
        </td>
        <td style={{ width: '8%', textAlign: 'left' }}>{item.location}</td>
        <td style={{ width: '10%', textAlign: 'center' }}>
          {preferences !== undefined && item.date_created !== ''
            ? DateFormat(item.date_created, preferences.date_format)
            : 'N/A'}
        </td>
        <td style={{ width: '15%', textAlign: 'left' }}>
          {GenerateLegalType(item.category, legalCategories)}
        </td>
        <td style={{ width: '18%', textAlign: 'left' }}>
          {item.applicable_members && item.applicable_members.length !== 0
            ? item.applicable_members.map((mem) => {
                return generateMemberDisplay(mem, 'member');
              })
            : '-'}
        </td>
        <td style={{ width: '16%', textAlign: 'left' }}>
          {item.empowered_members && item.empowered_members.length !== 0
            ? item.empowered_members.map((mem) => {
                return generateMemberDisplay(mem, 'member');
              })
            : '-'}
        </td>
        <td style={{ width: '16%', textAlign: 'left' }}>
          {item.beneficiary_members && item.beneficiary_members.length !== 0
            ? item.beneficiary_members.map((mem) => {
                return generateMemberDisplay(mem, 'member');
              })
            : '-'}
        </td>
        <td style={{ width: '5%', textAlign: 'right' }}>
          {preferences !== undefined && item.date_collected !== ''
            ? DateFormat(item.date_collected, preferences.date_format)
            : '-'}
        </td>
      </TableRow>
    );
  };

  const generatePolicyLegalTable = (type, items) => {
    if (loaded) {
      return items.map((item, index) => {
        return generatePolicyLegalRow(item, index, type);
      });
    } else {
      return [1, 2, 3].map((item) => {
        return (
          <TableRow key={item}>
            <td style={{ width: '12%' }}>
              <LoadingPlaceholder width="110px" float="left" />
            </td>
            <td style={{ width: '8%' }}>
              <LoadingPlaceholder width="80px" float="left" />
            </td>
            <td style={{ width: '10%' }}>
              <LoadingPlaceholder width="60px" margin="6px auto" />
            </td>
            <td style={{ width: '15%' }}>
              <LoadingPlaceholder width="150px" float="left" />
            </td>
            <td style={{ width: '18%' }}>
              <LoadingPlaceholder width="90px" float="left" />
            </td>
            <td style={{ width: '16%' }}>
              <LoadingPlaceholder width="80px" float="left" />
            </td>
            <td style={{ width: '16%' }}>
              <LoadingPlaceholder width="80px" margin="left" />
            </td>
            <td style={{ width: '5%' }}>
              <LoadingPlaceholder width="70px" float="right" />
            </td>
          </TableRow>
        );
      });
    }
  };

  const generateMemberAmount = (item, member) => {
    const matchingMember = item.members.filter((mem) => mem.id === member.id);
    if (matchingMember.length !== 0) {
      const matchingMemberRelations = matchingMember.map((mem) => mem.relation);
      const ownerMember = matchingMember.find(
        (mem) => mem.relation === 'owner'
      );
      if (item.instrument_type === 'insurance') {
        if (matchingMemberRelations.includes('owner')) {
          return FormatCurrency(
            generateRatio(item.cash_value, ownerMember.ratio),
            currency
          );
          // if (matchingMemberRelations.includes('insured')) {
          //   return FormatCurrency(
          //     generateRatio(item.cash_value, ownerMember.ratio),
          //     currency
          //   );
          // } else if (matchingMemberRelations.includes('owner')) {
          //   return FormatCurrency(
          //     generateRatio(item.cash_value, ownerMember.ratio),
          //     currency
          //   );
        } else {
          return '-';
        }
      } else {
        if (matchingMemberRelations.includes('owner')) {
          return FormatCurrency(
            generateRatio(item.amount, ownerMember.ratio),
            currency
          );
        } else {
          return '-';
        }
      }
    } else {
      return '-';
    }
  };

  const generateBalanceSheetRow = (item, index, type) => {
    return (
      <TableRow key={index}>
        <td
          style={{
            width: '15%',
            textAlign: 'left',
            paddingRight: '8px',
          }}
        >
          {item.name}
        </td>
        <td style={{ width: '15%', textAlign: 'left' }}>{item.source}</td>
        <td style={{ width: '12%', textAlign: 'left' }}>
          {preferences !== undefined && item.as_of_date !== null
            ? DateFormat(item.as_of_date, preferences.date_format)
            : '-'}
        </td>
        {memberList.map((member, index) => {
          const width = 50 / memberList.length;
          return (
            <td
              key={index}
              style={{
                width: `${width}%`,
                textAlign: 'right',
                minWidth: '115px',
                paddingLeft: '8px',
                color:
                  item.instrument_type === 'liability' &&
                  generateMemberAmount(item, member) !== '-'
                    ? messageColors.error
                    : null,
              }}
            >
              {item.instrument_type === 'liability' &&
              generateMemberAmount(item, member) !== '-'
                ? `(${generateMemberAmount(item, member)})`
                : generateMemberAmount(item, member)}
            </td>
          );
        })}
        <td
          style={{
            width: '10%',
            textAlign: 'right',
            color:
              item.instrument_type === 'liability' ? messageColors.error : null,
          }}
        >
          {item.instrument_type === 'liability'
            ? `(${FormatCurrency(
                getMembersRowTotal(item, memberList),
                currency
              )})`
            : FormatCurrency(getMembersRowTotal(item, memberList), currency)}
        </td>
      </TableRow>
    );
  };

  const getMembersRowTotal = (item, selectedMembers) => {
    return selectedMembers.reduce((acc, curr) => {
      const matchingMember = item.members.filter((mem) => mem.id === curr.id);
      // let matchedMember = item.members.find((member) => member.id === curr.id);
      if (matchingMember.length !== 0) {
        const matchingMemberRelations = matchingMember.map(
          (mem) => mem.relation
        );
        const ownerMember = matchingMember.find(
          (mem) => mem.relation === 'owner'
        );
        if (item.instrument_type === 'insurance') {
          if (matchingMemberRelations.includes('owner')) {
            return acc + generateRatio(item.cash_value, ownerMember.ratio);
          } else {
            return acc + 0;
          }
        } else {
          if (matchingMemberRelations.includes('owner')) {
            return acc + generateRatio(item.amount, ownerMember.ratio);
          } else {
            return acc + 0;
          }
        }
      } else {
        return acc + 0;
      }
    }, 0);
  };

  const generateBalanceSheetTables = (type, items) => {
    if (loaded) {
      return items.map((item, index) => {
        return generateBalanceSheetRow(item, index, type);
      });
    } else {
      return [1, 2, 3].map((item) => {
        return (
          <TableRow key={item}>
            <td style={{ width: '15%' }}>
              <LoadingPlaceholder width="100px" float="left" />
            </td>
            <td style={{ width: '8%' }}>
              <LoadingPlaceholder width="80px" float="left" />
            </td>
            <td style={{ width: '8%' }}>
              <LoadingPlaceholder width="60px" float="left" />
            </td>
            <td style={{ width: '40%' }}>
              <LoadingPlaceholder width="200px" float="right" />
            </td>
            <td style={{ width: '15%' }}>
              <LoadingPlaceholder width="90px" float="right" />
            </td>
          </TableRow>
        );
      });
    }
  };

  const createActionItems = (
    item,
    isTargetMap = false,
    hideEdit = false,
    hideDelete = false,
    showMoreMenu = false,
    showClone = false
  ) => {
    return isTargetMap ? (
      <ActionRow>
        <div style={{ paddingRight: '10px' }}>
          <span onClick={() => editFunction(item)}>
            <img src={pencil} alt="edit" data-image="edit" />
          </span>
          {showClone && (
            <span onClick={() => cloneFunction(item)}>
              <img src={copy} alt="clone" />
            </span>
          )}
          {item.instrument === '' ||
          item.instrument === null ||
          item.instrument === undefined ? (
            <span onClick={() => deleteFunction(item)}>
              <img src={trashDark} alt="delete" data-image="delete" />
            </span>
          ) : null}
        </div>
      </ActionRow>
    ) : (
      <ActionRow>
        <div
          style={{
            paddingRight: showMoreMenu ? '0' : '10px',
          }}
        >
          {hideEdit ? null : (
            <span onClick={() => editFunction(item)}>
              <img src={pencil} alt="edit" data-image="edit" />
            </span>
          )}
          {hideDelete ? null : (
            <span onClick={() => deleteFunction(item)}>
              <img src={trashDark} alt="delete" data-image="delete" />
            </span>
          )}
          {showMoreMenu && (
            <span onClick={() => setShowMoreMenu(item.menu_key)}>
              <MoreIcon src={ellipsisH} alt="more" data-image="more" />
            </span>
          )}
        </div>
      </ActionRow>
    );
  };

  const generateLabel = (type) => {
    switch (type) {
      case 'entities':
        return 'Legal Entities';
      case 'members':
        return 'Members';
      case 'professionals':
        return 'Professional Relationships';
      case 'personal':
        return 'Personal Relationships';
      case 'excludedEntities':
        return 'Other Entities';
      case 'income':
        return 'Incomes';
      case 'assets':
        return 'Assets';
      case 'liability':
        return 'Liabilities';
      case 'insurance':
        return 'Insurances';
      case 'wants':
        return 'Cash Flows';
      case 'haveCashFlow':
        return 'Cash Flows available';
      case 'haveAssets':
        return 'Assets available';
      case 'haveInsurance':
        return 'Insurances available';
      case 'incomes_ben':
        return 'Unearned Incomes available';
      case 'assets_ben':
        return 'Assets available';
      case 'liabilities_ben':
        return 'Liabilities available';
      case 'life_insurance_ben':
        return 'Life Insurances available';
      case 'other_insurance_ben':
        return 'Other Insurances available';
      case 'life_insurance':
        return 'Life Insurances available';
      case 'disability':
        return 'Disability Insurances available';
      case 'ltc':
        return 'Longer-term Care Insurances available';
      case 'p&c':
        return 'Property & Casualty Insurances available';
      case 'medical':
        return 'Medical Insurances available';
      case 'other_insurance':
        return 'Other Insurances available';
      case 'legal':
      case 'legal_policy':
        return 'Legals available';
      case 'assets_balance':
        return 'Non-Retirement Assets available';
      case 'liabilities_balance':
        return 'Liabilities available';
      case 'insurance_balance':
        return 'Insurance Cash Values available';
      case 'customer_members':
        return 'Members available';
      case 'customer_delegation':
        return 'Delegates available';
      case 'customer_pending':
        return 'Pending Invites';
      case 'customer_transfers':
        return 'Book of Business Transfers';
      case 'billing':
        return 'Available Invoices';
      case 'customer_stencil':
        return 'Stencils available';
      default:
        return type;
    }
  };

  const generateEmptyLabel = (type) => {
    switch (type) {
      case 'align_household':
        return 'No Relationship Groups found';
      case 'align_member':
        return 'No matching Salesforce contacts';
      case 'align_entity':
        return 'No matching entities found';
      case 'deleted_households':
        return 'No recently deleted households';
      case 'deleted_household_items':
        return 'No recently deleted households items';
      case 'household_sharing':
        return 'No household members to share this household with';
      case 'customer_transfers':
        return 'Transfers allow Team Managers to move books of business (All Households) between their Members.';
      default:
        return type;
    }
  };

  const generateTotal = (instruments) => {
    if (instruments && instruments.length !== 0) {
      let total = instruments.reduce((acc, curr) => {
        if (curr.instrument_type !== 'insurance') {
          let ratio = 1;
          curr.is_joint ? (ratio = 1) : (ratio = curr.member.ratio / 100);
          return acc + curr.amount * ratio;
        } else {
          return acc + curr.amount;
        }
      }, 0);
      return Math.round(total);
    }
    return 0;
  };

  const generateBalanceTotal = (instruments, members) => {
    return instruments.reduce((acc, curr) => {
      return acc + getMembersRowTotal(curr, members);
    }, 0);
  };

  const generatePolicyTotal = (instruments, fieldName) => {
    if (instruments && instruments.length !== 0) {
      let total = instruments.reduce((acc, curr) => {
        return acc + curr[fieldName];
      }, 0);
      return Math.round(total);
    }
    return 0;
  };

  const generatePremiumTotal = (instruments) => {
    if (instruments && instruments.length !== 0) {
      let total = instruments.reduce((acc, curr) => {
        return acc + curr.planned_premium * curr.planned_premium_interval;
      }, 0);
      return Math.round(total);
    }
    return 0;
  };

  const generateDisabilityTotal = (instruments) => {
    if (instruments && instruments.length !== 0) {
      let total = instruments.reduce((acc, curr) => {
        let amount = curr.amount;
        if (curr.amount_period_display === '/dy') {
          amount = (curr.amount * 365) / 12;
        } else if (curr.amount_period_display === '/wk') {
          amount = (curr.amount * 52) / 12;
        } else if (curr.amount_period_display === '/mo') {
          amount = curr.amount;
        } else {
          amount = curr.amount / 12;
        }
        return acc + amount;
      }, 0);
      return Math.round(total);
    }
    return 0;
  };

  const generateLTCTotal = (instruments) => {
    if (instruments && instruments.length !== 0) {
      let total = instruments.reduce((acc, curr) => {
        let amount = curr.amount / 365;
        if (curr.amount_period_display === '/dy') {
          amount = curr.amount;
        } else if (curr.amount_period_display === '/wk') {
          amount *= 52;
        } else if (curr.amount_period_display === '/mo') {
          amount *= 12;
        } else {
          amount = curr.amount / 365;
        }
        return acc + amount;
      }, 0);
      return Math.round(total);
    }
    return 0;
  };

  const generateMemberTotal = (instruments, memberId) => {
    const matchedInstruments = instruments.filter((instrument) => {
      let filteredMem = instrument.members.filter(
        (mem) => mem.relation === 'beneficiary'
      );
      let hasMember = filteredMem.find((mem) => mem.id === memberId);
      if (hasMember) {
        instrument.matchedMem = hasMember;
        return instrument;
      }
    });
    let total = null;
    if (matchedInstruments && matchedInstruments.length !== 0) {
      total = matchedInstruments.reduce((acc, curr) => {
        if (curr.instrument_type !== 'insurance') {
          const total = generateRatio(
            curr.amount,
            curr.member.ratio,
            curr.is_joint
          );
          return acc + total * (curr.matchedMem.ratio / 100);
        } else {
          return acc + curr.amount;
        }
      }, 0);
    }
    if (total !== null) {
      return Math.round(total);
    } else {
      return '';
    }
  };

  const generateMemberTotalBalance = (instruments, memberId) => {
    const matchedInstruments = instruments.filter((instrument) => {
      let filteredMem = instrument.members.filter(
        (mem) => mem.relation === 'owner'
      );
      let hasMember = filteredMem.find((mem) => mem.id === memberId);
      if (hasMember) {
        instrument.matchedMem = hasMember;
        return instrument;
      }
    });
    let total = null;
    if (matchedInstruments && matchedInstruments.length !== 0) {
      total = matchedInstruments.reduce((acc, curr) => {
        const matchingMember = curr.members.filter(
          (mem) => mem.id === memberId
        );
        const matchingOwner = matchingMember.find(
          (mem) => mem.relation === 'owner'
        );
        if (curr.instrument_type !== 'insurance') {
          return acc + generateRatio(curr.amount, matchingOwner.ratio);
        } else {
          return acc + generateRatio(curr.cash_value, matchingOwner.ratio);
        }
      }, 0);
    }
    if (total !== null) {
      return Math.round(total);
    } else {
      return 0;
    }
  };

  const calculateTotal = (heading, items) => {
    if (heading.value === 'total') {
      return generateTotal(items);
    }
    if (heading.value === 'total_balance') {
      return generateBalanceTotal(items, memberList);
    }

    if (heading.value === 'member') {
      return generateMemberTotal(items, heading.id);
    }
    if (heading.value === 'member_balance') {
      return generateMemberTotalBalance(items, heading.id);
    }
    if (
      heading.value === 'amount' ||
      heading.value === 'surrender_value' ||
      heading.value === 'cash_value'
    ) {
      return generatePolicyTotal(items, heading.value);
    }
    if (heading.value === 'prem_mode') {
      return '/ yr';
    }
    if (heading.value === 'premium') {
      return generatePremiumTotal(items);
    }
    if (heading.value === 'disability_total') {
      return generateDisabilityTotal(items);
    }
    if (heading.value === 'ltc_total') {
      return generateLTCTotal(items);
    }
    return 'total';
  };

  const generateTotalRowLabel = (items) => {
    if (items[0].instrument_type === 'liability') {
      return 'Total Liabilities';
    }
    if (items[0].instrument_type === 'insurance') {
      return 'Total Insurance Cash Value';
    }
    if (items[0].category === 'retirement') {
      return 'Total Retirement Assets';
    }
    if (items[0].category === 'nonretirement') {
      return 'Total Non-Retirement Assets';
    }
    if (items[0].category === 'residence') {
      return 'Total Real Estate';
    }
    return 'name';
  };

  const generateRowTotals = (heading, items) => {
    if (heading.value === 'name') {
      return (
        <span
          style={{
            color: inputColors.label,
            textTransform: 'capitalize',
            fontStyle: 'italic',
            // paddingLeft: '5px',
          }}
        >
          {generateTotalRowLabel(items)}
        </span>
      );
    } else {
      if (heading.value === 'prem_mode') {
        return '/ yr';
      }
      return negativeTotals
        ? `(${FormatCurrency(calculateTotal(heading, items), currency)})`
        : FormatCurrency(calculateTotal(heading, items), currency);
    }
  };

  const transformTotalValue = (heading) => {
    if (heading.value === 'total' || heading.value === 'unassigned') {
      return totalsObj[heading.value];
    } else {
      if (totalsObj.hasOwnProperty('member')) {
        const matching = totalsObj.member[heading.id];
        if (matching) {
          return matching;
        }
      }
    }
    return 0;
  };

  return (
    <TableContainer style={{ marginBottom: marginBottom }}>
      {tableHeading && (
        <TableHeader>
          <TableHeading>{tableHeading}</TableHeading>
          {items && items.length !== 0 && headingButton && (
            <Button
              text={headingButton.text}
              bigButton={false}
              onClick={headingButton.function}
              qaAttribute={headingButton.text
                .toLowerCase()
                .replace(/\s+/g, '-')}
            />
          )}
        </TableHeader>
      )}
      <TableContent>
        <thead style={type === 'wants' ? { textAlign: 'center' } : null}>
          <TableHeaderRow>
            {columnHeadings.map((heading, index) => {
              return (
                <th
                  key={index}
                  onClick={
                    heading.sortable ? () => heading.onClick(heading) : null
                  }
                  style={{
                    textAlign: heading.align ? heading.align : 'left',
                    width: heading.width ? heading.width : null,
                    minWidth: heading.minWidth ? heading.minWidth : null,
                    padding: heading.padding ? heading.padding : null,
                    cursor: heading.sortable ? 'pointer' : null,
                  }}
                >
                  {heading.active ? (
                    <HeadingSort
                      style={{
                        justifyContent:
                          heading.align === 'center' ? 'center' : 'flex-start',
                      }}
                    >
                      {heading.display && heading.text}
                      {heading.active &&
                        (heading.ascending ? (
                          <AscendingIcon
                            src={chevronDown}
                            alt="asc"
                            data-image={`${heading.value}-arrow-asc`}
                          />
                        ) : (
                          <DescendingIcon
                            src={chevronDown}
                            alt="desc"
                            data-image={`${heading.value}-arrow-desc`}
                          />
                        ))}
                    </HeadingSort>
                  ) : heading.display ? (
                    heading.text
                  ) : null}
                </th>
              );
            })}
          </TableHeaderRow>
        </thead>
        {items && dragAndDrop ? (
          generateTableBody(type, items)
        ) : (
          <tbody>
            {items && !dragAndDrop && generateTableBody(type, items)}
            {items && items.length !== 0 && showTotals && (
              <tr>
                {columnHeadings.map((heading, index) => {
                  return (
                    <td
                      key={index}
                      style={{
                        textAlign: heading.align ? heading.align : 'left',
                        width: heading.width ? heading.width : null,
                        minWidth: heading.minWidth ? heading.minWidth : null,
                        color: negativeTotals ? messageColors.error : null,
                        borderBottom: 'none',
                        fontWeight: fonts.semiBold,
                        padding:
                          index === columnHeadings.length - 1
                            ? '0 5px 0 0'
                            : heading.padding
                              ? heading.padding
                              : null,
                      }}
                    >
                      {heading.showTotal && generateRowTotals(heading, items)}
                      {heading.totalAppend && (
                        <span style={{ textTransform: 'lowercase' }}>
                          {heading.totalAppend}
                        </span>
                      )}
                    </td>
                  );
                })}
              </tr>
            )}
            {totalsObj &&
            totalsObj.hasOwnProperty('total') &&
            items &&
            items.length !== 0 ? (
              <tr>
                {columnHeadings.map((heading, index) => {
                  return (
                    <td
                      key={index}
                      style={{
                        textAlign: heading.align ? heading.align : 'left',
                        width: heading.width ? heading.width : null,
                        minWidth: heading.minWidth ? heading.minWidth : null,
                        color: negativeTotals ? messageColors.error : null,
                        borderBottom: 'none',
                        fontWeight: fonts.semiBold,
                        // textTransform: 'lowercase',
                        padding:
                          index === columnHeadings.length - 1
                            ? '0 5px 0 0'
                            : heading.padding
                              ? heading.padding
                              : null,
                      }}
                    >
                      {heading.showTotal && transformTotalValue(heading) !== 0
                        ? negativeTotals
                          ? `(${FormatCurrency(
                              transformTotalValue(heading),
                              currency
                            )})`
                          : FormatCurrency(
                              transformTotalValue(heading),
                              currency
                            )
                        : null}
                    </td>
                  );
                })}
              </tr>
            ) : null}
          </tbody>
        )}
      </TableContent>
      {/* {total ? <TotalRow> {FormatCurrency(total, currency)}</TotalRow> : null} */}
      {items && items.length === 0 && loaded && (
        <EmptyDisplay>
          <EmptyText>
            {customEmptyResults ? (
              generateEmptyLabel(type)
            ) : (
              <>
                There are no {generateLabel(type)}
                {isHousehold ? ' for this household.' : '.'}
              </>
            )}
          </EmptyText>
          {headingButton && headingButton.text && (
            <Button
              text={headingButton.text}
              bigButton={false}
              onClick={headingButton.function}
              qaAttribute={headingButton.text
                .toLowerCase()
                .replace(/\s+/g, '-')}
            />
          )}
        </EmptyDisplay>
      )}
    </TableContainer>
  );
};

const EmptyDisplay = styled.div`
  @media ${maxDevice.sideNav} {
    font-size: 11px;
    button {
      font-size: 11px;
      padding: 8px 10px;
    }
  }
  width: 100%;
  padding: 15px 0;
  text-align: center;
  font-size: 13px;
  background: ${colors.lighterGrey};
`;

const EmptyText = styled.p`
  margin-bottom: 10px;
`;

const TableContainer = styled.div`
  margin-bottom: 50px;
`;

const TableHeaderRow = styled.tr`
  th {
    &:first-child {
      padding-left: 5px;
    }
    &:last-child {
      padding-right: 5px;
    }
  }
`;

const DraggableRow = styled.tr`
  text-align: center;
  background: ${(props) =>
    props.matchesSearch
      ? colors.paleBlue
      : props.active
        ? colors.white
        : colors.lighterGrey};
  color: ${(props) => (props.active ? colors.darkGrey : colors.paleGrey)};
  font-size: 10px;
`;

const TableHeader = styled.div`
  @media ${maxDevice.sideNav} {
    button {
      font-size: 11px;
      padding: 8px 10px;
    }
  }
  margin-bottom: 10px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

const TableHeading = styled.h3`
  @media ${maxDevice.mobileL} {
    font-size: 16px;
  }
  flex: 1 1 auto;
  font-size: 18px;
  font-weight: ${fonts.semiBold};
`;

const TableContent = styled.table`
  width: 100%;
  thead {
    /* border-bottom: 1.5px solid black; */
    border-bottom: 1px solid ${colors.darkGrey};
    text-align: left;
    font-weight: ${fonts.semiBold};
    th {
      @media ${maxDevice.tablet} {
        font-size: 10px;
      }
      padding: 0 5px 5px 5px;
      font-size: 14px;
      vertical-align: bottom;
    }
  }
  tbody {
    margin-top: 10px;
    td {
      @media ${maxDevice.tablet} {
        font-size: 10px;
      }
      padding: 6px 5px;
      text-transform: capitalize;
      font-size: 13px;
      border-bottom: 1px solid ${colors.lightGrey};
      vertical-align: middle;
    }
  }
`;

const ActionRow = styled.td`
  width: ${(props) => (props.width ? props.width : '5%')};
  div {
    display: flex !important;
    align-content: center;
    align-items: center;
    justify-content: flex-end;
    span {
      width: 30px;
      height: 30px;
      display: inline-block;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      margin-left: 5px;
      cursor: pointer;
      &:hover {
        background: ${colors.lightGrey};
        border-radius: 25px;
      }
    }
    img {
      width: 14px;
    }
  }
`;

const MoreIcon = styled.img`
  transform: rotate(90deg);
`;

const LinkIcon = styled.img`
  width: 11px;
  height: 11px;
  margin-right: 8px;
`;

const TableRow = styled.tr`
  position: relative;
  &:hover {
    background: ${colors.lighterGrey};
  }
  a {
    color: ${colors.lightBlue};
  }
`;

const ClickableValue = styled.span`
  cursor: pointer;
  color: ${colors.lightBlue};
  &:hover {
    color: ${colors.blue};
  }
`;

const CheckBoxClick = styled.span`
  cursor: pointer;
`;

const CheckBoxContainer = styled.span`
  cursor: pointer;
  margin-left: 7px;
  display: flex;
  width: 12px;
`;

const CheckBoxImage = styled.img`
  width: 12px;
  height: 12px;
`;

const CheckBoxNullImage = styled.img`
  width: 8px;
  height: 8px;
  opacity: 0.5;
`;

const HeadingSort = styled.span`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
`;

const DescendingIcon = styled.img`
  max-width: 15px;
`;
const AscendingIcon = styled(DescendingIcon)`
  transform: rotate(180deg);
`;

const LinkedLabel = styled.span`
  font-size: 11px;
  display: inline-block;
  background: ${colors.lightGrey};
  margin-right: 5px;
  margin-bottom: 3px;
  padding: 3px 5px;
  border-radius: 2px;
`;

const MemberAge = styled.span`
  margin-left: 4px;
  color: ${colors.paleGrey};
  font-size: 10px;
`;

// const PermissionBoxContainer = styled.div`
//   background: ${colors.lightGrey};
//   display: inline-block;
//   padding: 4px 8px;
//   border-radius: 2px;
//   font-size: 11px;
//   margin: 2px 5px 2px 0;
// `;

// const PermissionBox = styled.div`
//   display: flex;
//   align-content: center;
//   align-items: center;
//   justify-content: flex-start;
// `;

// const PermissionIcon = styled.img`
//   width: 12px;
//   height: 12px;
// `;

// const PermissionType = styled.span`
//   margin-left: 6px;
//   text-transform: capitalize;
//   font-size: 10px;
//   color: ${colors.darkGrey};
// `;

// const PermissionValue = styled.span`
//   margin: 1px 0 0 6px;
//   text-transform: uppercase;
//   font-weight: ${fonts.semiBold};
//   font-size: 8px;
//   color: ${colors.paleGrey};
// `;

const SpinnerContainer = styled.div`
  margin: 0 auto;
  width: 25px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

const ImagePreviewIcon = styled.img`
  margin-right: 15px;
  vertical-align: middle;
  opacity: 0.6;
  cursor: pointer;
`;

// const Spinner = styled.div`
//   margin: -2px 0 0 0;
//   width: 25px;
//   text-align: center;
//   div {
//     width: 6px;
//     height: 6px;
//     background-color: ${colors.paleGrey};
//     border-radius: 100%;
//     display: inline-block;
//     animation: ${bounceDelay} 1s infinite ease-in-out both;
//   }
//   ${Bounce1} {
//     animation-delay: -0.32s;
//   }
//   ${Bounce2} {
//     animation-delay: -0.16s;
//   }
// `;

SmallTable.propTypes = {
  tableHeading: PropTypes.string,
  headingButton: PropTypes.object,
  columnHeadings: PropTypes.array,
  items: PropTypes.array,
  loaded: PropTypes.bool,
  showTotals: PropTypes.bool,
  totalsObj: PropTypes.object,
  negativeTotals: PropTypes.bool,
  memberList: PropTypes.array,
  deleteFunction: PropTypes.func,
  editFunction: PropTypes.func,
  cloneFunction: PropTypes.func,
  toggleFunction: PropTypes.func,
  moreActionFunction: PropTypes.func,
  type: PropTypes.string,
  activeColor: PropTypes.string,
  marginBottom: PropTypes.string,
  isHousehold: PropTypes.bool,
  customEmptyResults: PropTypes.bool,
  showEdit: PropTypes.bool,
  dragAndDrop: PropTypes.bool,
  onDragEnd: PropTypes.func,
  actionsList: PropTypes.array,
};

export default SmallTable;
