export const colors = {
  darkGrey: '#3F4444',
  blue: '#004C97',
  lightBlue: '#3EB1C8',
  green: '#78BE20',
  pink: '#E24585',
  orange: '#DE7C00',
  paleGrey: '#B2B4B2',
  midGrey: '#BDBDBD',
  wedge: '#4E87A0',
  rock: '#8E9FBC',
  teal: '#279989',
  rose: '#B04A5A',
  palePink: '#D6938A',
  avocado: '#899064',
  golden: '#FFC56E',
  white: '#FFFFFF',
  lightGrey: 'rgb(238, 238, 238)',
  hoverLightGrey: 'rgb(230, 230, 230)',
  lighterGrey: 'rgb(250, 250, 250)',
  hoverLighterGrey: 'rgb(245, 245, 245)',
  paleBlue: '#ecf6ff',
};

export const boxShadows = {
  boxShadow: '0px 0px 8px 0px rgb(206, 206, 206)',
  boxShadowSoft: '0px 0px 8px 0px rgb(230, 230, 230)',
  boxShadowBottom: '0px 2px 6px 0px rgb(230, 230, 230)',
  boxShadowLight: '0px 0px 8px 0px rgb(240, 240, 240)',
  boxShadowNavbar: '0px 1px 8px 0px rgb(217, 217, 217)',
  boxShadowNavbarAlt: '0px 1px 8px 0px rgb(104 104 104 / 30%)',
  boxShadowSideNav: '0px 0px 3px rgb(226,226, 226)',
  boxShadowDropdown: '0px 0px 8px 0px rgba(0, 0, 0, .2)',
  boxShadowAMRSelected: '0px 0px 8px 0px rgb(210, 210, 210)',
  boxShadowAMRHiddenSelected: '0px 0px 8px 0px rgba(88,88,88)',
  boxShadowModal: '0px 0px 5px 1px rgba(0, 0, 0, 0.2)',
  boxShadowModalTab: '0px -10px 10px -2px rgba(0,0,0,.3)',
  boxShadowTargetMapTab: '0px -5px 10px -2px rgba(0,0,0,.05)',
};

export const assetMapColors = {
  cashFlow: '#3EB1C8',
  realEstate: '#8E9FBC',
  retirement: '#E24585',
  nonRetirement: '#78BE20',
  liability: '#DE7C00',
  insurance: '#004C97',
  otherOwner2: '#fcff9d',
  otherOwner: 'rgba(255,254,224,.75)',
  entityOwner: 'rgba(225,225,255,.75)',
  stencil: 'rgb(215,215,215)',
  expected: '#8b8e8e',
  connector: '#b8b8b8',
  paleBlueHidden: '#d0e9ff',
  rollupParent: '#EDFCFF',
  draft: '#FFEEF7',
};

export const targetMapColors = {
  death: '#4d6df3',
  disability: '#ff972f',
  ltc: '#b5a5d5',
  education: '#f7de10',
  retirement: '#42c78a',
  custom: '#6c6678',
  user: '#4E87A0',
  other: '#279989',
  red: '#EA1310',
  orange: '#FF8A00',
  yellow: '#FFCC16',
  green: '#78BE1F',
};

export const buttonColors = {
  primary: colors.green,
  secondary: colors.lightBlue,
  inactive: colors.paleGrey,
  alt: colors.blue,
};

export const inputColors = {
  border: '#cccccc',
  label: '#666666',
  background: colors.lightGrey,
  focus: colors.darkGrey,
  focusBorder: '#aaacaa',
  updated: '#ffffed',
  primary25: '#e7f3ff',
  primary50: '#a5d2ff',
  primary75: '#4ba5ff',
};

export const messageColors = {
  error: '#eb4d4b',
  errorHalfOpacity: 'rgba(235, 77, 75, .5)',
  errorBackground: '#fdeded',
  success: '#78BE20',
  successBackground: '#fcfefa',
  modalSuccessBackground: '#f0ffe1',
  info: '#004C97',
  loading: '#3EB1C8',
  warning: '#FFC56E',
  warningLabelBg: '#FFF3E2',
  successLabelBg: '#E4F2D2',
  errorLabelBg: '#FFDFDD',
};

export const signalColors = {
  red: '#ff5f57',
  green: '#78BE20',
  yellow: '#f3be4f',
  snoozedOld: '#633bda',
  snoozed: colors.paleGrey,
  none: 'rgb(238, 238, 238)',
};

export const toastColors = {
  success: '#6EC05F',
  info: '#1271EC',
  warn: '#FED953',
  error: '#D60A2E',
  loading: '#0088ff',
};

export const heights = {
  navBar: '55px',
  navBarHeading: '45px',
};

export const sidebar = {
  appBarWidth: '55px',
  appContentWidth: '320px',
  appContentWidthWide: '435px',
};

export const colorsRGB = {
  darkGrey: 'rgb(63, 68, 68)',
  paleGrey: 'rgb(178, 180, 178)',
  green: 'rgb(120, 190, 32)',
  teal: 'rgb(39, 153, 137)',
  pink: 'rgb(226, 69, 133)',
  palePink: 'rgb(214, 147, 138)',
  orange: 'rgb(222, 124, 0)',
  golden: 'rgb(255, 197, 110)',
  blue: 'rgb(0, 76, 151)',
  lightBlue: 'rgb(62, 177, 200)',
  wedge: 'rgb(78, 135, 160)',
  rock: 'rgb(142, 159, 188)',
};

export const fonts = {
  primaryHeading: '24px',
  secondaryHeading: '18px',
  tertiaryHeading: '16px',
  subGroupHeading: '14px',
  primaryText: '15px',
  buttonText: '12px',
  error: '12px',
  success: '12px',
  small: '11px',
  primaryColor: colors.darkGrey,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
};

const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  sideNav: '560px',
  tablet: '812px',
  laptop: '1024px',
  laptopAlt: '1220px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const minDevice = {
  mobileS: `(min-width: ${sizes.mobileS})`,
  mobileM: `(min-width: ${sizes.mobileM})`,
  mobileL: `(min-width: ${sizes.mobileL})`,
  tablet: `(min-width: ${sizes.tablet})`,
  laptop: `(min-width: ${sizes.laptop})`,
  laptopAlt: `(min-width: ${sizes.laptopAlt})`,
  laptopL: `(min-width: ${sizes.laptopL})`,
  desktop: `(min-width: ${sizes.desktop})`,
  desktopL: `(min-width: ${sizes.desktop})`,
};

export const maxDevice = {
  mobileS: `(max-width: ${sizes.mobileS})`,
  mobileM: `(max-width: ${sizes.mobileM})`,
  mobileL: `(max-width: ${sizes.mobileL})`,
  sideNav: `(max-width: ${sizes.sideNav})`,
  tablet: `(max-width: ${sizes.tablet})`,
  laptop: `(max-width: ${sizes.laptop})`,
  laptopAlt: `(max-width: ${sizes.laptopAlt})`,
  laptopL: `(max-width: ${sizes.laptopL})`,
  desktop: `(max-width: ${sizes.desktop})`,
  desktopL: `(max-width: ${sizes.desktop})`,
};
