import styled from 'styled-components';
import { boxShadows, colors, fonts, maxDevice } from '../../styles/variables';

export const CenterModal = styled.div`
  @media ${maxDevice.tablet} {
    box-shadow: none;
    border: none;
    /* margin-top: -40%; */
  }
  margin: 20vh auto;
  /* margin-top: -200px; */
  width: 100%;
  min-width: ${(props) => (props.width ? props.width : '400px')};
  max-width: ${(props) => (props.width ? props.width : '400px')};
  box-shadow: ${boxShadows.boxShadowModal};
  border-radius: 6px;
  background-color: ${colors.white};
  color: ${colors.darkGrey};
`;

export const ModalHeader = styled.div`
  @media ${maxDevice.tablet} {
    background: none;
    padding-bottom: 5px;
  }
  text-align: center;
  background-color: ${colors.darkGrey};
  padding: 15px 20px;
  border-radius: 6px 6px 0 0;
`;

export const ModalHeadingConfig = styled.h2`
  @media ${maxDevice.tablet} {
    color: ${colors.darkGrey};
    font-size: ${fonts.primaryHeading};
  }
  color: ${colors.white};
  font-size: ${fonts.secondaryHeading};
  font-weight: ${fonts.semiBold};
`;

export const ModalContent = styled.div`
  padding: 0 20px 20px 20px;
`;

export const TabContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const ModalIcon = styled.img`
  width: 16px;
  height: 16px;
  flex: 0 0 auto;
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.$disabled ? 0.5 : 1)};
  margin-right: 15px;
  &:hover {
    transform: ${(props) => (props.$disabled ? null : 'scale(1.01)')};
    opacity: ${(props) => (props.$disabled ? 0.5 : 0.8)};
  }
`;

// export const ErrorMessageContainer = styled.div`
//   font-size: 12px;
//   text-align: left;
//   color: ${messageColors.error};
// `;

export const ModalBackground = styled.div`
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9995 !important;
  visibility: ${(props) => (props.invisible ? 'hidden' : 'visible')};
`;

export const ModalOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9998 !important;
  overflow: auto;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  font-size: 14px;
  visibility: ${(props) => (props.invisible ? 'hidden' : 'visible')};
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

export const ModalContainer = styled.div`
  @media ${maxDevice.sideNav} {
    width: 100%;
    margin: 0;
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    border-radius: 0;
  }
  min-width: 470px;
  max-width: 470px;
  margin: 8vh 20px 20px 20px;
  background: white;
  border-radius: 7px 7px 6px 6px;
  text-align: center;
  z-index: 9999 !important;
  color: ${colors.darkGrey};
`;

export const ModalLoadingOverlayContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const ModalLoadingOverlayContainerAbsolute = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
`;

export const LoadingOverlayContainer = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  z-index: 100;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 6px 6px;
`;

export const LoadingOverlay = styled.div`
  transform: scale(0.7);
  margin-top: -50px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  /* background: white; */
  /* background: rgba(255, 255, 255, 0.7); */
  /* padding: 0px 20px 0 10px; */
  border-radius: 50px;
  p {
    margin-left: 10px;
    font-weight: bold;
    font-size: 16px;
  }
`;

export const SmallerModalContainer = styled.div`
  @media ${maxDevice.sideNav} {
    width: 100%;
    margin: 0;
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    border-radius: 0;
  }
  min-width: 400px;
  max-width: 400px;
  margin: 8vh 20px 20px 20px;
  background: white;
  border-radius: 7px 7px 6px 6px;
  text-align: center;
  z-index: 9999 !important;
  color: ${colors.darkGrey};
`;

export const ModalHeadingContainer = styled.div`
  @media ${maxDevice.sideNav} {
    border-radius: 0;
  }
  padding: 12px 22px;
  background: ${colors.darkGrey};
  border-radius: 6px 6px 0 0;
  position: relative;
  z-index: 11;
`;

export const ModalHeading = styled.h3`
  font-weight: ${fonts.semiBold};
  background: ${colors.darkGrey};
  color: ${colors.white};
  font-size: 15px;
`;

export const CloseButton = styled.img`
  width: 10px;
  height: 10px;
  position: absolute;
  right: 15px;
  top: 18px;
  cursor: pointer;
`;

export const ModalContentContainer = styled.div`
  padding: 15px 20px;
  min-height: ${(props) => (props.$minHeight ? props.$minHeight : null)};
`;

export const SelectDropdownOption = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  margin-left: -5px;
  input {
    flex: 0 0 auto;
  }
  label {
    flex: 1 1 auto;
    margin-left: 8px;
    text-align: left;
    width: 220px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ButtonContainer = styled.div`
  flex: 1 1 auto;
  text-align: right;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  button {
    flex: ${(props) => (props.flex ? props.flex : null)};
    width: ${(props) => (props.width ? props.width : 'auto')};
    &:last-child {
      margin-left: 15px;
    }
    &:last-child {
      width: ${(props) =>
        props.primaryButtonWidth ? props.primaryButtonWidth : '120px'};
    }
  }
`;

export const ButtonsRowContainer = styled.div`
  padding: ${(props) => (props.$padding ? props.$padding : '10px 0 0 0')};
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

export const ButtonsColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
`;

export const SSOContainer = styled.div`
  margin: 20px 0;
  text-align: center;
  a {
    color: ${colors.lightBlue};
  }
`;

export const ModalMainText = styled.p`
  font-size: 14px;
`;

export const ModalDetailText = styled.p`
  margin-top: 10px;
  font-size: 12px;
`;
