/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {
  GenerateInput,
  ModalTabs,
  ModalButtons,
  Error,
  IntegrationTab,
  LoadingAnimation,
  DetectModalKeys,
  ProposalsTab,
} from '..';
import { timesWhite } from '../../assets';
import {
  ModalBackground,
  ModalOverlay,
  ModalContainer,
  ModalContentContainer,
  ModalHeadingContainer,
  ModalLoadingOverlayContainer,
  LoadingOverlayContainer,
  LoadingOverlay,
  ModalHeading,
  CloseButton,
  TabContent,
} from '../../styles/library/modalStyles';

const InstrumentModal = ({
  name,
  isLoading,
  invisible,
  hide,
  isNew,
  viewOnly = false,
  showProposalMode,
  modalTabOptions,
  activeTab,
  setActiveTab,
  currency,
  household,
  essentialInputs,
  detailInputs,
  notesInput,
  onEnter,
  buttonContent,
  modalError,
  errorTitle,
  integrationContent,
  proposalContent,
}) => {
  const ref = useRef();

  return ReactDOM.createPortal(
    <>
      <DetectModalKeys onEnter={onEnter} onEsc={hide} />
      <ModalBackground invisible={invisible} />
      <ModalOverlay invisible={invisible}>
        <ModalContainer role="dialog" ref={ref}>
          <ModalHeadingContainer>
            <ModalHeading>
              {viewOnly
                ? `${name} Details`
                : isNew
                  ? `Add ${name}`
                  : `Edit ${name}`}
            </ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hide()}
              alt="close"
              data-image="close"
            />
          </ModalHeadingContainer>
          <ModalLoadingOverlayContainer>
            {isLoading && !isNew && (
              <LoadingOverlayContainer>
                <LoadingOverlay>
                  <LoadingAnimation />
                </LoadingOverlay>
              </LoadingOverlayContainer>
            )}
            {modalTabOptions?.length && (
              <ModalTabs
                tabs={modalTabOptions}
                activeTab={activeTab}
                onClick={setActiveTab}
              />
            )}
            <ModalContentContainer>
              {activeTab === 'essentials' ? (
                <TabContent>
                  {essentialInputs.map((input, index) => {
                    return GenerateInput(input, index, currency, viewOnly);
                  })}
                </TabContent>
              ) : activeTab === 'details' ? (
                <TabContent>
                  {detailInputs.map((input, index) => {
                    return GenerateInput(input, index, currency, viewOnly);
                  })}
                </TabContent>
              ) : activeTab === 'notes' ? (
                <TabContent>{GenerateInput(notesInput, 0)}</TabContent>
              ) : activeTab === 'proposals' ? (
                <ProposalsTab
                  proposalContent={proposalContent}
                  hasError={modalError && modalError !== ''}
                />
              ) : (
                <IntegrationTab
                  content={integrationContent.content}
                  loading={integrationContent.loading}
                  integration={integrationContent.integration}
                  instrument={integrationContent.instrument}
                  household={household}
                  error={integrationContent.error}
                  type={name}
                  lastHouseholdSync={integrationContent.lastHouseholdSync}
                />
              )}
              {viewOnly || activeTab === 'proposals' || (
                <ModalButtons
                  isNew={isNew}
                  showCopy={!isNew && !showProposalMode}
                  hide={hide}
                  isHidden={buttonContent.isHidden}
                  justCopied={buttonContent.justCopied}
                  updateHidden={
                    showProposalMode ? null : buttonContent.updateHidden
                  }
                  copyFunction={buttonContent.copyFunction}
                  deleteFunction={buttonContent.deleteFunction}
                  saveFunction={buttonContent.saveFunction}
                  showLoading={buttonContent.showLoading}
                  loadingText={buttonContent.loadingText}
                  showSync={buttonContent.showSync}
                  syncFunction={buttonContent.syncFunction}
                  isSyncing={buttonContent.isSyncing}
                />
              )}
            </ModalContentContainer>
          </ModalLoadingOverlayContainer>
          {modalError && modalError !== '' && (
            <Error errorMessage={modalError} title={errorTitle} />
          )}
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

InstrumentModal.propTypes = {
  name: PropTypes.string,
  isLoading: PropTypes.bool,
  invisible: PropTypes.bool,
  hide: PropTypes.func,
  isNew: PropTypes.bool,
  viewOnly: PropTypes.bool,
  showProposalMode: PropTypes.bool,
  modalTabOptions: PropTypes.array,
  defaultTab: PropTypes.string,
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
  onEnter: PropTypes.func,
  household: PropTypes.object,
  currency: PropTypes.object,
  essentialInputs: PropTypes.array,
  detailInputs: PropTypes.array,
  notesInput: PropTypes.object,
  buttonContent: PropTypes.object,
  modalError: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  errorTitle: PropTypes.string,
  integrationContent: PropTypes.object,
  proposalContent: PropTypes.object,
};

export default InstrumentModal;
