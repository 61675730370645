import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import PropTypes from 'prop-types';
import {
  AssetMapCreateSidebar,
  AssetMapModalsSidebar,
  CalculatorSidebar,
  LegalsListSidebar,
  NotesSidebar,
  SignalsSidebar,
  StencilsSidebar,
  TargetMapSidebar,
} from '.';
import { chevronRight, launchLink, notFound, timesPaleGrey } from '../assets';
import {
  colors,
  boxShadows,
  heights,
  sidebar,
  fonts,
} from '../styles/variables';
import { LoadingPlaceholder } from '../styles/library/fontStyles';
import { FlexCenterAll, FlexCenterStart } from '../styles/library/layoutStyles';
import {
  EmptyContainer,
  NotFoundImage,
  NotFoundText,
} from '../styles/library/sidebarStyles';
import { SendTrackingEvent } from '../utils';

const Sidebar = ({
  trayWidth,
  showingAppBar,
  sidebarApps,
  selectedApp,
  activeItemDetails,
  appOnClick,
  showAppView,
  trayArrowOnClick,
  trayCloseOnClick,
  currency,
  householdId,
  invocationId,
  currentHousehold,
  updatedInstrument,
  updatedMember,
  showProposalMode,
  currentProposal,
  expandedOption,
}) => {
  const [heading, setHeading] = useState('');

  useEffect(() => {
    if (selectedApp) {
      setHeading(selectedApp?.name);
    }
  }, [selectedApp]);

  useEffect(() => {
    if (activeItemDetails?.type) {
      const heading = generateHeadingValue(activeItemDetails);
      setHeading(heading);
    } else {
      setHeading(selectedApp?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItemDetails]);

  const generateHeadingValue = (activeItemDetails) => {
    const verb = activeItemDetails?.isEdit ? 'Edit' : 'Add';
    let type = activeItemDetails?.type;
    switch (activeItemDetails?.type) {
      case 'income':
        type = 'Cash Flow';
        break;
      case 'asset':
        type = 'Asset';
        break;
      case 'liability':
        type = 'Liability';
        break;
      case 'insurance':
        type = 'Insurance';
        break;
      case 'member':
        type = 'Member';
        break;
      case 'entity':
        type = 'Entity';
        break;
      default:
        type = activeItemDetails?.type;
    }
    return `${verb} ${type}`;
  };

  const headingLinkOnClick = (app) => {
    SendTrackingEvent(
      invocationId,
      'click',
      `${app.value}_visit`,
      'sidebar_amr'
    );
  };

  const borderLess = ['assetmap', 'notes', 'calculator'];
  const hasButtons = ['assetmap', 'add'];
  const excludeBorderLess = ['member', 'entity'];

  const showBorder = (app) => {
    if (excludeBorderLess.includes(activeItemDetails?.type)) {
      return true;
    } else {
      return !borderLess.includes(app.key);
    }
  };

  const showButtons = (app) => {
    return hasButtons.includes(app.key);
  };

  return (
    <TrayContainer width={trayWidth}>
      {showingAppBar ? (
        <>
          <AppListContainer>
            {sidebarApps.length
              ? sidebarApps.map((app, index) => {
                  return (
                    <AppIconContainer
                      key={index}
                      onClick={() => appOnClick(app)}
                      $isActive={selectedApp?.value === app.value}
                    >
                      <Tooltip
                        title={app.name}
                        position="bottom"
                        trigger="mouseenter"
                        followCursor={false}
                        delay={0}
                        animation={'fade'}
                        animateFill={false}
                        transitionFlip={false}
                        arrow={false}
                        size={'small'}
                        key={index}
                      >
                        <img src={app.icon} alt={app.value} title={app.name} />
                      </Tooltip>
                    </AppIconContainer>
                  );
                })
              : [0, 1, 2, 3, 4].map((index) => {
                  return (
                    <AppIconContainer
                      key={index}
                      $isActive={false}
                      $isLoading={true}
                    >
                      <LoadingPlaceholder
                        key={index}
                        round={true}
                        height="30px"
                        width="30px"
                        margin="0"
                      />
                    </AppIconContainer>
                  );
                })}
          </AppListContainer>
          {!showAppView && (
            <TrayArrowExpanded onClick={() => trayArrowOnClick(false)}>
              <img src={chevronRight} alt="arrow" />
            </TrayArrowExpanded>
          )}
        </>
      ) : (
        <TrayArrowCollapsed onClick={() => trayArrowOnClick(true)}>
          <img src={chevronRight} alt="arrow" />
        </TrayArrowCollapsed>
      )}
      {showAppView && (
        <AppContainer $width={selectedApp?.width}>
          <HeadingContainer $showBorder={showBorder(selectedApp)}>
            <HeadingName>
              {heading}
              {selectedApp?.showLink && (
                <Link
                  to={selectedApp?.link}
                  onClick={() => headingLinkOnClick(selectedApp)}
                >
                  <img
                    src={launchLink}
                    alt="link"
                    title={`View ${selectedApp?.name}`}
                  />
                </Link>
              )}
            </HeadingName>
            <button onClick={() => trayCloseOnClick()}>
              <img src={timesPaleGrey} alt="close" />
            </button>
          </HeadingContainer>
          <AppContent $hasButtons={showButtons(selectedApp)}>
            {selectedApp?.key === 'assetmap' ? (
              <AssetMapModalsSidebar
                householdId={householdId}
                currency={currency}
                itemDetails={activeItemDetails}
                showProposalMode={showProposalMode}
                currentProposal={currentProposal}
              />
            ) : selectedApp?.key === 'add' ? (
              <AssetMapCreateSidebar
                householdId={householdId}
                currency={currency}
                updatedInstrument={updatedInstrument}
                updatedMember={updatedMember}
                invocationId={invocationId}
                expandedOption={expandedOption}
              />
            ) : selectedApp?.key === 'legal' ? (
              <LegalsListSidebar
                householdId={householdId}
                currentHousehold={currentHousehold}
                currency={currency}
                showProposalMode={showProposalMode}
                currentProposal={currentProposal}
                invocationId={invocationId}
              />
            ) : selectedApp?.key === 'target_maps' ? (
              <TargetMapSidebar />
            ) : selectedApp?.key === 'signals' ? (
              <SignalsSidebar />
            ) : selectedApp?.key === 'stencils' ? (
              <StencilsSidebar />
            ) : selectedApp?.key === 'calculator' ? (
              <CalculatorSidebar />
            ) : selectedApp?.key === 'notes' ? (
              <NotesSidebar />
            ) : (
              <EmptyContainer>
                <NotFoundImage
                  src={notFound}
                  alt="Not Found"
                  data-image="not-found-sidebar"
                />
                <NotFoundText $fontSize="24px">App Not Found.</NotFoundText>
                <NotFoundText $fontSize="16px">
                  Contact Support if issue persists.
                </NotFoundText>
              </EmptyContainer>
            )}
          </AppContent>
        </AppContainer>
      )}
    </TrayContainer>
  );
};

const TrayContainer = styled.div`
  background: white;
  position: fixed;
  right: 0;
  top: ${heights.navBar};
  height: calc(100vh - ${heights.navBar});
  z-index: 501;
  width: ${(props) => (props.width ? props.width : '0px')};
  display: flex;
  align-content: stretch;
  justify-content: flex-start;
  align-items: flex-start;
  border-left: 1px solid ${colors.lightGrey};
  box-shadow: ${boxShadows.boxShadowSideNav};
`;

const AppContent = styled.div`
  height: calc(100% - 43px - 62px);
  height: ${(props) =>
    props.$hasButtons ? `calc(100% - 43px - 62px)` : `calc(100% - 43px)`};
  overflow: auto;
`;

const AppContainer = styled.div`
  background: white;
  ${'' /* width: ${(props) => props.width}; */}
  height: 100%;
  width: 100%;
  flex: 0 0 ${(props) => (props.$width ? props.$width : '355px')};
  border-left: 1px solid ${colors.lightGrey};
  ${
    '' /* box-shadow:
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12),
    0px 2px 4px -1px rgba(0, 0, 0, 0.2); */
  }
`;

const HeadingName = styled.p`
  font-weight: ${fonts.semiBold};
  font-size: 16px;
  flex: 1 1 auto;
  padding: 0 5px;
  ${FlexCenterStart};
  img {
    width: 12px;
    margin-left: 10px;
  }
`;

const HeadingContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0px;
  margin: 0 10px;
  border-bottom: ${(props) =>
    props.$showBorder
      ? `1px solid ${colors.lightGrey}`
      : `1px solid transparent`};
  button {
    flex: 0 0 auto;
    border-radius: 100%;
    height: 40px;
    width: 40px;
    ${FlexCenterAll};
    &:hover {
      background: ${colors.lightGrey};
    }
    img {
      width: 12px;
    }
  }
`;

const AppListContainer = styled.div`
  background: ${colors.white};
  /* background: ${colors.lightGrey}; */
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  width: ${sidebar.appBarWidth};
  flex: 0 0 ${sidebar.appBarWidth};
`;

const AppIconContainer = styled.div`
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${(props) =>
    props.$isActive
      ? colors.lightGrey
      : props.$isLoading
        ? null
        : colors.lighterGrey};
  transform: ${(props) => (props.$isActive ? 'scale(1.1)' : null)};
  margin: 5px 0px;
  transition: background 0.2s ease;
  div {
    display: flex !important;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  img {
    width: 20px;
    height: 20px;
  }
  &:hover {
    background: ${colors.hoverLighterGrey};
    opacity: 0.9;
  }
`;

const TrayArrow = styled.div`
  position: absolute;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    height: 14px;
  }
`;

const TrayArrowCollapsed = styled(TrayArrow)`
  bottom: 5px;
  right: 0;
  width: 30px;
  height: 25px;
  background: white;
  margin: 8px 0 8px 8px;
  padding: 10px 2px 10px 5px;
  border-radius: calc(56px - -2px);
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  box-shadow:
    0 1px 1px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  &:hover {
    background: ${colors.lightGrey};
    width: 45px;
  }
  img {
    transform: rotate(-180deg);
  }
`;

const TrayArrowExpanded = styled(TrayArrow)`
  bottom: 13px;
  right: 5px;
  display: flex;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  padding: 5px 4px 5px 5px;
  &:hover {
    background: ${colors.lightGrey};
  }
`;

Sidebar.propTypes = {
  trayWidth: PropTypes.string,
  showingAppBar: PropTypes.bool,
  sidebarApps: PropTypes.array,
  appOnClick: PropTypes.func,
  selectedApp: PropTypes.object,
  activeItemDetails: PropTypes.object,
  showAppView: PropTypes.bool,
  trayArrowOnClick: PropTypes.func,
  trayCloseOnClick: PropTypes.func,
  currency: PropTypes.object,
  invocationId: PropTypes.string,
  currentHousehold: PropTypes.object,
  updatedInstrument: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  updatedMember: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  showProposalMode: PropTypes.bool,
  currentProposal: PropTypes.object,
};

export default Sidebar;
