/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import PropTypes from 'prop-types';
import { InstrumentModal, InstrumentSidebar } from '..';

const InstrumentDisplayContainer = ({
  isModal = true,
  name,
  isLoading,
  invisible,
  hide,
  isNew,
  viewOnly = false,
  showProposalMode,
  modalTabOptions,
  activeTab,
  setActiveTab,
  preferences,
  currency,
  household,
  essentialInputs,
  detailInputs,
  notesInput,
  onEnter,
  buttonContent,
  modalError,
  errorTitle,
  integrationContent,
  proposalContent,
  itemDetails,
}) => {
  return isModal ? (
    <InstrumentModal
      isModal={isModal}
      name={name}
      viewOnly={viewOnly}
      isLoading={isLoading}
      invisible={invisible}
      hide={hide}
      isNew={isNew}
      showProposalMode={showProposalMode}
      modalTabOptions={modalTabOptions}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      currency={currency}
      preferences={preferences}
      household={household}
      essentialInputs={essentialInputs}
      detailInputs={detailInputs}
      notesInput={notesInput}
      onEnter={onEnter}
      buttonContent={buttonContent}
      modalError={modalError}
      errorTitle={errorTitle}
      proposalContent={proposalContent}
      integrationContent={integrationContent}
    />
  ) : (
    <InstrumentSidebar
      item={itemDetails}
      isEdit={!isNew}
      isLoading={isLoading}
      household={household}
      currency={currency}
      modalTabOptions={modalTabOptions}
      setActiveTab={setActiveTab}
      essentialInputs={essentialInputs}
      detailInputs={detailInputs}
      notesInput={notesInput}
      proposalContent={proposalContent}
      integrationContent={integrationContent}
      buttonContent={buttonContent}
      showProposalMode={showProposalMode}
      error={modalError}
      errorTitle={errorTitle}
    />
  );
};

InstrumentDisplayContainer.propTypes = {
  name: PropTypes.string,
  isLoading: PropTypes.bool,
  invisible: PropTypes.bool,
  hide: PropTypes.func,
  isNew: PropTypes.bool,
  viewOnly: PropTypes.bool,
  showProposalMode: PropTypes.bool,
  showUndo: PropTypes.bool,
  modalTabOptions: PropTypes.array,
  defaultTab: PropTypes.string,
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
  onEnter: PropTypes.func,
  household: PropTypes.object,
  currency: PropTypes.object,
  preferences: PropTypes.object,
  essentialInputs: PropTypes.array,
  detailInputs: PropTypes.array,
  notesInput: PropTypes.object,
  buttonContent: PropTypes.object,
  modalError: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  errorTitle: PropTypes.string,
  integrationContent: PropTypes.object,
  proposalContent: PropTypes.object,
};

export default InstrumentDisplayContainer;
